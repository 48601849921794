import { Stack, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

export const SuccessPlace = () => {
    const theme = useTheme();
    return (
        <Stack sx={{ maxWidth: "720px", margin: "auto", px: 2 }}>
            <Typography variant="h3">Skráning tókst</Typography>
            <Typography pt={2}>
                Takk fyrir þessa skráningu. Núna verður farið yfir hana og skoðað hvort hún eigi við og sé rétt út
                útfyllt. Takk fyrir þetta.
            </Typography>
            <Stack direction={"row"} sx={{ display: "flex", alignItems: "flex-end", whiteSpace: "pre" }}>
                <Typography variant="body1" sx={{ pt: 2 }}>
                    Fara aftur á{" "}
                </Typography>
                <Link
                    to="/important-places"
                    style={{ textDecoration: "none", color: theme.palette.secondary.main, fontWeight: "16px" }}
                >
                    <Typography variant="body1" sx={{ fontWeight: 700 }}>
                        forsíðu
                    </Typography>
                </Link>
            </Stack>
        </Stack>
    );
};
