import { createContext } from "react";
import { RiskUser } from "../models/RiskUser";

interface appContext {
    user: RiskUser | undefined;
}

const defaultContext: appContext = { user: undefined };

export const AppContext = createContext<appContext>(defaultContext);
