import Button, { ButtonProps } from "@mui/material/Button";
import { useAsync } from "../utils/UseAsync";
import { useEffect, useRef, useState } from "react";
import Cloud from "../assets/icons/cloud.svg";
import { Box, Typography } from "@mui/material";

export interface DownloadButtonProps {
    className?: string;
    getToken: () => Promise<string>;
    downloadUrl: string;
}

export default function DownloadButton({ className, getToken, downloadUrl }: DownloadButtonProps) {
    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
    const { value: newToken, execute } = useAsync(getToken, true);
    const [token, setToken] = useState(newToken);

    useEffect(() => {
        if (newToken && newToken != token) setToken(newToken);
    }, [newToken, token, setToken]);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            execute();
        }, 1000 * (60 * 5 - 10));

        return () => {
            intervalRef.current && clearInterval(intervalRef.current);
        };
    });

    return (
        <>
            {token && (
                <CustomIconButton
                    href={`${downloadUrl}?token=${token}`}
                    className={className}
                    variant="contained"
                    color="primary"
                    icon={<Box component="img" src={Cloud} alt="cloudLogo" />}
                    title={"Niðurhala lista"}
                    resize={true}
                />
            )}
        </>
    );
}

interface CustomIconButtonProps extends ButtonProps {
    title: string;
    icon?: JSX.Element;
    resize?: boolean;
    textMinWidth?: number;
    left?: boolean;
}

export const CustomIconButton = ({
    title,
    icon,
    resize,
    textMinWidth,
    left = false,
    ...rest
}: CustomIconButtonProps) => {
    return (
        <Button {...rest} sx={{ mr: 3 }}>
            {left && icon}
            <Typography
                sx={{
                    minWidth: textMinWidth,
                    maxHeight: 48,
                    fontWeight: 700,
                    pr: !left ? 1 : 0,
                    pl: left ? 1 : 0,
                    display: resize ? { xs: "none", sm: "flex" } : {},
                    textAlign: "center",
                }}
            >
                {title}
            </Typography>
            {!left && icon}
        </Button>
    );
};
