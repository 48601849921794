export type EditPageParams = {
    ID: string;
};

export const routes = {
    root: "/",
    risks: {
        root: "risks",
        new: "risks/new",
        analysis: "risks/analysis",
        edit: {
            root: "risks",
            params: { ID: "ID" },
        },
    },
    surveys: {
        root: "surveys",
        new: "surveys/new",
        edit: {
            root: "surveys",
            params: { ID: "ID" },
        },
    },
    places: {
        root: "important-places",
        new: "important-places/new",
        edit: {
            root: "important-places",
            params: { ID: "ID" },
        },
        submit: {
            root: "submit",
            params: { ID: "ID" },
        },
        success: {
            root: "success",
        },
    },
    admin: {
        root: "admin",
    },
};

export const commonRoutes = {
    login: {
        root: "login",
        params: {
            token: "token",
            // ATT: DO NOT CHANGE, THIS IS USED IN THE BACKEND!
            errorMessage: "errMsg",
        },
    },
};
