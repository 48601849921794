import { Typography, Button, Snackbar, Box, Alert } from "@mui/material";
import stafrIcelandLogo from "../assets/StafraentIslandLogo.svg";
import loginPageImage from "../assets/7V0A56042540.jpg";
import almLogo from "../assets/almannavarnir_logo.svg";
import { history, useQuery } from "../utils/utils";
import { useCallback, useEffect } from "react";
import { commonRoutes } from "../routes/routes";
import { useState, FC } from "react";
import { styled } from "@mui/styles";

const Root = styled("div")(() => ({
    display: "flex",
    background: `url("${loginPageImage}")`,
    width: "100%",
    minWidth: "100%",
    height: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
}));

const CardContainer = styled("div")(({ theme }) => ({
    margin: theme.spacing(10),
    padding: theme.spacing(4),
    marginTop: theme.spacing(20),
    height: "334px",
    width: "580px",
    [theme.breakpoints.down("md")]: {
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(5),
    },
    [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    backgroundColor: "#ffff",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
}));

const Footer = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
    backgroundColor: "#3571BD",
    height: "6vh",
    width: "100%",
    position: "fixed",
    bottom: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
        fontSize: "11px",
        padding: 0,
    },
}));

const LoginButton = styled(Button)(() => ({
    width: "50%",
    height: 60,
}));

const Header = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        fontSize: "1.4rem",
    },
}));

const Image = styled("img")(({ theme }) => ({
    maxHeight: "20px",

    [theme.breakpoints.down("sm")]: {
        maxHeight: "15px",
    },
}));

export interface LoginProps {
    setToken: (token: string) => void;
    prepareLogin: () => Promise<{ id: string }>;
}

const Login: FC<LoginProps> = ({ setToken, prepareLogin: _prepLogin }: LoginProps) => {
    const query = useQuery();
    const [error, setError] = useState<string | null>(query.get(commonRoutes.login.params.errorMessage));
    const [loginAuthId, setLoginAuthId] = useState<string | undefined>();

    const prepLogin = useCallback(() => {
        _prepLogin().then((r) => setLoginAuthId(r.id));
    }, [_prepLogin, setLoginAuthId]);

    useEffect(() => {
        const token = query.get(commonRoutes.login.params.token);

        if (token) {
            setToken(token);
        } else if (!loginAuthId) {
            prepLogin();
        }

        if (error && query.get(commonRoutes.login.params.errorMessage)) {
            history.push(`/${commonRoutes.login.root}`);
        }
    }, [query, setToken, error, loginAuthId, prepLogin]);

    return (
        <Root>
            {error && (
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={Boolean(error)}
                    autoHideDuration={8000}
                    onClose={() => {
                        setError(null);
                    }}
                >
                    <Alert
                        severity="error"
                        onClose={() => {
                            setError(null);
                        }}
                    >
                        {error}
                    </Alert>
                </Snackbar>
            )}
            <CardContainer>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <img src={almLogo} alt="" style={{ maxHeight: "50px" }} />
                    <Image src={stafrIcelandLogo} alt="" />
                </Box>
                <Box>
                    <Header variant="h3" sx={{ fontWeight: "750", pb: 1.5 }}>
                        Velkomin í vefgátt Almannavarna
                    </Header>
                    <Typography variant="body2" sx={{ fontSize: { xs: "1rem", sm: "1.1rem" } }}>
                        Skráðu þig inn til að skoða gögn þinnar stofnunnar.
                    </Typography>
                </Box>
                <Box>
                    <form
                        method="get"
                        action={"redirect/island"}
                    >
                        <input type="hidden" name="authid" value={loginAuthId || ""} />
                        <LoginButton type="submit" disabled={!loginAuthId} color="primary" variant="contained">
                            Innskráning
                        </LoginButton>
                    </form>
                </Box>
            </CardContainer>
            <Footer>
                <div>Skógarhlíð 14</div>
                <div>105 Reykjavík </div>
                <div>
                    <a style={{ textDecoration: "none", color: "#FFFFFF" }} href="tel:+3544442500">
                        +354 444 2500
                    </a>
                </div>
                <div>
                    <a style={{ textDecoration: "none", color: "#FFFFFF" }} href="mailto:almannavarnir@logreglan.is">
                        almannavarnir@logreglan.is
                    </a>
                </div>
            </Footer>
        </Root>
    );
};

export default Login;
