import { ICauseOrEffect } from "../../models/RiskTypes";
import CauseEffectCard, { causeEffect } from "./CauseEffectCard";

export interface CauseOrEffectProps {
    cause: ICauseOrEffect;
    type: causeEffect;
    isPrinting: boolean;
}

const CauseOrEffect = ({ cause, type, isPrinting }: CauseOrEffectProps) => {
    return (
        <>
            <CauseEffectCard isPrinting={isPrinting} text={cause.name} type={type} />
            {cause.preventions &&
                cause.preventions.map((c, i) => (
                    <CauseEffectCard isPrinting={isPrinting} key={i} text={c.name} type={causeEffect.prevention} />
                ))}
        </>
    );
};

export default CauseOrEffect;
