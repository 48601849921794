import { createStyles, withStyles, WithStyles } from "@mui/styles";
import { Typography, Theme, Stack } from "@mui/material";
import { getContentWrapperClass } from "../../styles/Styles";
import { history, Spinner } from "../../components/Shared/Common";
import { routes } from "../../routes/routes";
import { IRisk } from "../../models/RiskTypes";
import { useAsync } from "../../utils/UseAsync";
import get from "lodash.get";
import DownloadButton, { CustomIconButton } from "../../components/DownloadButton";
import FullPageError from "../../components/FullPageError";
import CheckIcon from "@mui/icons-material/Check";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { AlmEnhancedTable } from "almannavarnir-shared";

const styles = (theme: Theme) =>
    createStyles({
        wrapperClass: {
            ...getContentWrapperClass(theme),
        },
        buttonContainer: {
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            justifyContent: "end",
            paddingBottom: theme.spacing(1),
            gap: theme.spacing(2),
        },
        button: {
            color: theme.palette.background.default,
        },
    });

export interface RiskOverviewProps extends WithStyles<typeof styles> {
    getRisks: () => Promise<IRisk[]>;
    getXLSXDownloadToken: () => Promise<string>;
    downloadUrl: string;
}

function RiskOverview({ classes, getRisks, getXLSXDownloadToken, downloadUrl }: RiskOverviewProps) {
    const { status, value: risks, error } = useAsync(getRisks, true);

    return (
        <div className={classes.wrapperClass}>
            <div>
                <Typography variant="h2">Áhættur</Typography>
                <Typography paragraph sx={{ pt: 2 }}>
                    Kærar þakkir fyrir að taka þátt í áhættuskoðun almannavarna.
                </Typography>
                <Typography paragraph>
                    Áhættuskoðun almannavarna er liður í starfi Almannavarnadeildar ríkislögreglustjóra til að uppfylla
                    kröfur í 7. gr. laga um almannavarnir þar sem segir að ríkislögreglustjóri hafi umsjón með að
                    ráðstafanir séu gerðar í samræmi við stefnu stjórnvalda í almannavarna- og öryggismálum sem og
                    eftirlit með skipulagi almannavarna á landinu öllu og eftirlit með almannavörnum sveitarfélaga.
                </Typography>
                <Typography paragraph>
                    Ætlunin er að Áhættuskoðun almannavarna sé lifandi gagnagrunnur sem inniheldur raunsætt mat á áhættu
                    og viðkvæmni íslensks samfélags hverju sinni. Ráðuneyti, stofnanir og sveitarfélög geta bætt við og
                    uppfært sitt mat með reglubundnum hætti. Eftirfylgni Almannavarna miðar að því að slík endurskoðun
                    þessara aðila á greiningu á áhættu og áfallaþoli sé gerð með reglubundnum hætti og í samræmi við
                    breytingar á áhættum og/eða viðkvæmni.
                </Typography>
                <Typography paragraph>
                    Mat á hverju óæskilegu atviki fyrir sig er skjalfest í gagnagrunninum.
                </Typography>
            </div>
            {/* TODO: Style errors */}
            {status == "error" && <FullPageError message={error || "Something went wrong"} />}
            {/* TODO: Show Loading */}
            {status == "pending" && <Spinner />}
            {status == "success" && risks && (
                <AlmEnhancedTable
                    items={risks}
                    searchFields={[
                        "id",
                        "name",
                        "type.name",
                        "category.name",
                        "organization.name",
                        "organization.type",
                        "organization.postalCode.areaName",
                        "organization.postalCode.postalName",
                    ]}
                    columns={[
                        {
                            id: "isPublished",
                            label: "Lokið",
                            onRender: (itm, key) =>
                                get(itm, key) ? (
                                    <CheckIcon style={{ color: "green" }} />
                                ) : (
                                    <AccessTimeIcon color="primary" />
                                ),
                        },
                        { id: "id", label: "Einkenni" },
                        { id: "name", label: "Titill" },
                        { id: "type.name", label: "Gerð" },
                        { id: "category.name", label: "Flokkur" },
                        { id: "organization.name", label: "Stofnun" },
                        { id: "organization.postalCode.postalName", label: "Bæjarfélag" },
                        { id: "organization.postalCode.areaName", label: "Landssvæði" },
                    ]}
                    idCol="id"
                    onClick={(_ev, it) => {
                        history.push(`/${routes.risks.edit.root}/${it.id}`);
                    }}
                    customBtn={
                        <Stack direction={"row"}>
                            <CustomIconButton
                                variant="contained"
                                color="secondary"
                                icon={<AddCircleOutlineRoundedIcon />}
                                title={"Skrá nýja áhættu"}
                                onClick={() => {
                                    history.push(`/${routes.risks.new}`);
                                }}
                                resize={true}
                                textMinWidth={120}
                            />
                            <DownloadButton getToken={getXLSXDownloadToken} downloadUrl={downloadUrl} />
                        </Stack>
                    }
                />
            )}
            {status == "success" && risks && risks.length == 0 && (
                <Typography>Engar áhættuskráningar fundust frá þinni stofnun.</Typography>
            )}
        </div>
    );
}

export default withStyles(styles)(RiskOverview);
