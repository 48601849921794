import { useCallback, useState } from "react";
import { IOrganization, OrganizationUser } from "../../models/RiskTypes";
import mergeWith from "lodash.mergewith";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Switch } from "@mui/material";
import UserOrganizationsTable from "./UserOrganizationsTable";
import kennitalaValidation from "kennitala";
import kennitala from "kennitala";
import { AlmButton, AlmTextField } from "almannavarnir-shared";
import FormError from "../FormError";
export interface UserDialogProps {
    onClose: (wasUpdated?: boolean) => void;
    onSubmit: (user: OrganizationUser) => Promise<void>;
    organizations: IOrganization[];
    user?: OrganizationUser;
    open: boolean;
}

const variant = "outlined";

export default function UserDialog({ onClose, onSubmit, user: defaultUser, organizations, open }: UserDialogProps) {
    const [formValid, setFormVaild] = useState({ firstName: true, lastName: true, kennitala: true });

    const [user, setUser] = useState<OrganizationUser>(
        mergeWith<Partial<OrganizationUser>, OrganizationUser | undefined>(
            { activeStatus: true, organizations: [] },
            defaultUser,
            (val, srcVal) => (srcVal === null ? val : undefined)
        )
    );

    const handleChange = useCallback(
        (property: keyof OrganizationUser, newVal: string | number | number[] | object[] | boolean | undefined) => {
            console.log("im handle change");
            const newValue = newVal;
            setUser({ ...user, [property]: newValue });
            property === "kennitala"
                ? setFormVaild({
                      ...formValid,
                      [property]:
                          typeof newVal === "string" || typeof newVal === "number" ? kennitala.isValid(newVal) : false,
                  })
                : setFormVaild({ ...formValid, [property]: true });
        },
        [setUser, user, formValid]
    );

    const isValid = (): boolean => {
        let allValid = true;
        const validInput = { firstName: true, lastName: true, kennitala: true };
        if (!user.firstName || user.firstName.replace(/ /g, "").length < 1) {
            validInput.firstName = false;
            allValid = false;
        }
        if (!user.lastName || user.lastName.replace(/ /g, "").length < 1) {
            validInput.lastName = false;
            allValid = false;
        }
        if (!kennitala.isValid(user.kennitala)) {
            validInput.kennitala = false;
            allValid = false;
        }
        setFormVaild(validInput);
        return allValid;
    };

    const [error, setError] = useState<string | undefined>(undefined);
    return (
        <Dialog
            open={open}
            sx={{
                ".MuiDialog-container .MuiPaper-root": {
                    boxShadow: "0px 0px 23px rgb(0 0 0 / 10%)",
                    maxWidth: "800px",
                },
            }}
        >
            {error && <FormError message={error} />}
            <DialogTitle
                id="form-dialog-title"
                sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                variant="h3"
            >
                Notandi
                <FormControlLabel
                    control={
                        <Switch
                            checked={user.activeStatus || false}
                            onChange={(_ev, val) => handleChange("activeStatus", val)}
                        />
                    }
                    label={user.activeStatus ? "Virkur" : "Óvirkur"}
                />
            </DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(2) }}>
                <Grid container spacing={2} pt={1}>
                    <Grid item container spacing={2} xs={12}>
                        <Grid item xs={12} sm={6}>
                            <AlmTextField
                                autoFocus
                                label="Fornafn (og millinafn)"
                                value={user.firstName || ""}
                                surveyProperty="firstName"
                                onChange={handleChange}
                                variant={variant}
                                errorText={"Fornafn má ekki vera tómt"}
                                required
                                error={!formValid.firstName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <AlmTextField
                                label="Eftirnafn"
                                value={user.lastName || ""}
                                surveyProperty="lastName"
                                onChange={handleChange}
                                errorText={"Eftirnafn má ekki vera tómt"}
                                variant={variant}
                                required
                                error={!formValid.lastName}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2} xs={12}>
                        <Grid item xs={12} sm={6}>
                            <AlmTextField
                                number
                                maxLength={10}
                                label="Kennitala"
                                value={user.kennitala || ""}
                                surveyProperty="kennitala"
                                onChange={handleChange}
                                variant={variant}
                                required
                                errorText={"Ógild kennitala"}
                                error={!formValid.kennitala}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <AlmTextField
                                label="Símanúmer"
                                value={user.mobilePhone || ""}
                                surveyProperty="mobilePhone"
                                required
                                onChange={handleChange}
                                variant={variant}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <AlmTextField
                            label="Email"
                            value={user.email || ""}
                            surveyProperty="email"
                            required
                            onChange={handleChange}
                            variant={variant}
                        />
                    </Grid>
                </Grid>
                <UserOrganizationsTable
                    organizations={user.organizations}
                    variant={variant}
                    orgOptions={organizations}
                    onChange={(newVal) => {
                        handleChange("organizations", newVal);
                    }}
                />
            </DialogContent>
            <DialogActions sx={{ m: 1 }}>
                <AlmButton
                    variant={"contained"}
                    color="secondary"
                    onClick={() => onClose()}
                    sx={{
                        width: "100%",
                    }}
                    title={"Hætta við"}
                />
                <AlmButton
                    variant={"contained"}
                    color="primary"
                    onClick={() => {
                        if (isValid()) {
                            setError(undefined);
                            setUser({ ...user, kennitala: kennitalaValidation.clean(user.kennitala) });
                            onSubmit(user)
                                .then(() => onClose(true))
                                .catch((err) => {
                                    console.log("Error updating: ", err);
                                    setError(
                                        (typeof err == "string" ? err : err.message) ||
                                            "Eitthvað fór úrskeiðis við að vista"
                                    );
                                });
                        }
                    }}
                    sx={{
                        width: "100%",
                        // "&.Mui-disabled": { backgroundColor: (theme) => theme.palette.primary.main },
                    }}
                    title={"Vista"}
                    disabled={user.organizations.some((org) => org.id === undefined)}
                />
            </DialogActions>
        </Dialog>
    );
}
