import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { IOrganization, IUserOrganization } from "../../models/RiskTypes";
import { useCallback } from "react";
import UserOrganizationsRow from "./UserOrganizationsRow";
import { cloneDeep } from "lodash";
import NewIcon from "@mui/icons-material/Add";
export interface UserOrganizationsTableProps {
    organizations: IUserOrganization[];
    variant: "filled" | "standard" | "outlined";
    orgOptions: IOrganization[];
    onChange: (newVal: IUserOrganization[]) => void;
}

export default function UserOrganizationsTable({
    organizations,
    variant,
    orgOptions,
    onChange,
}: UserOrganizationsTableProps) {
    const handleChange = useCallback(
        (idx: number, newVal: IUserOrganization) => {
            const newOrgs: IUserOrganization[] = cloneDeep(organizations);
            newOrgs.splice(idx, 1, newVal);
            onChange(newOrgs);
        },
        [organizations, onChange]
    );

    const handleDelete = useCallback(
        (idx: number) => {
            const newOrgs: IUserOrganization[] = cloneDeep(organizations);
            newOrgs.splice(idx, 1);
            onChange(newOrgs);
        },
        [organizations, onChange]
    );

    const handleAdd = useCallback(() => {
        const newOrgs: IUserOrganization[] = [...cloneDeep(organizations), { id: undefined, roles: [] }];
        onChange(newOrgs);
    }, [organizations, onChange]);

    return (
        <TableContainer sx={{ overflowX: "initial", paddingBottom: (theme) => theme.spacing(1) }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Stofnun</TableCell>
                        <TableCell>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Typography sx={{ rotate: "-50deg", width: "38px" }}>Staðir</Typography>
                                        </td>
                                        <td>
                                            <Typography sx={{ rotate: "-50deg", width: "38px" }}>Kannanir</Typography>
                                        </td>
                                        <td>
                                            <Typography sx={{ rotate: "-50deg", width: "38px" }}>Áhættur</Typography>
                                        </td>
                                        <td>
                                            <Typography sx={{ rotate: "-50deg", width: "38px" }}>Aðg.stýr.</Typography>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TableCell>
                        <TableCell align="right">
                            <IconButton
                                onClick={handleAdd}
                                color="primary"
                                disabled={organizations.some((org) => org.id === undefined)}
                            >
                                <NewIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(organizations || []).map((org, idx) => (
                        <UserOrganizationsRow
                            key={org.id}
                            org={org}
                            variant={variant}
                            orgOptions={
                                org.id === undefined
                                    ? orgOptions.filter((itm) => !organizations.some((org) => org.id === itm.id))
                                    : orgOptions
                            }
                            onChange={handleChange.bind(undefined, idx)}
                            onDelete={handleDelete.bind(undefined, idx)}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
