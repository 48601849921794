import { Typography } from "@mui/material";
import FormIntro, { FormIntroProps } from "../FormIntro";

export type SurveyIntroProps = Pick<FormIntroProps, "isExpandedByDefault">;

export default function SurveyIntro({ isExpandedByDefault }: SurveyIntroProps) {
    return (
        <FormIntro
            isExpandedByDefault={isExpandedByDefault}
            title="Könnun á starfi sveitarfélaga við að tryggja öryggi borgaranna 2021"
        >
            <Typography paragraph>
                Hægt er að vista svör og taka upp þráðinn síðar. Þegar nýtt skjal er opnað þá þarf að vista það til að
                það stofnist í grunninum. Eftir að skjal hefur verið stofnað er það vistað sjálfkrafa á nokkurra
                sekúndna fresti til að passa upp á að gögn tapist síður. Athugið að bara einn sé að vinna í skjali á
                hverjum tíma, annars geymast bara gögn þess sem vistaði síðast.
            </Typography>
            <Typography paragraph>
                Könnuninni verður að svara rafrænt en hægt er að prenta spurningalistann út með því að smella á
                prentaratáknið hægra megin á síðunni. Sömuleiðis er hægt að prenta svör út, að könnun lokinni.
            </Typography>
            <Typography variant="h3">Meginþema</Typography>
            <Typography paragraph style={{ fontWeight: 600 }}>
                Staða vinnu við að tryggja öryggi borgaranna og samfélagsins sem og viðbúnað í sveitarfélögum
            </Typography>
            <Typography paragraph>
                Landið skiptist í sveitarfélög sem ráða sjálf málefnum sínum á eigin ábyrgð. Afskipti annarra
                stjórnvalda af málefnum sveitarfélaga taka ávallt mið af sjálfstjórn sveitarfélaga. Sveitarfélögum er
                skylt að annast þau verkefni sem þeim eru falin í lögum. Sveitarfélög þurfa að vinna að sameiginlegum
                velferðarmálum íbúa eins og unnt er á hverjum tíma.
            </Typography>
            <Typography paragraph>
                Frá árinu 2008 hefur sveitarfélögum verið skylt að fara með almannavarnir í héraði í samvinnu við
                ríkisvaldið en auk þess hafa sveitarfélög ákveðna lögboðna viðbúnaðarskyldu í ýmsum málaflokkum. Fylgni
                við lög og reglugerðir eru aðalefni þessarar könnunar.
            </Typography>
            <Typography variant="h6" paragraph>
                Áfallaþol 16. gr. í lögum um almannavarnir
            </Typography>
            <Typography paragraph>
                Sveitarfélög og stofnanir á þeirra vegum þurfa að kanna áfallaþol í sínu umdæmi í samvinnu við
                ríkislögreglustjóra.
            </Typography>
            <Typography paragraph>
                Sveitarfélög verða að kortleggja hvaða óæskilegu atburðir geta átt sér stað í sveitarfélaginu, meta
                líkur á að þessir atburðir eigi sér stað og hvaða áhrif þeir geta haft á sveitarfélagið.
            </Typography>
            <Typography paragraph>Niðurstaðan skal metin og tekin saman í greiningu á áhættu og áfallaþoli.</Typography>
        </FormIntro>
    );
}
