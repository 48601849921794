import { Url } from "../models/PlaceTypes";
import Service from "./Service";
// TODO ser type skra
export interface ExtendedFile extends File {
    tags: AttachmentTag[];
    id?: number;
    blobPath?: string;
    data?: File;
    placeID: number;
    placeTitle: string;
}
export interface DisplayFile {
    tags: AttachmentTag[];
    id?: number;
    blobPath?: string;
    size: number;
    name: string;
}

export interface AttachmentTag {
    id: number;
    name: string;
    shouldDelete: boolean;
    isNew: boolean;
}

export interface NewAttachment {
    id: number;
    blobPath: string;
}

export async function uploadAttachment(this: Service, file: ExtendedFile): Promise<NewAttachment> {
    const base64data: string = file.data ? ((await getBase64(file.data)) as string).split("base64,")[1] : "";
    const moreFile = {
        ...file,
        lastModified: new Date(file.lastModified),
        data: base64data,
    };
    return this.post(`/places/attachment/add`, moreFile);
}

export async function removeAttachment(this: Service, blobPath: string): Promise<void> {
    return this.post(`/places/attachment/remove`, blobPath);
}

export async function editAttachmentTag(
    this: Service,
    file: DisplayFile | Url,
    tagID: number,
    remove: boolean
): Promise<number | null> {
    return this.post(`/places/attachment/tag`, {
        attachment: file,
        tagID,
        remove,
    });
}
export async function getAttachmentTags(this: Service): Promise<AttachmentTag[]> {
    return this.get<AttachmentTag[]>(`/places/attachment/tags`);
}
export async function addNewTag(this: Service, tag: string): Promise<number> {
    return this.post(`/places/attachment/tags/new`, tag);
}

const getBase64 = async (file: File) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file as File);

        reader.onload = () => {
            return resolve(reader.result);
        };

        reader.onerror = reject;
    });
};
