import { Grid, TypographyVariant } from "@mui/material";
import { IRisk, ISurvey } from "../../models/RiskTypes";
import SurveySectionHeader from "./SurveySectionHeader";
import SurveySelect, { SurveySelectProps } from "./SurveySelect";

type valueType = number | string;

interface QuestionSubsection<T extends valueType, U extends ISurvey | IRisk> {
    label: string;
    helperText?: string;
    surveyProperty: Extract<keyof U, string>;
    value?: T;
}

export type SurveyMultiPartSelectProps<T extends valueType, U extends ISurvey | IRisk> = Omit<
    SurveySelectProps<T, U>,
    "value" | "helperText" | "surveyProperty"
> & {
    variant: "filled" | "standard" | "outlined";
    label: string;
    sections: QuestionSubsection<T, U>[];
    isPrinting?: boolean;
    titleVariant?: TypographyVariant;
    boldTitle?: boolean;
};

function SurveyMultiPartSelect<T extends valueType, U extends ISurvey | IRisk>({
    label,
    sections,
    isPrinting,
    titleVariant,
    boldTitle,
    ...rest
}: SurveyMultiPartSelectProps<T, U>) {
    return (
        <>
            <Grid item xs={12}>
                <SurveySectionHeader
                    boldTitle={boldTitle}
                    variant={titleVariant}
                    title={label}
                    subTitle=""
                    disabled={rest.disabled}
                />
            </Grid>
            {sections.map((section) => (
                <Grid
                    key={section.surveyProperty}
                    item
                    xs={12}
                    sm={!isPrinting && 4}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                    }}
                >
                    <SurveySelect {...section} {...rest} style={{}} />
                </Grid>
            ))}
        </>
    );
}

export default SurveyMultiPartSelect;
