import { Button, ButtonGroup, TextField, Theme, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useComponentFocused from "../../utils/CustomHooks";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useIsTyping from "../../utils/CustomHooks/useIsTyping";
import { makeStyles } from "@mui/styles";

export interface IEditableTextRowProps {
    value: string;
    rowDeleted: () => void;
    rowEdited: (newValue: string) => void;
    showEdit?: boolean;
    showDelete?: boolean;
    buttonOrientation?: "horizontal" | "vertical";
    color?: "primary" | "secondary";
    disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    textArea: {
        flexGrow: 1,
        marginRight: theme.spacing(2),
        display: "flex",
        paddingLeft: "15px",
        alignItems: "center",
    },
    textField: {
        width: "100%",
    },
    buttons: {
        visibility: "inherit",
        justifyContent: "center",
        padding: "8px 0 4px",
    },
    deleteButton: {
        backgroundColor: theme.palette.error.light,
        "&:hover": {
            backgroundColor: theme.palette.error.main,
        },
    },
    deleteIcon: {
        color: "white",
    },
    itemContainer: {
        display: "flex",
        width: "100%",
    },
    hidden: {
        padding: "8px 0 4px",
        visibility: "hidden",
    },
}));

export function EditableTextRow({
    showEdit = false,
    showDelete = false,
    buttonOrientation = "horizontal",
    color = "primary",
    value,
    rowDeleted,
    rowEdited,
    disabled,
}: IEditableTextRowProps) {
    const [textFieldRef, isEditingField, setIsEditingField] = useComponentFocused(value === "" || value == undefined);
    const [isTyping, register] = useIsTyping({ timeout: 8000 });
    const [textValue, setTextValue] = useState<string>(value);

    useEffect(() => {
        if ((textValue !== value || textValue.trim() == "") && !isTyping && !isEditingField) {
            rowEdited(textValue);
        }
    }, [isTyping, isEditingField]);

    const classes = useStyles();

    const [showButtons, setShowButtons] = useState<boolean>(false);

    return (
        <div
            className={classes.itemContainer}
            onMouseEnter={() => setShowButtons(true)}
            onFocus={() => setShowButtons(true)}
            onBlur={() => setShowButtons(false)}
            onMouseLeave={() => setShowButtons(false)}
        >
            <span className={classes.textArea}>
                {isEditingField ? (
                    <TextField
                        autoFocus
                        className={classes.textField}
                        margin="dense"
                        id="filled-name"
                        value={textValue}
                        color={color}
                        multiline
                        variant="outlined"
                        ref={textFieldRef}
                        inputRef={register}
                        onKeyDown={(ev) => {
                            if (ev.key == "Enter") {
                                setIsEditingField(false);
                                rowEdited(textValue);
                            }
                        }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setTextValue(event.target.value);
                        }}
                        onClick={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                        }}
                    />
                ) : (
                    <Typography {...(disabled && { color: "textSecondary" })}> {value}</Typography>
                )}
            </span>
            <ButtonGroup
                className={showButtons || value == "" ? classes.buttons : classes.hidden}
                orientation={buttonOrientation}
                aria-label="vertical outlined primary button group"
            >
                {!disabled && showEdit && (
                    <Button
                        size="small"
                        onClick={(ev) => {
                            ev.stopPropagation();
                            setIsEditingField(true);
                        }}
                    >
                        <EditIcon color={color} />
                    </Button>
                )}
                {!disabled && showDelete && (
                    <Button
                        size="small"
                        className={classes.deleteButton}
                        variant="contained"
                        onClick={(ev) => {
                            ev.stopPropagation();
                            if (window.confirm("Ertu viss um að þú viljir eyða?")) {
                                rowDeleted();
                            }
                        }}
                    >
                        <DeleteIcon className={classes.deleteIcon} />
                    </Button>
                )}
            </ButtonGroup>
        </div>
    );
}
