import { Button, Tooltip } from "@mui/material";
import { useState } from "react";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DoneIcon from "@mui/icons-material/Done";
import { copyRichText } from "../utils/Utilities";
export interface DownloadButtonProps {
    elementID: string;
}

export default function CopyButton({ elementID }: DownloadButtonProps) {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopyClick = () => {
        // Asynchronously call copyTextToClipboard
        copyRichText(elementID)
            .then(() => {
                // If successful, update the isCopied state value
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Tooltip
            PopperProps={{
                disablePortal: true,
            }}
            open={isCopied}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Copied"
            arrow
            placement="top"
        >
            <Button
                style={{ minWidth: 0, height: "32px", alignSelf: "center" }}
                color={isCopied ? "success" : "primary"}
                variant="outlined"
                onClick={handleCopyClick}
                startIcon={
                    isCopied ? (
                        <DoneIcon sx={{ marginRight: "-12px", color: "success.main" }} />
                    ) : (
                        <FileCopyIcon sx={{ marginRight: "-12px", color: "primary.main" }} />
                    )
                }
            />
        </Tooltip>
    );
}
