import { createStyles, withStyles, WithStyles } from "@mui/styles/";
import { Stack, Theme, Typography } from "@mui/material";

import { getContentWrapperClass } from "../../styles/Styles";
import { history, Spinner } from "../../components/Shared/Common";
import { routes } from "../../routes/routes";
import { ISurvey } from "../../models/RiskTypes";
import { useAsync } from "../../utils/UseAsync";
import get from "lodash.get";
import DownloadButton, { CustomIconButton } from "../../components/DownloadButton";
import FullPageError from "../../components/FullPageError";

import CheckIcon from "@mui/icons-material/Check";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { AlmEnhancedTable } from "almannavarnir-shared";

const styles = (theme: Theme) =>
    createStyles({
        wrapperClass: {
            ...getContentWrapperClass(theme),
        },
        buttonContainer: {
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            justifyContent: "end",
            paddingBottom: theme.spacing(1),
            gap: theme.spacing(2),
        },
        button: {
            color: theme.palette.background.default,
        },
    });

export interface SurveyOverviewProps extends WithStyles<typeof styles> {
    getSurveys: () => Promise<ISurvey[]>;
    getXLSXDownloadToken: () => Promise<string>;
    downloadUrl: string;
}

function SurveyOverview({ classes, getSurveys, getXLSXDownloadToken, downloadUrl }: SurveyOverviewProps) {
    const { status, value: surveys, error } = useAsync(getSurveys, true);

    return (
        <div className={classes.wrapperClass}>
            <div>
                <Typography variant="h2">Stöðu kannanir</Typography>

                <Typography paragraph sx={{ pt: 2 }}>
                    Kærar þakkir fyrir að taka þátt í þessari könnun.
                </Typography>
                <Typography paragraph>
                    Í hverju sveitarfélagi starfar almannavarnanefnd sem vinnur að gerð hættumats og viðbragðsáætlana.
                    Ríkislögreglustjóri tekur við staðfestum viðbragðsáætlunum sveitarfélaga.
                </Typography>
                <Typography paragraph>
                    Könnunin er liður í starfi Almannavarnadeildar ríkislögreglustjórans til að uppfylla kröfur í 7. gr.
                    laga um almannavarnir þar sem segir að ríkislögreglustjóri hafi eftirlit með almannavörnum
                    sveitarfélaga. Ætlunin er að gera sambærilega könnun árlega sem hluta af virku eftirliti með
                    almannavörnum sveitarfélaga.
                </Typography>
                <Typography paragraph>
                    Ef um nýtt sameinað sveitarfélag er að ræða, ættu svörin einungis að miðast við nýja sveitarfélagið.
                    Ljóst er að vinna við sameiningu sveitarfélaga getur haft áhrif á niðurstöður könnunarinnar. Ný
                    sveitarfélög verða því skoðuð sérstaklega í greiningunni.
                </Typography>
                <Typography paragraph>
                    Nokkrir opnir athugasemdareitir eru í könnuninni sem gefa tækifæri á að gera athugasemdir eða dýpka
                    svör.
                </Typography>
                <Typography paragraph>
                    Meginþema könnunarinnar er eftirfylgni sveitarfélags með kröfum um neyðarviðbúnað sveitarfélaga.
                    Sérstök þemu í ár eru:{" "}
                    <Typography style={{ fontStyle: "italic" }} component="span">
                        yfirsýn, eldsneyti og eigin viðbúnaður.
                    </Typography>
                </Typography>
                <Typography paragraph>
                    Ef takmörkuð þekking er á efni einnar eða fleiri spurninga, er nauðsynlegt að ráðfæra sig við eða
                    senda eyðublað til samstarfsmanns.
                </Typography>
            </div>
            {/* TODO: Style errors */}
            {status == "error" && <FullPageError message={error || "Something went wrong"} />}
            {/* TODO: Show Loading */}
            {status == "pending" && <Spinner />}
            {status == "success" && surveys && (
                <AlmEnhancedTable
                    items={surveys}
                    searchFields={[
                        "id",
                        "name",
                        "organization.name",
                        "organization.type",
                        "organization.postalCode.areaName",
                        "organization.postalCode.postalName",
                    ]}
                    columns={[
                        {
                            id: "isPublished",
                            label: "Lokið",
                            onRender: (itm, key) =>
                                get(itm, key) ? (
                                    <CheckIcon style={{ color: "green" }} />
                                ) : (
                                    <AccessTimeIcon color="primary" />
                                ),
                        },
                        { id: "id", label: "Einkenni" },
                        { id: "organization.name", label: "Stofnun" },
                        { id: "organization.type", label: "Tegund Stofnunnar" },
                        { id: "organization.postalCode.postalName", label: "Bæjarfélag" },
                        { id: "organization.postalCode.areaName", label: "Landssvæði" },
                    ]}
                    idCol="id"
                    onClick={(_ev, it) => {
                        history.push(`/${routes.surveys.edit.root}/${it.id}`);
                    }}
                    customBtn={
                        <Stack direction={"row"}>
                            <CustomIconButton
                                variant="contained"
                                color="secondary"
                                icon={<AddCircleOutlineRoundedIcon />}
                                title={"Skrá nýtt stöðumat"}
                                onClick={() => {
                                    history.push(`/${routes.surveys.new}`);
                                }}
                                resize={true}
                            />
                            <DownloadButton getToken={getXLSXDownloadToken} downloadUrl={downloadUrl} />
                        </Stack>
                    }
                />
            )}
            {status == "success" && surveys && surveys.length == 0 && (
                <Typography>Engar útfylltar kannanir fundust frá þinni stofnun.</Typography>
            )}
        </div>
    );
}

export default withStyles(styles)(SurveyOverview);
