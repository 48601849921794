import { useCallback, useContext, useEffect, useState } from "react";
import { wrapperClass } from "../../styles/Styles";
import { Spinner } from "../../components/Shared/Common";
import { AppContext } from "../../utils/AppContext";
import FullPageError from "../../components/FullPageError";
import { IOrganization, IRisk } from "../../models/RiskTypes";
import { useAsync } from "../../utils/UseAsync";
import RisksList from "../../components/Risks/RisksList";
import CommunityProjectsTable from "../../components/Risks/CommunityProjectsTable";
import RisksBowtie from "../../components/Risks/RisksBowtie";
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Divider,
    Paper,
    TextField,
    Theme,
    Autocomplete,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PrintIcon from "@mui/icons-material/Print";
import { compare, LOWER_CASE } from "almannavarnir-shared";

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        width: "35%",
    },
    containerGrid: {
        paddingTop: theme.spacing(2),
    },
    wrapperClass: {
        ...wrapperClass.wrapperClass,
        paddingTop: theme.spacing(2),
    },
    gridItem: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    printButton: {
        "@media print": {
            display: "none!important",
        },
        "@media screen and (max-width: 600px)": {
            fontSize: 0,
            paddingRight: "13px",
        },
        color: "white",
        position: "fixed",
        right: 20,
    },
}));

export interface RiskAnalysisProps {
    getOrganizations: () => Promise<IOrganization[]>;
    getRisksAnalysis: (ID?: number) => Promise<IRisk[]>;
}

function RiskAnalysis({ getOrganizations, getRisksAnalysis }: RiskAnalysisProps) {
    const classes = useStyles();
    const { user } = useContext(AppContext);
    const {
        status: organizationsFetchStatus,
        value: organizations,
        error: organizationsFetcherror,
    } = useAsync(getOrganizations);
    const [selectedOrg, setSelectedOrg] = useState<number | undefined>(user?.organizations[0].id);

    const [checked, setChecked] = useState(false);
    const allRiskDetails = useCallback(
        () => (selectedOrg ? getRisksAnalysis(checked ? undefined : selectedOrg) : Promise.resolve(null)),
        [selectedOrg, getRisksAnalysis, checked]
    );
    const {
        status: allRiskDetailsFetchStatus,
        value: risks,
        error: allRiskDetailsFetchError,
    } = useAsync(allRiskDetails);
    const [isPrinting, setIsPrinting] = useState(false);

    useEffect(() => {
        if (isPrinting) {
            console.log("Starting printing");
            window.print();
            console.log("Done printing");
            setIsPrinting(false);
        }
    }, [isPrinting]);

    return (
        <div className={classes.wrapperClass}>
            {(organizationsFetchStatus === "pending" || allRiskDetailsFetchStatus === "pending") && <Spinner />}
            {organizationsFetchStatus === "success" &&
                allRiskDetailsFetchStatus === "success" &&
                organizations !== null && (
                    <div>
                        <Typography variant="h2" sx={{ pb: 2 }}>
                            Yfirlit Áhættna
                        </Typography>
                        {organizations.length > 1 && !isPrinting && (
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={(organizations || [])
                                            .map((org) => org.name)
                                            .sort((a, b) => -compare(a, b, 0, LOWER_CASE))}
                                        style={{ width: 350 }}
                                        renderInput={(params) => (
                                            <TextField variant="outlined" {...params} label="Stofnun" />
                                        )}
                                        onChange={(_event, newInputValue) => {
                                            setSelectedOrg(
                                                organizations?.find((org) => org.name === newInputValue)?.id
                                            );
                                        }}
                                        value={organizations?.find((org) => org.id === selectedOrg)?.name ?? ""}
                                        disabled={checked}
                                    />
                                    {user !== undefined && user.isAlmAdmin && (
                                        <FormGroup sx={{ ml: 2 }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={checked} onChange={() => setChecked(!checked)} />
                                                }
                                                label="Sjá fyrir allar stofnanir"
                                            />
                                        </FormGroup>
                                    )}
                                </div>
                                <Button
                                    disableElevation
                                    // className={classes.printButton}
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    onClick={() => {
                                        setIsPrinting(true);
                                    }}
                                    style={{ zIndex: 1, top: "7%" }}
                                >
                                    Prenta
                                </Button>
                            </div>
                        )}

                        {selectedOrg !== undefined &&
                            risks !== null &&
                            (risks.length > 0 ? (
                                <>
                                    <Paper
                                        elevation={2}
                                        style={{
                                            marginTop: "10px",
                                            padding: "16px",
                                            paddingTop: "0px",
                                            pageBreakAfter: "always",
                                        }}
                                    >
                                        <div style={{ paddingTop: "1px" }}>
                                            <h3>{`Heildaryfirlit allra áhættna - ${
                                                checked
                                                    ? "Allar stofnanir"
                                                    : organizations?.find((org) => org.id === selectedOrg)?.name
                                            }`}</h3>
                                        </div>
                                        <Divider />
                                        <RisksList allRisks={risks} almAdmin={checked} />
                                        {!checked && <CommunityProjectsTable isPrinting={isPrinting} risks={risks} />}
                                    </Paper>
                                    <RisksBowtie isPrinting={isPrinting} allRisks={risks} />
                                </>
                            ) : (
                                <FullPageError message={"Þín stofnun hefur engar áhættur"} />
                            ))}
                        {selectedOrg === undefined && <FullPageError message={"Vinsamlegast veljið stofnun!"} />}
                    </div>
                )}
            {(organizationsFetchStatus === "error" || allRiskDetailsFetchStatus === "error") && (
                <FullPageError
                    message={
                        allRiskDetailsFetchError ||
                        organizationsFetcherror ||
                        "Eitthvað fór úrskeiðis við að sækja gögn"
                    }
                />
            )}
        </div>
    );
}

export default RiskAnalysis;
