import { createTheme, responsiveFontSizes } from "@mui/material";

export const BetaTheme = responsiveFontSizes(
    createTheme({
        palette: {
            primary: {
                main: "#e09e3d",
            },
            secondary: {
                main: "#3571bd",
            },
            text: {
                primary: "#0c090a",
            },
            success: {
                main: "#3B873E",
            },
            background: {
                default: "#fafafa",
            },
            grey: {
                200: "#C6C6C6",
            },
            error: {
                main: "#F44336",
            },
            info: {
                main: "#ffffff",
            },
        },
        typography: {
            fontFamily: "PT Sans Pro,sans-serif",
            h1: {
                fontSize: "2.5rem",
            },
            h2: {
                fontSize: "2.1rem",
                fontWeight: 800,
            },
            h3: {
                fontSize: "2rem",
            },
            h4: {
                fontSize: "1.8rem",
            },
            h5: {
                fontSize: "1.5rem",
                fontWeight: 700,
            },
            h6: {
                fontSize: "1.4rem",
            },
        },
        components: {
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        borderRadius: "0px",
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        borderRadius: "0px",
                    },
                },
            },
            MuiBackdrop: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#fafafab0",
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#ffffff",
                        "& .Mui-disabled": {
                            backgroundColor: "#fafafa",
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        boxShadow: "none",
                        textTransform: "none",
                        borderRadius: 0,
                        fontWeight: 700,
                        fontSize: "1rem",
                        height: "56px",
                        color: "#ffffff",
                    },
                },
            },
        },
    })
);
