import { Spinner } from "../../components/Shared/Common";
import { useAsync } from "../../utils/UseAsync";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import FullPageError from "../../components/FullPageError";
import { SurveyForm } from "../../components/Survey/SurveyForm";
import { IOrganization, ISurvey } from "../../models/RiskTypes";
import { EditPageParams } from "../../routes/routes";

export interface EditSurveyProps {
    getSurvey: (ID: number) => Promise<ISurvey>;
    updateSurvey: (risk: ISurvey) => Promise<any>;
    getOrganizations: () => Promise<IOrganization[]>;
}

export default function EditRisk({ getSurvey, updateSurvey, getOrganizations }: EditSurveyProps) {
    const { ID: strID } = useParams<EditPageParams>();
    const ID = useMemo(() => parseInt(strID ?? "0"), [strID]);
    const wrappedGetRisk = useCallback(() => (ID ? getSurvey(ID) : Promise.resolve(null)), [ID, getSurvey]);
    const { status, value: survey, error } = useAsync(wrappedGetRisk);

    return (
        <div>
            <div>
                {status == "pending" && <Spinner />}
                {/* TODO: Style error */}
                {status == "error" && <FullPageError message={error || "Something went wrong"} />}
                {status == "success" && survey && (
                    <SurveyForm
                        title="Breyta könnun"
                        survey={survey}
                        onSubmit={updateSurvey}
                        getOrganizations={getOrganizations}
                    />
                )}
            </div>
        </div>
    );
}
