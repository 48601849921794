import { Typography } from "@mui/material";

export enum causeEffect {
    cause = 1,
    effect = 2,
    prevention = 3,
}

export interface CauseEffectCardProps {
    text: string;
    type: causeEffect;
    isPrinting: boolean;
}

const CauseEffectCard = ({ text, type, isPrinting }: CauseEffectCardProps) => {
    const customStyle =
        type === causeEffect.cause
            ? { backgroundColor: "#3571BD", border: isPrinting ? "solid 2px #3571BD" : "" }
            : type === causeEffect.effect
            ? { backgroundColor: "#F44336", border: isPrinting ? "solid 2px #F44336" : "" }
            : { backgroundColor: "#E09E3D", marginLeft: "15px", border: isPrinting ? "solid 2px #E09E3D" : "" };
    return (
        <Typography
            style={{
                borderRadius: "8px",
                width: "fit-content",
                color: "#ffffff",
                lineHeight: "150%",
                padding: "8px",
                marginTop: "5px",
                marginBottom: "5px",
                ...customStyle,
            }}
        >
            {text}
        </Typography>
    );
};

export default CauseEffectCard;
