import Grid from "@mui/material/Grid";
import { Button, useTheme } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { RedirectType } from "../../utils/Utilities";

import mergeWith from "lodash.mergewith";
import cloneDeep from "lodash.clonedeep";
import { routes } from "../../routes/routes";
import { history } from "../../components/Shared/Common";

import { IMinimumPlace, IPlace, newMinimumPlace } from "../../models/PlaceTypes";
import FormError from "../../components/FormError";
import PlacesIntro from "../../components/Places/placesIntro";
import { IOrganization } from "../../models/RiskTypes";
import { useAsync } from "../../utils/UseAsync";
import { AlmSelect, AlmTextField, compare, LOWER_CASE } from "almannavarnir-shared";
export interface IPlaceFormProps {
    onSubmit: (place: IMinimumPlace) => Promise<Required<Pick<IPlace, "id">>>;
    getOrganizations: () => Promise<IOrganization[]>;
}

export function NewPlace({ onSubmit, getOrganizations }: IPlaceFormProps) {
    const theme = useTheme();
    const inputVariant = "outlined";
    const [minimumPlace, setPlace] = useState<IMinimumPlace>(
        mergeWith(cloneDeep(newMinimumPlace), null, (val, srcVal) => (srcVal === null ? val : undefined))
    );

    const getFilteredOrgs = useCallback(
        () => getOrganizations().then((orgs) => orgs.filter((org) => org.hasPlaces)),
        [getOrganizations]
    );

    const { status: organizationsFetchStatus, value: organizations } = useAsync(getFilteredOrgs);
    const [error, setError] = useState<string | undefined>(undefined);

    const handleChange = useCallback(
        (
            property: keyof IMinimumPlace,
            newVal: string | number | Pick<IMinimumPlace, "id"> | Pick<IOrganization, "id">
        ) => {
            setError("");
            // console.log("setting minimal place:", { ...minimumPlace, [property]: newVal });
            setPlace({ ...minimumPlace, [property]: newVal });
        },
        [setPlace, minimumPlace]
    );

    function validateFields(minimumPlace: IMinimumPlace): string[] | boolean {
        const errorList: string[] = [];
        for (const prop in minimumPlace) {
            if (Object.prototype.hasOwnProperty.call(minimumPlace, prop)) {
                if (
                    prop === "title" &&
                    (minimumPlace.title.length > 50 || minimumPlace.title === null || minimumPlace.title === "")
                )
                    errorList.push("Titil");
                if (
                    prop === "accessAddress" &&
                    (minimumPlace.accessAddress === "" || minimumPlace.accessAddress.length != 7)
                )
                    errorList.push("Heitinúmer Staðfangs");
                if (prop === "organization" && minimumPlace.organization.id <= 0) errorList.push("Stofnun");
            }
        }
        return errorList.length > 0 ? errorList : false;
    }

    function isMinimumPlaceValid(
        minimumPlace: IMinimumPlace,
        setError: React.Dispatch<React.SetStateAction<string | undefined>>
    ): boolean {
        let isValid = true;
        const fieldError = validateFields(minimumPlace);
        if (fieldError !== false) {
            const errorString = `Vinsamlegast gættu að hafa alla reiti rétt úfyllta: athugaðu ${fieldError}.`;
            setError(errorString);
            isValid = false;
        }
        return isValid;
    }

    const handleSubmit = (newPlace: IMinimumPlace, redirectType: RedirectType = RedirectType.Home) => {
        setError(undefined);
        if (!isMinimumPlaceValid(newPlace, setError)) return;
        return onSubmit(newPlace)
            .then((res) => {
                if (redirectType == RedirectType.Edit) {
                    history.push(`/${routes.places.edit.root}/${res.id}`);
                } else if (redirectType == RedirectType.Home) {
                    history.push(`/${routes.places.root}`);
                }
            })
            .catch((err: string | Error) => {
                setError((typeof err == "string" ? err : err.message) || "Eitthvað fór úrskeiðis við að vista");
            });
    };

    useEffect(() => {
        organizations && handleChange("organization", organizations[0]);
    }, [organizations]);

    return (
        <div
            style={{
                margin: "0 auto",
                maxWidth: "720px",
                paddingBottom: theme.spacing(2),
            }}
        >
            {error && <FormError message={error} />}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <PlacesIntro isExpandedByDefault={true} />
                </Grid>
                <Grid item container spacing={3}>
                    <Grid item xs={12}>
                        <AlmTextField
                            required={true}
                            maxLength={50}
                            variant={inputVariant}
                            surveyProperty="title"
                            label="Titill"
                            value={minimumPlace.title}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item container spacing={{ xs: 3, sm: 2 }}>
                        <Grid item sm={6} xs={12}>
                            <AlmSelect
                                required={true}
                                disabled={
                                    organizationsFetchStatus !== "success" ||
                                    organizations == undefined ||
                                    organizations.length == 1
                                }
                                variant={inputVariant}
                                surveyProperty="organization"
                                label="Stofnun"
                                value={minimumPlace.organization.id}
                                onChange={(prop, newVal) => {
                                    handleChange(prop, { id: newVal });
                                }}
                                options={(organizations || [])
                                    .map((org) => ({ label: org.name, value: org.id }))
                                    .sort((a, b) => -compare(a.label, b.label, 0, LOWER_CASE))}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <AlmTextField
                                required={true}
                                maxLength={7}
                                variant={inputVariant}
                                surveyProperty="accessAddress"
                                label="Heitinúmer staðfangs"
                                value={minimumPlace.accessAddress ?? ""}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item container spacing={2} xs={12} sx={{ mt: 1 }}>
                    <Grid item xs={6}>
                        <Button
                            disableElevation
                            size="large"
                            variant={inputVariant}
                            color="secondary"
                            onClick={() => {
                                history.push(`/${routes.places.root}`);
                            }}
                            sx={{ color: theme.palette.secondary.main, width: "100%" }}
                        >
                            Hætta við
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            disableElevation
                            disabled={
                                !minimumPlace.organization?.id ||
                                minimumPlace.title === "" ||
                                minimumPlace.accessAddress.length != 7
                            }
                            size="large"
                            variant={"contained"}
                            color="primary"
                            onClick={() => {
                                handleSubmit(minimumPlace, RedirectType.Edit);
                            }}
                            sx={{ width: "100%" }}
                        >
                            Stofna skráningu
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
