import { Theme } from "@mui/material/styles";
import { CSSProperties } from "@mui/styles";

export const getContentWrapperClass = (theme: Theme) => ({
    ...wrapperClass.wrapperClass,
    paddingTop: theme.spacing(2),
});

export const wrapperClass = {
    wrapperClass: {
        maxWidth: 1240,
        margin: "0 auto",
        padding: "0px 16px",
    },
};

export const hideInPrint = {
    "@media print": {
        display: "none!important",
    },
};
export const hideButtonText = {
    "@media screen and (max-width: 600px)": {
        fontSize: 0,
        paddingRight: "13px",
    },
};

export const showOnlyInPrint: CSSProperties = {
    display: "none",
    "@media print": {
        display: "initial",
    },
};

export const printOnNewPage: CSSProperties = {
    "@media print": {
        pageBreakBefore: "always",
    },
};

export const spinnerContainer = (theme: Theme) => ({
    spinnerContainer: {
        width: "fit-content",
        paddingTop: theme.spacing(5),
    },
});
