import {
    FormControl,
    SelectProps,
    Select,
    MenuItem,
    FormHelperText,
    InputLabel,
    Checkbox,
    ListItemText,
    useTheme,
} from "@mui/material";
import find from "lodash.find";
import { useMemo } from "react";
import { SurveySelectOption } from "../../models/FormValues";
import { IOrganization, IRisk, ISurvey, IUserOrganization, OrganizationUser } from "../../models/RiskTypes";

import { isValueEmpty } from "../../utils/Utilities";
import { IPlace } from "../../models/PlaceTypes";

// eslint-disable-next-line @typescript-eslint/ban-types
export type customObjectArray = object[];
// eslint-disable-next-line @typescript-eslint/ban-types
type valueType = number | string | number[] | string[] | customObjectArray | object;
type internalValueType = number | string | number[] | string[];

function incomingValueIsNotCustomObjectArray(value: valueType): value is string | number | number[] | string[] {
    // ): value is Exclude<valueType, customObjectArray | object> {
    return (
        typeof value != "object" ||
        (Array.isArray(value) && (typeof value[0] === "number" || typeof value[0] === "string"))
    );
}

function outgoingValueMayBeCustomObjectArray(value: valueType): value is string[] {
    return Array.isArray(value) && typeof value[0] == "string";
}

function outgoingValueMayBeCustomObject(value: valueType): value is string {
    return typeof value == "string";
}

export type SurveySelectProps<
    T extends valueType,
    U extends ISurvey | IRisk | IOrganization | OrganizationUser | IUserOrganization | IPlace
> = Omit<SelectProps, "onChange" | "value"> & {
    variant: "filled" | "standard" | "outlined";
    surveyProperty: Extract<keyof U, string>;
    label?: string;
    helperText?: string;
    errorText?: string;
    title?: string;
    value?: T;
    checkbox?: boolean;
    options: SurveySelectOption<
        // eslint-disable-next-line @typescript-eslint/ban-types
        T extends number[] ? number : T extends customObjectArray | string[] | object ? string : T
    >[];
    onChange: (property: keyof U, newVal: T) => void;
};

function SurveySelect<
    T extends valueType,
    U extends ISurvey | IRisk | IOrganization | OrganizationUser | IUserOrganization | IPlace
>({
    variant,
    surveyProperty,
    label,
    helperText,
    errorText,
    title,
    value,
    error,
    options,
    onChange,
    ...rest
}: SurveySelectProps<T, U>) {
    const labelId = `survey-form-${surveyProperty}-select`;
    const theme = useTheme();

    // T extends number[] ? number[] : T extends string[] | customObjectArray ? string[] : T extends object ? string : T
    const encodedValue: internalValueType = useMemo(() => {
        if (value == undefined) return "";
        if (incomingValueIsNotCustomObjectArray(value)) return value;
        return Array.isArray(value) ? value.map((val) => JSON.stringify(val)) : JSON.stringify(value);
    }, [value]);

    const notEmpty = useMemo(() => {
        return !isValueEmpty(encodedValue);
    }, [encodedValue]);
    function isChecked(val: number, encodedValue: number[]): boolean {
        return encodedValue.indexOf(val) > -1;
    }
    return (
        <>
            {title && (
                <FormHelperText style={{ marginBottom: "7px", flex: "auto", fontSize: "0.85rem", color: "#000000" }}>
                    {title}
                </FormHelperText>
            )}
            <>
                {helperText && (
                    <FormHelperText style={{ marginBottom: "7px", flex: "auto", fontStyle: "italic" }}>
                        {helperText}
                    </FormHelperText>
                )}
                {rest.multiple && (
                    <FormHelperText style={{ marginBottom: "7px", fontStyle: "italic" }}>
                        {"Merkja má við eitt svar eða fleiri."}
                    </FormHelperText>
                )}
            </>

            <FormControl
                variant={variant}
                sx={{
                    "& .MuiSelect-icon": {
                        color: theme.palette.primary.main,
                    },
                    width: "100%",
                    "& label": {
                        color: notEmpty ? theme.palette.success.main : error ? theme.palette.error.main : undefined,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${
                            notEmpty ? theme.palette.success.light : error ? theme.palette.error.light : undefined
                        }`,
                        "&:hover": {
                            borderWidth: "1px",
                        },
                    },
                    "&:hover": {
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderWidth: "1px",
                        },
                    },
                }}
            >
                {label && (
                    <InputLabel id={labelId} htmlFor={surveyProperty}>
                        {label}
                    </InputLabel>
                )}
                <Select
                    sx={{
                        color: theme.palette.success.main,
                        height: "56px",
                        "&>div": {
                            margin: 0,
                            "&>span": {
                                lineHeight: 1,
                            },
                        },
                    }}
                    name={surveyProperty}
                    variant={variant}
                    {...(label ? { labelId: labelId, label: label } : {})}
                    value={encodedValue}
                    onChange={(ev) => {
                        let newVal = ev.target.value as valueType;
                        if (outgoingValueMayBeCustomObjectArray(newVal)) {
                            /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
                            try {
                                newVal = newVal.map((val) => JSON.parse(val));
                            } catch (error) {}
                        }
                        if (outgoingValueMayBeCustomObject(newVal)) {
                            try {
                                newVal = JSON.parse(newVal);
                            } catch (error) {}
                        }
                        onChange(surveyProperty, newVal as T);
                    }}
                    {...rest}
                    {...(rest.multiple
                        ? {
                              renderValue: (selected) =>
                                  (selected as number[])
                                      .map((val) => find(options, (opt) => opt.value === val)?.label)
                                      .join(", "),
                          }
                        : {})}
                >
                    {options.map((opt) => (
                        <MenuItem key={opt.value} value={opt.value}>
                            {rest.multiple && (
                                //If keys are strings, this needs to be looked at
                                <Checkbox checked={isChecked(opt.value as number, encodedValue as number[])} />
                            )}
                            <ListItemText style={{ whiteSpace: "normal" }} primary={opt.label} />
                        </MenuItem>
                    ))}
                </Select>
                {error && <FormHelperText sx={{ color: "error" }}>{errorText}</FormHelperText>}
            </FormControl>
        </>
    );
}

export default SurveySelect;
