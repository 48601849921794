import { useEffect, useState } from "react";
import { RiskUser, Roles } from "../models/RiskUser";

export function replaceAtIndex<Type>(array: Type[], index: number, value: Type): Type[] {
    const ret = array.slice(0);
    ret[index] = value;
    return ret;
}

export enum RedirectType {
    Home,
    Edit,
    Success,
    None,
}

export const copyRichText = async (elementID: string): Promise<void> => {
    const elem = document.getElementById(elementID);

    if (elem !== null) {
        if (elementID === "CopyToClipboardCommunityProjectsTable")
            elem.getElementsByTagName("table")[0].setAttribute("border", "1");
        const content = elem.innerHTML;
        const listener = (e: ClipboardEvent) => {
            if (e.clipboardData !== null) {
                e.clipboardData.setData("text/html", content);
                e.clipboardData.setData("text/plain", content);
                e.preventDefault();
            }
        };
        document.addEventListener("copy", listener);
        document.execCommand("copy");
        document.removeEventListener("copy", listener);
    }
};

export const isValueEmpty = (val: number | string | number[] | string[] | undefined | null): boolean => {
    if (val === undefined || val === null) return true;
    if (typeof val === "number") {
        return val === undefined;
    }

    return val.length === 0;
};
interface shouldShowTypes {
    places: boolean;
    risks: boolean;
    surveys: boolean;
}

export const useShouldShow = (user: RiskUser | undefined) => {
    const [show, setShow] = useState<shouldShowTypes>({
        places: false,
        risks: false,
        surveys: false,
    });
    useEffect(() => {
        const orgs = user?.organizations;
        let newState: shouldShowTypes = show;
        orgs?.forEach((org) => {
            if (
                org.roles.includes(Roles.placeAdmin) ||
                org.roles.includes(Roles.placeEdit) ||
                org.roles.includes(Roles.almAdmin) ||
                org.roles.includes(Roles.almEdit)
            ) {
                newState = { ...newState, places: true };
                // setShow((prev) => {
                //     return { ...prev, places: true };
                // });
            }
            if (
                org.roles.includes(Roles.riskAdmin) ||
                org.roles.includes(Roles.riskEdit) ||
                org.roles.includes(Roles.almAdmin) ||
                org.roles.includes(Roles.almEdit)
            ) {
                newState = { ...newState, risks: true };
                // setShow((prev) => {
                //     return { ...prev, risks: true };
                // });
            }
            if (
                org.roles.includes(Roles.surveyAdmin) ||
                org.roles.includes(Roles.surveyEdit) ||
                org.roles.includes(Roles.almAdmin) ||
                org.roles.includes(Roles.almEdit)
            ) {
                newState = { ...newState, surveys: true };
                // setShow((prev) => {
                //     return { ...prev, surveys: true };
                // });
            }
        });
        setShow(newState);
    }, [user]);
    return show;
};
