import { createBrowserHistory } from "history";
import { useLocation } from "react-router-dom";
import { daysOfWeek, months } from "./DateConstants";

export function sleep(ms: number) : Promise<unknown>{
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export const history = createBrowserHistory();

export const getIcelandicTime = (date: Date) : string =>
    date.toLocaleTimeString("is-IS", { hour12: false, hour: "2-digit", minute: "2-digit" });

export const getIcelandicDate = (date: Date) :string=>
    `${daysOfWeek[date.getDay()]}, ${date.getDate()}. ${months[date.getMonth()]}`;

export const downloadFromUrl = (uri: string, fileName: string) : void=> {
    const link = document.createElement("a");
    link.download = fileName;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const useQuery = () : URLSearchParams=> {
    return new URLSearchParams(useLocation().search);
};

type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]];

export type Join<K, P> = K extends string | number
    ? P extends string | number
        ? `${K}${"" extends P ? "" : "."}${P}`
        : never
    : never;

export type Leaves<T, D extends number = 10> = [D] extends [never]
    ? never
    // eslint-disable-next-line @typescript-eslint/ban-types
    : T extends object
    ? { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T]
    : "";

export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<T>;
