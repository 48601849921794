export interface IRisk {
    id?: number;
    name: string;
    contactIDCreatedBy?: number;
    contactIDSubmittedBy?: number;
    dateCreated?: string;
    dateSubmitted?: string;
    probability: number | undefined;
    organization?: IOrganization;
    typeID: number | undefined;
    categoryID: number | undefined;
    type: RiskTypeOrCategory | undefined;
    category: RiskTypeOrCategory | undefined;
    causes: ICauseOrEffect[];
    effects: IEffects;
    customEffects: ICauseOrEffect[];
    isPublished: boolean;

    description: string;
    causesDescription: string;
    preventionDescription: string;
    probabilityDescription: string;

    probabilityArgument: string;
    vulnerabilityAssessment: string;
    summaryArgumentForEffects: string;
    needForPublicWarning: string;
    needForEvacuation: string;
    uncertainty: number | undefined;
    uncertaintyArgument: string;
    canRiskFactorsBeControlled: number | undefined;
    riskFactorControlArgument: string;
    proposedPreventions: string;
    possibleToTransferToAnotherScenario: string;
    locationLat: number | undefined;
    locationLon: number | undefined;
    postalCodeID: number | undefined;

    affectedCommunityProjects: number[];
}

export type RiskWithAffectedProjects = Pick<IRisk, "id" | "name" | "organization" | "affectedCommunityProjects">;

export interface IncompleteSurvey {
    organizationID: number;
    surveyID: number;
    dateCreated: string;
    dateSubmitted: string;
    createdBy: number;
    submittedBy: number;
}

export interface ISurvey extends Partial<IncompleteSurvey> {
    id?: number;
    name: string;
    isPublished: boolean;
    organization?: IOrganization;
    q1_AnalyzeRisk: number | undefined;
    q3_P1_AnalyzeRisk: number | undefined;
    q3_P2_AnalyzeRisk: number | undefined;
    q3_P3_AnalyzeRisk: number | undefined;
    q4_P1_AnalyzeRisk: number | undefined;
    q4_P2_AnalyzeRisk: number | undefined;
    q4_P3_AnalyzeRisk: number | undefined;
    q4_P4_AnalyzeRisk: number | undefined;
    q4_P5_AnalyzeRisk: number | undefined;
    q4_P6_AnalyzeRisk: number | undefined;
    disasterToleranceDesc: string;
    q5_HasPreparedObjectives: number | undefined;
    q6_HasPreparedExecutionPlan: number | undefined;
    q7_WorkRegardingSafety: number[];
    generalSafetyWorkDescription: string;
    q8_RecoveryPlanAvailable: number | undefined;
    q9_RecoveryPlanLastReview: number | undefined;
    q10_RecoveryPlanBasedOnRiskAnalysis: number | undefined;
    q11_P1_RecoveryPlan: number | undefined;
    q11_P2_RecoveryPlan: number | undefined;
    q11_P3_RecoveryPlan: number | undefined;
    q11_P4_RecoveryPlan: number | undefined;
    q11_P5_RecoveryPlan: number | undefined;
    q11_P6_RecoveryPlan: number | undefined;
    recoveryPlanFurtherDescription: string;
    q12_PerformedTraining: number | undefined;
    performedTrainingDescription: string;
    q13_AnalyzeRiskConfirmed: number | undefined;
    q14_ExecutionPlanConfirmed: number | undefined;
    q15_RecoveryPlanConfirmed: number | undefined;
    confirmationDescription: string;
    q16_P1_Collaboration: number[];
    q16_P2_Collaboration: number[];
    q16_P3_Collaboration: number[];
    q16_P4_Collaboration: number[];
    q16_P5_Collaboration: number[];
    q17_EstablishedEmergencyControl: number | undefined;
    collaborationFurtherDescription: string;
    themeOverview_Q1_CommunicationTools: number[];
    themeOverview_Q2_MapSolutions: number[];
    themeOverview_Q3_SystemUsed: number | undefined;
    themeOverview_FurtherDetails: string;
    themeFuel_Q1_FuelSupply: number | undefined;
    themeFuel_Q2_FuelSupplyPriority: string;
    themeFuel_Q3_EmergencyFuelDistribution: number | undefined;
    themeFuel_Q4_EmergencyPreparation: string;
    themeFuel_Q4_P1_Situation: number | undefined;
    themeFuel_Q4_P2_Situation: number | undefined;
    themeFuel_Q5_SituationDesc: string;
    themeFuel_Q6_ProblemsOccured: number | undefined;
    themeFuel_Q7_ProblemsOccuredExplanation: string;
    themeFuel_Q8_ProblemsAccessFuel: number | undefined;
    themeFuel_Q9_ProblemsAccessFuelExplanation: string;
    themeOwnPreparation_Q1_WeAreAllAlmannavarnir: number | undefined;
    themeOwnPreparation_Q2_WhatOperation: number[];
    themeOwnPreparation_Q2_WhatOperation_Description: string;
    themeOwnPreparation_Q3_Proposals: string;
    howMuchTimeSpentOnEmergencyControl_Perc: number | undefined;
    surveyFeedback: string;
    submitterJobRole: string;
}

export interface BackendRisk extends Omit<IRisk, "affectedCommunityProjects"> {
    affectedCommunityProjects: string;
}

export interface BackendSurvey
    extends Omit<
        ISurvey,
        | "q7_WorkRegardingSafety"
        | "q16_P1_Collaboration"
        | "q16_P2_Collaboration"
        | "q16_P3_Collaboration"
        | "q16_P4_Collaboration"
        | "q16_P5_Collaboration"
        | "themeOverview_Q1_CommunicationTools"
        | "themeOverview_Q2_MapSolutions"
        | "themeOwnPreparation_Q2_WhatOperation"
    > {
    q7_WorkRegardingSafety: string;
    q16_P1_Collaboration: string;
    q16_P2_Collaboration: string;
    q16_P3_Collaboration: string;
    q16_P4_Collaboration: string;
    q16_P5_Collaboration: string;
    themeOverview_Q1_CommunicationTools: string;
    themeOverview_Q2_MapSolutions: string;
    themeOwnPreparation_Q2_WhatOperation: string;
}

export interface IOrganization {
    id: number;
    name: string;
    hasRisks: boolean;
    hasSurveys: boolean;
    hasPlaces: boolean;
    type?: string | undefined;
    typeID?: number | undefined;
    address?: string | undefined;
    postalCode?: PostalCode | undefined;
    postalCodeID?: number | undefined;
}

export interface IUserOrganization extends Partial<Pick<IOrganization, "id" | "name">> {
    //TODO skoda Er þetta bara pure number, ekkert enum sem við getum notað frekar einu sinni?
    roles: number[];
}

export interface PostalCode {
    postalCodeID: number;
    postalCode: string;
    postalName: string;
    areaName: string;
    areaSortOrder: number;
    place: string;
}

export interface OrganizationUser {
    id: number;
    kennitala: string;
    firstName: string;
    lastName: string;
    mobilePhone: string;
    email: string;
    organizations: IUserOrganization[];
    activeStatus: boolean;
}

export interface RiskTypeOrCategory {
    id: number;
    name: string;
}

export interface RiskType extends RiskTypeOrCategory {
    categories: RiskTypeOrCategory[];
}

export interface PostCode {
    postalCodeID: number;
    postalCode: string;
    postalName: string;
    areaName: string;
    areaSortOrder: number;
    place: string;
}

export interface ICauseOrEffect {
    id: number;
    name: string;
    preventions: ICausePrevention[];
    isNew?: boolean;
    shouldDelete?: boolean;
}

export interface ICausePrevention {
    id: number;
    name: string;
    isNew?: boolean;
    shouldDelete?: boolean;
}

export interface IEffects {
    lifeAndHealth: IAssessmentFactor<"numberOfDeceased" | "numberInjuredOrSick">;
    stability: IAssessmentFactor<
        | "basicNeedsUnfulfilled_Duration"
        | "basicNeedsUnfulfilled_NumPeople"
        | "disturbancesToDailyLife_Duration"
        | "disturbancesToDailyLife_NumPeople"
    >;
    natureAndEnvironment: IAssessmentFactor<
        | "permanentDamageToNature_Duration"
        | "permanentDamageToNature_Area"
        | "permanentDamageToCulture_Scope"
        | "permanentDamageToCulture_Protection"
    >;
    physicalValuables: IAssessmentFactor<"directEconomicalDamage" | "indirectEconomicalDamage">;
}

export type IAssessmentFactor<T extends string> = Record<T, IEffectAssessment> & {
    preventions: IEffectPrevention[];
};

export interface IEffectAssessment {
    weight: number | undefined;
    description: string;
}

export interface IEffectPrevention {
    title: string;
}

//#region Default
export const defaultEffects: IEffects = {
    lifeAndHealth: {
        numberInjuredOrSick: {
            weight: undefined,
            description: "",
        },
        numberOfDeceased: {
            weight: undefined,
            description: "",
        },
        preventions: [],
    },
    stability: {
        basicNeedsUnfulfilled_Duration: {
            weight: undefined,
            description: "",
        },
        basicNeedsUnfulfilled_NumPeople: {
            weight: undefined,
            description: "",
        },
        disturbancesToDailyLife_Duration: {
            weight: undefined,
            description: "",
        },
        disturbancesToDailyLife_NumPeople: {
            weight: undefined,
            description: "",
        },
        preventions: [],
    },
    natureAndEnvironment: {
        permanentDamageToNature_Duration: {
            weight: undefined,
            description: "",
        },
        permanentDamageToNature_Area: {
            weight: undefined,
            description: "",
        },
        permanentDamageToCulture_Scope: {
            weight: undefined,
            description: "",
        },
        permanentDamageToCulture_Protection: {
            weight: undefined,
            description: "",
        },
        preventions: [],
    },
    physicalValuables: {
        directEconomicalDamage: {
            weight: undefined,
            description: "",
        },
        indirectEconomicalDamage: {
            weight: undefined,
            description: "",
        },
        preventions: [],
    },
};

export const newRisk: IRisk = {
    name: "",
    typeID: undefined,
    categoryID: undefined,
    type: undefined,
    category: undefined,
    probability: undefined,
    effects: defaultEffects,
    causes: [],
    customEffects: [],
    isPublished: false,
    probabilityArgument: "",
    vulnerabilityAssessment: "",
    summaryArgumentForEffects: "",
    needForPublicWarning: "",
    needForEvacuation: "",
    uncertainty: undefined,
    uncertaintyArgument: "",
    canRiskFactorsBeControlled: undefined,
    riskFactorControlArgument: "",
    proposedPreventions: "",
    possibleToTransferToAnotherScenario: "",
    locationLat: undefined,
    locationLon: undefined,
    description: "",
    causesDescription: "",
    preventionDescription: "",
    probabilityDescription: "",
    postalCodeID: undefined,
    affectedCommunityProjects: [],
};

export const defaultSurvey: ISurvey = {
    name: "",
    isPublished: false,
    q1_AnalyzeRisk: undefined,
    q3_P1_AnalyzeRisk: undefined,
    q3_P2_AnalyzeRisk: undefined,
    q3_P3_AnalyzeRisk: undefined,
    q4_P1_AnalyzeRisk: undefined,
    q4_P2_AnalyzeRisk: undefined,
    q4_P3_AnalyzeRisk: undefined,
    q4_P4_AnalyzeRisk: undefined,
    q4_P5_AnalyzeRisk: undefined,
    q4_P6_AnalyzeRisk: undefined,
    disasterToleranceDesc: "",
    q5_HasPreparedObjectives: undefined,
    q6_HasPreparedExecutionPlan: undefined,
    q7_WorkRegardingSafety: [],
    generalSafetyWorkDescription: "",
    q8_RecoveryPlanAvailable: undefined,
    q9_RecoveryPlanLastReview: undefined,
    q10_RecoveryPlanBasedOnRiskAnalysis: undefined,
    q11_P1_RecoveryPlan: undefined,
    q11_P2_RecoveryPlan: undefined,
    q11_P3_RecoveryPlan: undefined,
    q11_P4_RecoveryPlan: undefined,
    q11_P5_RecoveryPlan: undefined,
    q11_P6_RecoveryPlan: undefined,
    recoveryPlanFurtherDescription: "",
    q12_PerformedTraining: undefined,
    performedTrainingDescription: "",
    q13_AnalyzeRiskConfirmed: undefined,
    q14_ExecutionPlanConfirmed: undefined,
    q15_RecoveryPlanConfirmed: undefined,
    confirmationDescription: "",
    q16_P1_Collaboration: [],
    q16_P2_Collaboration: [],
    q16_P3_Collaboration: [],
    q16_P4_Collaboration: [],
    q16_P5_Collaboration: [],
    q17_EstablishedEmergencyControl: undefined,
    collaborationFurtherDescription: "",
    themeOverview_Q1_CommunicationTools: [],
    themeOverview_Q2_MapSolutions: [],
    themeOverview_Q3_SystemUsed: undefined,
    themeOverview_FurtherDetails: "",
    themeFuel_Q1_FuelSupply: undefined,
    themeFuel_Q2_FuelSupplyPriority: "",
    themeFuel_Q3_EmergencyFuelDistribution: undefined,
    themeFuel_Q4_EmergencyPreparation: "",
    themeFuel_Q4_P1_Situation: undefined,
    themeFuel_Q4_P2_Situation: undefined,
    themeFuel_Q5_SituationDesc: "",
    themeFuel_Q6_ProblemsOccured: undefined,
    themeFuel_Q7_ProblemsOccuredExplanation: "",
    themeFuel_Q8_ProblemsAccessFuel: undefined,
    themeFuel_Q9_ProblemsAccessFuelExplanation: "",
    themeOwnPreparation_Q1_WeAreAllAlmannavarnir: undefined,
    themeOwnPreparation_Q2_WhatOperation: [],
    themeOwnPreparation_Q2_WhatOperation_Description: "",
    themeOwnPreparation_Q3_Proposals: "",
    howMuchTimeSpentOnEmergencyControl_Perc: 50,
    surveyFeedback: "",
    submitterJobRole: "",
};

//#endregion Default
