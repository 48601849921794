import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";

export interface FormErrorProps {
    message: string;
}

export default function FormError({ message }: FormErrorProps) {
    const [isOpen, setOpen] = useState(true);

    return (
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={isOpen}
            autoHideDuration={8000}
            onClose={() => {
                setOpen(false);
            }}
        >
            <Alert severity="error">{message}</Alert>
        </Snackbar>
    );
}
