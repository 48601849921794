import { Stack, Typography } from "@mui/material";
import { AlmButton, AlmFormError, AlmTextField, IPlace } from "almannavarnir-shared";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Shared/Spinner";
import { EditPageParams, routes } from "../../routes/routes";
import { useAsync } from "../../utils/UseAsync";
import { history } from "../../utils/utils";

export interface IPlaceFormProps {
    getPlace: (ID: number) => Promise<IPlace>;
    updatePlace: (place: IPlace) => Promise<any>;
}

export const SubmitPlace = ({ getPlace, updatePlace }: IPlaceFormProps) => {
    const { ID: strID } = useParams<EditPageParams>();
    const ID = useMemo(() => parseInt(strID ?? "0"), [strID]);
    const wrappedGetPlace = useCallback(() => (ID ? getPlace(ID) : Promise.resolve(null)), [ID, getPlace]);
    const { status, value: fetchedPlace, error: fetchError } = useAsync(wrappedGetPlace);
    const [error, setError] = useState<string | undefined>(undefined);
    const [place, setPlace] = useState<IPlace>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [toast, setToast] = useState(false);
    useEffect(() => {
        fetchedPlace && setPlace(fetchedPlace);
    }, [fetchedPlace]);

    const handleSubmit = (newPlace: IPlace) => {
        setIsSubmitting(true);
        return updatePlace(newPlace)
            .then(() => {
                setIsSubmitting(false);
                history.push(`/${routes.places.root}/${routes.places.success.root}`);
            })
            .catch((err: string | Error) => {
                setIsSubmitting(false);
                setError((typeof err == "string" ? err : err.message) || "Eitthvað fór úrskeiðis við að vista");
                setToast(true);
            });
    };

    const handleChange = useCallback(
        (property: keyof IPlace, newVal: string) => {
            setError("");
            place && setPlace({ ...place, [property]: newVal });
        },
        [setPlace, place]
    );

    return (
        <Stack sx={{ maxWidth: "720px", margin: "auto", px: 2 }}>
            {status === "pending" ? (
                <Spinner />
            ) : place?.status?.isEditable && status === "success" ? (
                <Stack>
                    <Typography variant="h2">Lýsing skráningar</Typography>
                    <Typography sx={{ fontSize: "16px", pt: 2 }}>
                        Áður en skilað er inn biðjum við þig um að lýsa því sem skráð var til þess að halda utan um
                        uppfærslusögu. Varist það að setja hér inn viðkvæmar upplýsingar því þetta mun varðveitast í
                        gáttinni.
                    </Typography>

                    <AlmTextField
                        required={true}
                        maxLength={200}
                        variant={"outlined"}
                        surveyProperty="submissionDescription"
                        label="Lýsing"
                        value={place?.submissionDescription || ""}
                        onChange={handleChange}
                        large
                        sx={{ my: 2 }}
                        disabled={isSubmitting}
                    />
                    <AlmButton
                        variant={"contained"}
                        color="primary"
                        onClick={() => {
                            handleSubmit({ ...place, status: { ...place.status, stringID: "InReview" } });
                        }}
                        sx={{
                            width: "100%",
                            "&.Mui-disabled": { backgroundColor: (theme) => theme.palette.primary.main },
                        }}
                        title={"Senda til yfirferðar"}
                        loading={isSubmitting}
                        disabled={isSubmitting}
                    />
                </Stack>
            ) : (
                <Typography variant="h3" color="error">
                    {fetchError || error ? fetchError : "Úbs, það lýtur út fyrir að eitthvað hafi klikkað!"}
                </Typography>
            )}
            {error && <AlmFormError message={error} open={toast} setOpen={setToast} />}
        </Stack>
    );
};
