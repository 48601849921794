import React from "react";

import { Routes, Route, unstable_HistoryRouter as HistoryRouter, Navigate } from "react-router-dom";

import { BetaTheme, CommonPageElements, history } from "./components/Shared/Common";
import Login from "./pages/Login";

import { commonRoutes, routes } from "./routes/routes";

import Service from "./services/Service";

import NewRisk from "./pages/Risks/NewRisk";
import RiskOverview from "./pages/Risks/RiskOverview";
import EditRisk from "./pages/Risks/EditRisk";
import NewSurvey from "./pages/Surveys/NewSurvey";
import Welcome from "./pages/Welcome";
import SurveyOverview from "./pages/Surveys/SurveyOverview";
import EditSurvey from "./pages/Surveys/EditSurvey";
import { AppContext } from "./utils/AppContext";
import AdminPage from "./pages/AdminPage";
import { RiskUser, Roles } from "./models/RiskUser";
import RiskAnalysis from "./pages/Risks/RiskAnalysis";
import { ConfigProvider } from "./utils/ConfigContext";
import { ProtectedLayout } from "./components/ProtectedLayout";
import { AdminLayout, SecuredLayout } from "./components/Admin/AdminLayout";
import { ThemeProvider, Theme, StyledEngineProvider, CssBaseline, Snackbar } from "@mui/material";
import PlaceOverview from "./pages/Places/PlaceOverview";
import { NewPlace } from "./pages/Places/NewPlace";
import EditPlace from "./pages/Places/EditPlace";
import { SubmitPlace } from "./pages/Places/SubmitPlace";
import { SuccessPlace } from "./pages/Places/Success";

declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

interface IAppState {
    user: RiskUser | undefined;
    error: string;
}

class App extends React.Component<Record<string, never>, IAppState> {
    private service: Service;

    constructor(props: Record<string, never>) {
        super(props);

        this.service = new Service(this.handleUnauthorizedRequest);

        this.state = {
            user: this.service.getUserIfLoggedIn(),
            error: "",
        };
    }
    private clearErrorToast = () => this.setState({ error: "" });

    private logIn = (token: string) => {
        const user = this.service.setTokenAndGetUser(token);
        this.setState({ user }, () => {
            history.push(`/`);
        });
    };

    private logOut = () => {
        this.service.logOut();
        this.setState({ user: undefined }, () => {
            history.push(`/${commonRoutes.login.root}`);
        });
    };

    private handleUnauthorizedRequest = () => {
        this.setState({ user: undefined }, () => {
            history.push(
                `/${commonRoutes.login.root}?${commonRoutes.login.params.errorMessage}=Your session has expired, please login again`
            );
        });
    };

    public render() {
        return (
            <HistoryRouter history={history}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={BetaTheme}>
                        <CssBaseline />
                        <AppContext.Provider
                            value={{
                                user: this.state.user,
                            }}
                        >
                            <CommonPageElements>
                                {this.state.error && (
                                    <Snackbar
                                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                        open={Boolean(this.state.error)}
                                        autoHideDuration={6000}
                                        onClose={this.clearErrorToast}
                                        message={this.state.error}
                                    />
                                )}
                                <Routes>
                                    <Route
                                        path="/login"
                                        element={
                                            <>
                                                {this.state.user !== undefined && <Navigate to="/" replace />}
                                                <ConfigProvider>
                                                    <Login
                                                        setToken={this.logIn}
                                                        prepareLogin={this.service.prepareLogin}
                                                    />
                                                </ConfigProvider>
                                            </>
                                        }
                                    />
                                    <Route
                                        path="/"
                                        element={
                                            <ProtectedLayout
                                                user={this.state.user}
                                                logOut={this.logOut}
                                                isUserAdmin={this.state.user?.isAlmAdmin}
                                            />
                                        }
                                    >
                                        <Route index element={<Welcome />} />
                                        <Route
                                            path={`/${routes.admin.root}`}
                                            element={<AdminLayout isAdmin={this.state.user?.isAlmAdmin} />}
                                        >
                                            <Route
                                                index
                                                element={
                                                    <AdminPage
                                                        getOrganizations={this.service.getOrganizations}
                                                        getUsers={this.service.getUsers}
                                                        getPostCodes={this.service.getPostCodes}
                                                        updateUser={this.service.updateUser}
                                                        addUser={this.service.addUser}
                                                        updateOrganization={this.service.updateOrganization}
                                                        addOrganization={this.service.addOrganization}
                                                    />
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path={`/${routes.surveys.root}`}
                                            element={<SecuredLayout roles={[Roles.surveyAdmin, Roles.surveyEdit]} />}
                                        >
                                            <Route
                                                index
                                                element={
                                                    <SurveyOverview
                                                        getSurveys={this.service.getSurveys}
                                                        getXLSXDownloadToken={this.service.getSurveysDownloadToken}
                                                        downloadUrl={this.service.surveysDownloadUrl}
                                                    />
                                                }
                                            />
                                            <Route
                                                path={`new`}
                                                element={
                                                    <NewSurvey
                                                        onSubmit={this.service.addSurvey}
                                                        getOrganizations={this.service.getSurveysOrganizations}
                                                    />
                                                }
                                            />
                                            <Route
                                                path={`:${routes.surveys.edit.params.ID}`}
                                                element={
                                                    <EditSurvey
                                                        updateSurvey={this.service.updateSurvey}
                                                        getSurvey={this.service.getSurvey}
                                                        getOrganizations={this.service.getSurveysOrganizations}
                                                    />
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path={`/${routes.risks.root}`}
                                            element={<SecuredLayout roles={[Roles.riskAdmin, Roles.riskEdit]} />}
                                        >
                                            <Route
                                                index
                                                element={
                                                    <RiskOverview
                                                        getRisks={this.service.getRisks}
                                                        getXLSXDownloadToken={this.service.getRisksDownloadToken}
                                                        downloadUrl={this.service.risksDownloadUrl}
                                                    />
                                                }
                                            />
                                            <Route
                                                path={`analysis`}
                                                element={
                                                    <RiskAnalysis
                                                        getOrganizations={this.service.getRisksOrganizations}
                                                        getRisksAnalysis={this.service.getRisksAnalysis}
                                                    />
                                                }
                                            />
                                            <Route
                                                path={`:${routes.risks.edit.params.ID}`}
                                                element={
                                                    <EditRisk
                                                        getRisk={this.service.getRisk}
                                                        updateRisk={this.service.updateRisk}
                                                        getRiskTypes={this.service.getRiskTypes}
                                                        getPostCodes={this.service.getPostCodes}
                                                        getOrganizations={this.service.getRisksOrganizations}
                                                    />
                                                }
                                            />
                                            <Route
                                                path={`new`}
                                                element={
                                                    <NewRisk
                                                        getRiskTypes={this.service.getRiskTypes}
                                                        onSubmit={this.service.addRisk}
                                                        getPostCodes={this.service.getPostCodes}
                                                        getOrganizations={this.service.getRisksOrganizations}
                                                    />
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path={`/${routes.places.root}`}
                                            element={<SecuredLayout roles={[Roles.placeAdmin, Roles.placeEdit]} />}
                                        >
                                            <Route
                                                index
                                                element={<PlaceOverview getPlaces={this.service.getPlaces} />}
                                            />
                                            <Route
                                                path={`new`}
                                                element={
                                                    <NewPlace
                                                        onSubmit={this.service.addMinimumPlace}
                                                        getOrganizations={this.service.getPlacesOrganizations}
                                                    />
                                                }
                                            />
                                            <Route
                                                path={`:${routes.places.edit.params.ID}`}
                                                element={
                                                    <EditPlace
                                                        getPlace={this.service.getPlace}
                                                        updatePlace={this.service.updatePlace}
                                                        getPlaceTypes={this.service.getPlaceTypes}
                                                        getPlaceSubTypes={this.service.getPlaceSubTypes}
                                                        getHazardousMaterials={this.service.getPlaceHazardousMaterials}
                                                        getPostCodes={this.service.getPlacesPostCodes}
                                                        getOrganizations={this.service.getPlacesOrganizations}
                                                        addAttachment={this.service.postPlaceAttachment}
                                                        editTag={this.service.editAttachmentTag}
                                                        removeAttachment={this.service.removeAttachment}
                                                        getAvailableTags={this.service.getAttachmentTags}
                                                        addNewTag={this.service.addNewTag}
                                                    />
                                                }
                                            />
                                            <Route path={`${routes.places.submit.root}`}>
                                                <Route
                                                    path={`:${routes.places.submit.params.ID}`}
                                                    element={
                                                        <SubmitPlace
                                                            getPlace={this.service.getPlace}
                                                            updatePlace={this.service.updatePlace}
                                                        />
                                                    }
                                                />
                                            </Route>
                                            <Route path={`${routes.places.success.root}`} element={<SuccessPlace />} />
                                        </Route>
                                    </Route>
                                </Routes>
                            </CommonPageElements>
                        </AppContext.Provider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </HistoryRouter>
        );
    }
}

export default App;
