import { IRiskFormProps, RiskForm } from "../../components/Risks/RiskForm";

export type NewRiskProps = Pick<IRiskFormProps, "onSubmit" | "getRiskTypes" | "getPostCodes" | "getOrganizations">

function NewRisk({ onSubmit, getRiskTypes, getPostCodes, getOrganizations }: NewRiskProps) {
    return (
        <RiskForm
            title="Skráning áhættu"
            getRiskTypes={getRiskTypes}
            onSubmit={onSubmit}
            getPostCodes={getPostCodes}
            getOrganizations={getOrganizations}
        />
    );
}

export default NewRisk;
