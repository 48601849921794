import { TableCell, Typography } from "@mui/material";
import { IEffectAssessment, IRisk } from "../../models/RiskTypes";
import SurveySelect, { SurveySelectProps } from "../Survey/SurveySelect";
import { SurveyTextField } from "../Survey/SurveyTextField";

export interface ITableCheckboxQuestionProps {
    header: string;
    onChange: (newValue: IEffectAssessment) => void;
    value: IEffectAssessment;
    disabled: boolean;
    options: SurveySelectProps<number, IRisk>["options"];
}

export function EffectSection({ header, onChange, value, disabled, options }: ITableCheckboxQuestionProps) {
    return (
        <>
            <TableCell>
                <Typography {...(disabled && { color: "textSecondary" })}>{header}</Typography>
            </TableCell>
            <TableCell style={{ verticalAlign: "bottom" }}>
                <SurveySelect
                    variant="outlined"
                    surveyProperty="id"
                    label=""
                    value={value.weight}
                    options={options}
                    onChange={(_, newVal) => {
                        onChange({ ...value, weight: newVal });
                    }}
                />
            </TableCell>
            <TableCell>
                <SurveyTextField
                    multiline
                    disabled={disabled}
                    surveyProperty="id"
                    label=""
                    style={{ minWidth: 200 }}
                    value={value.description || ""}
                    maxLength={128}
                    onChange={(_, newValue) => {
                        onChange({ ...value, description: newValue });
                    }}
                    variant="outlined"
                />
            </TableCell>
        </>
    );
}
