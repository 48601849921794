import { Box, Stack, useTheme } from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ZLogo from "../../assets/icons/zLogo.svg";

export interface IndexImageButtonProps {
    backgroundUrl: string;
    title: string;
    href: string;
}

export default function IndexImageButton({ backgroundUrl, title, href }: IndexImageButtonProps) {
    const theme = useTheme();

    return (
        <ButtonBase
            focusRipple
            key={title}
            href={href}
            sx={{
                height: { xs: 220, sm: 311 },
                width: "100%",
                maxWidth: 360,
                p: 2,
                ":hover": {
                    "& .btnImg": {
                        opacity: 0.85,
                    },
                },
            }}
        >
            <Box
                component="img"
                className="btnImg"
                src={backgroundUrl}
                sx={{ height: "100%", width: "100%", objectFit: "cover", overflow: "hidden" }}
            />
            <Stack direction={"row"} sx={{ position: "absolute", height: 56, width: "80%", right: 0, bottom: 0 }}>
                <Box component="img" src={ZLogo} alt="zLogo" />
                <Stack
                    direction="row"
                    sx={{
                        backgroundColor: theme.palette.info.main,
                        width: 240,

                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="body1" sx={{ fontWeight: 700, pr: 1, whiteSpace: "nowrap" }}>
                        {title}
                    </Typography>
                    <ArrowForwardIcon color={"primary"} />
                </Stack>
            </Stack>
        </ButtonBase>
    );
}
