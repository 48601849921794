import { Theme } from "@mui/material/styles/createTheme";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { getContentWrapperClass } from "../styles/Styles";
import { useCallback, useState } from "react";
import TabPanel from "../components/TabPanel";
import { IOrganization, OrganizationUser, PostCode } from "../models/RiskTypes";
import { useAsync } from "../utils/UseAsync";
import Dialog from "@mui/material/Dialog";
import OrganizationDialog from "../components/Admin/OrganizationDialog";
import UserDialog from "../components/Admin/UserDialog";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { makeStyles } from "@mui/styles";
import { AlmEnhancedTable, compare, LOWER_CASE } from "almannavarnir-shared";
import { Stack } from "@mui/material";
import { CustomIconButton } from "../components/DownloadButton";

enum DialogTypes {
    Closed,
    Organization,
    User,
}

const useStyles = makeStyles((theme: Theme) => ({
    wrapperClass: {
        ...getContentWrapperClass(theme),
    },
    dialog: {
        width: 500,
    },
    buttonContainer: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "end",
        paddingBottom: theme.spacing(1),
    },
    button: {
        color: theme.palette.background.default,
    },
}));

export interface AdminPageProps {
    getOrganizations: () => Promise<IOrganization[]>;
    getUsers: () => Promise<OrganizationUser[]>;
    getPostCodes: () => Promise<PostCode[]>;
    updateUser: (user: OrganizationUser) => Promise<void>;
    addUser: (user: OrganizationUser) => Promise<void>;
    updateOrganization: (org: IOrganization) => Promise<void>;
    addOrganization: (org: IOrganization) => Promise<void>;
}

export default function AdminPage({
    getOrganizations,
    getUsers,
    getPostCodes,
    updateUser,
    addUser,
    addOrganization,
    updateOrganization,
}: AdminPageProps): JSX.Element {
    const classes = useStyles();

    const {
        status: orgFetchStatus,
        value: organizations,
        // error: orgFetchError,
        execute: reloadOrganizations,
    } = useAsync(getOrganizations, true);

    const {
        status: userFetchStatus,
        value: users,
        // error: userFetchError,
        execute: reloadUsers,
    } = useAsync(getUsers, true);

    const [activeTab, setTab] = useState(0);
    const [dialogType, setDialogType] = useState(DialogTypes.Closed);
    const [selectedItem, setselectedItem] = useState<IOrganization | OrganizationUser | undefined>();

    const openDialog = useCallback(
        <T extends DialogTypes>(
            dialogType: T,
            item?: T extends DialogTypes.User ? OrganizationUser : IOrganization
        ) => {
            setselectedItem(item);
            setDialogType(dialogType);
        },
        [setDialogType, setselectedItem]
    );

    const closeDialog = useCallback(
        (wasUpdated = false) => {
            if (wasUpdated) {
                if (dialogType == DialogTypes.Organization) {
                    reloadOrganizations();
                } else {
                    reloadUsers();
                }
            }
            setDialogType(DialogTypes.Closed);
            setselectedItem(undefined);
        },
        [setDialogType, setselectedItem, dialogType, reloadOrganizations, reloadUsers]
    );

    const getDialogContent = useCallback(() => {
        if (dialogType == DialogTypes.Organization) {
            return (
                <OrganizationDialog
                    onClose={closeDialog}
                    getPostCodes={getPostCodes}
                    organization={selectedItem as IOrganization | undefined}
                    onSubmit={selectedItem ? updateOrganization : addOrganization}
                />
            );
        } else if (dialogType == DialogTypes.User) {
            return (
                <UserDialog
                    onClose={closeDialog}
                    open={dialogType === DialogTypes.User}
                    user={selectedItem as OrganizationUser | undefined}
                    organizations={organizations?.sort((a, b) => -compare(a.name, b.name, 0, LOWER_CASE)) ?? []}
                    onSubmit={selectedItem ? updateUser : addUser}
                />
            );
        } else {
            return <></>;
        }
    }, [
        dialogType,
        selectedItem,
        addOrganization,
        addUser,
        closeDialog,
        getPostCodes,
        organizations,
        updateOrganization,
        updateUser,
    ]);
    return (
        <div className={classes.wrapperClass}>
            <Tabs
                value={activeTab}
                onChange={(_, newTab) => setTab(newTab)}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label="Notendur" />
                <Tab label="Stofnanir" />
            </Tabs>
            <TabPanel value={activeTab} index={1}>
                {orgFetchStatus == "success" && organizations && organizations.length > 0 && (
                    <AlmEnhancedTable
                        searchFields={[
                            "name",
                            "address",
                            "type",
                            "postalCode.areaName",
                            "postalCode.place",
                            "postalCode.postalCode",
                        ]}
                        onClick={(_, item) => {
                            openDialog(DialogTypes.Organization, item);
                        }}
                        customBtn={
                            <Stack direction={"row"}>
                                <CustomIconButton
                                    variant="contained"
                                    color="primary"
                                    icon={<AddCircleOutlineRoundedIcon />}
                                    title={"Bæta við stofnun"}
                                    onClick={() => {
                                        openDialog(DialogTypes.Organization);
                                    }}
                                    resize={true}
                                />
                            </Stack>
                        }
                        idCol="id"
                        items={organizations}
                        columns={[
                            { id: "name", label: "Nafn" },
                            { id: "type", label: "Tegund" },
                            { id: "address", label: "Heimilisfang" },
                            { id: "postalCode.areaName", label: "Landssvæði" },
                        ]}
                    />
                )}
            </TabPanel>
            <TabPanel value={activeTab} index={0}>
                {userFetchStatus == "success" && users && users.length > 0 && (
                    <AlmEnhancedTable
                        searchFields={["firstName", "lastName", "email", "mobilePhone"]}
                        onClick={(_, item) => {
                            openDialog(DialogTypes.User, item);
                        }}
                        idCol="id"
                        items={users}
                        customBtn={
                            <Stack direction={"row"}>
                                <CustomIconButton
                                    variant="contained"
                                    color="primary"
                                    icon={<AddCircleOutlineRoundedIcon />}
                                    title={"Bæta við notanda"}
                                    onClick={() => {
                                        openDialog(DialogTypes.User);
                                    }}
                                    resize={true}
                                />
                            </Stack>
                        }
                        columns={[
                            {
                                id: "firstName",
                                label: "Nafn",
                                onRender: (itm) => `${itm.firstName} ${itm.lastName}`,
                            },
                            {
                                id: "id",
                                label: "Stofnanir",
                                onRender: (itm) =>
                                    (itm.organizations || [])
                                        //TODO Hér væri kanski sniðugt að filtera .filter(x=>x!=undefined) fyrir mappið til að passa að það komi ekki tómir strengir. Samt slæmt því ef hann er tómur þá er basically villa
                                        .map((org) => organizations?.find((o) => o.id === org.id)?.name)
                                        .join(", "),
                            },
                            {
                                id: "activeStatus",
                                label: "Staða",
                                onRender: (itm) => (itm.activeStatus ? "Virkur" : "Óvirkur"),
                            },
                        ]}
                    />
                )}
            </TabPanel>
            <Dialog open={dialogType != DialogTypes.Closed} onClose={() => closeDialog()} fullWidth maxWidth={"md"}>
                {getDialogContent()}
            </Dialog>
        </div>
    );
}
