import { IRisk } from "../../models/RiskTypes";
import RiskBowtieDiagram from "../Bowtie/RiskBowtieDiagram";

export interface RisksBowtieProps {
    allRisks: IRisk[];
    isPrinting: boolean;
}

const RisksBowtie = ({ allRisks, isPrinting }: RisksBowtieProps) => {
    return (
        <>
            {allRisks.map((risk, i) => (
                <RiskBowtieDiagram isPrinting={isPrinting} key={i} risk={risk} />
            ))}
        </>
    );
};

export default RisksBowtie;
