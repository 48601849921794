import { Divider, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { IRisk } from "../../models/RiskTypes";
import CauseOrEffect from "./CauseOrEffect";
import { causeEffect } from "./CauseEffectCard";
import RiskCenter from "./RiskCenter";

const useStyles = makeStyles(() => ({
    circle: {
        "@media print": { position: "absolute", left: "35%", top: "15%", width: "60%" },
    },
    effect: {
        "@media print": { position: "absolute", right: "0px", top: "0px" },
    },
    paper: {
        padding: "10px",
        marginTop: "22px",
        marginBottom: "15px",
        "@media print": { pageBreakAfter: "always", height: "auto" },
    },
    gridRoot: {
        "@media print": { position: "relative" },
        display: "flex",
        flexDirection: "row",
    },
}));
export interface RiskBowtieDiagramProps {
    risk: IRisk;
    isPrinting: boolean;
}

const RiskBowtieDiagram = ({ risk, isPrinting }: RiskBowtieDiagramProps) => {
    const classes = useStyles();
    return (
        <>
            {risk && (
                <Paper elevation={2} className={classes.paper}>
                    <h3 style={{ marginTop: "0px", marginBottom: "10px" }}>{risk.name}</h3>
                    <Divider style={{ marginBottom: "10px" }} />
                    <Typography style={{ wordBreak: "break-all" }}>{risk.description}</Typography>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "33%" }}>
                            {risk.causes !== null &&
                                risk.causes.map((c, i) => (
                                    <CauseOrEffect isPrinting={isPrinting} key={i} cause={c} type={causeEffect.cause} />
                                ))}
                        </div>
                        <div style={{ width: "34%", alignSelf: "center" }}>
                            <RiskCenter isPrinting={isPrinting} text={risk.name} />
                        </div>
                        <div style={{ width: "33%" }}>
                            {risk.customEffects !== null &&
                                risk.customEffects.map((c, i) => (
                                    <CauseOrEffect
                                        isPrinting={isPrinting}
                                        key={i}
                                        cause={c}
                                        type={causeEffect.effect}
                                    />
                                ))}
                        </div>
                    </div>
                </Paper>
            )}
        </>
    );
};

export default RiskBowtieDiagram;
