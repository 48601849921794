import { useState, useEffect, useRef } from "react";

export default function useComponentFocused(
    initialIsVisible: boolean
): [React.MutableRefObject<HTMLDivElement | null>, boolean, React.Dispatch<React.SetStateAction<boolean>>] {
    const [isComponentFocused, setIsComponentFocused] = useState(initialIsVisible);
    const ref = useRef<HTMLDivElement | null>(null);

    const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentFocused(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    return [ref, isComponentFocused, setIsComponentFocused];
}
