import { PropsWithChildren } from "react";
import { Navigate, useOutlet } from "react-router-dom";
import { User } from "../models/User";
import { TabsHeader } from "./Shared/Header";
import { AlmHeader, IHeaderProps } from "almannavarnir-shared";
import { history } from "./Shared/Common";
import { routes } from "../routes/routes";
import { Link } from "react-router-dom";
import logo from "../assets/almannavarnir_logo.svg";

export type ProtectedLayoutProps<T extends User> = Pick<IHeaderProps<T>, "isUserAdmin" | "user" | "logOut">;

export function ProtectedLayout<T extends User>({
    user,
    logOut,
    isUserAdmin,
}: PropsWithChildren<ProtectedLayoutProps<T>>) {
    const outlet = useOutlet();
    if (!user) {
        return <Navigate to="/login" />;
    }

    return (
        <>
            <div style={{ maxWidth: "1250px", margin: "auto" }}>
                {/* <HeaderComponent user={user} logOut={logOut} isUserAdmin={isUserAdmin} /> */}
                <AlmHeader
                    user={user}
                    logOut={logOut}
                    isUserAdmin={isUserAdmin}
                    goToSettings={() => history.push("/" + routes.admin.root)}
                    headerImg={
                        <Link to="/">
                            <img height={"50px"} src={logo} alt="Vefgátt Almannavarna" />
                        </Link>
                    }
                    Tabs={TabsHeader}
                />
                {outlet}
            </div>
        </>
    );
}
