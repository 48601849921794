import { Theme, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { hideInPrint, showOnlyInPrint } from "../../styles/Styles";
import { useCallback, useEffect, useRef } from "react";
import { ISurvey } from "../../models/RiskTypes";

const useStyles = makeStyles((theme: Theme) => ({
    table: {
        ...hideInPrint,
    },
    hiddenInPrint: {
        ...hideInPrint,
    },
    visibleOnlyInPrint: {
        ...showOnlyInPrint,
    },
    tableContainer: {
        [theme.breakpoints.down("md")]: {
            overflow: "scroll",
        },
    },
    icon: {
        position: "absolute",
        top: "10%",
        left: "90%",
        transform: " translate(-50%,-50%)",
        width: "40px",
        height: "30px",
        cursor: "pointer",
    },
    arrow: {
        "@media screen and (max-width: 810px)": {
            position: "absolute",
            top: "25px",
            width: "90%",
            height: "7px",
            backgroundColor: "#e09e3d",
            boxShadow: "0 3px 5px rgba(0, 0, 0, .2)",
            animation: "arrow 700ms linear infinite",
            "&::before": {
                content: '""',
                position: "absolute",
                width: "60%",
                height: "7px",
                right: "-4px",
                backgroundColor: "#e09e3d",
                top: "6px",
                boxShadow: "0 3px 5px rgba(0, 0, 0, .2)",
                transform: "rotate(-45deg)",
            },
            "&::after": {
                content: '""',
                position: "absolute",
                width: "60%",
                height: "7px",
                right: "-4px",
                backgroundColor: "#e09e3d",
                top: "-6px",
                transform: "rotate(45deg)",
            },
        },
    },
}));

export interface SurveyMultiPartMultiOptionTableProps {
    options: { label: string; value: number }[];
    sections: {
        label: string;
        helperText?: string;
        surveyProperty: Extract<keyof ISurvey, string>;
        value: number[];
    }[];
    onChange: (property: keyof ISurvey, newVal: number[]) => void;
    disabled?: boolean;
}

export default function SurveyMultiPartMultiOptionTable({
    options,
    sections,
    onChange,
    disabled,
}: SurveyMultiPartMultiOptionTableProps) {
    const classes = useStyles();

    const handleChange = useCallback(
        (property: keyof ISurvey, currVal: number[], changedValue: number, shouldAdd: boolean) => {
            let newVal: number[];
            if (shouldAdd) {
                newVal = [...currVal, changedValue];
            } else {
                newVal = currVal.filter((val) => val != changedValue);
            }
            onChange(property, newVal);
        },
        [onChange]
    );
    const arrowRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const anim =
            arrowRef.current &&
            arrowRef.current.animate([{ left: "0" }, { left: "10px" }, { left: "0" }], {
                duration: 700,
                iterations: Infinity,
            });
        return () => {
            anim?.cancel();
        };
    }, []);

    return (
        <div style={{ position: "relative" }}>
            <Typography variant="body1" {...(disabled && { color: "textSecondary" })}>
                Hverjum eftirtalinna aðila hefur sveitarfélagið unnið með við gerð eftirfarandi efnis?
            </Typography>
            <div className={classes.icon}>
                <div ref={arrowRef} className={classes.arrow}></div>
            </div>
            <Typography variant="body2" {...(disabled && { color: "textSecondary" })} className={classes.hiddenInPrint}>
                Merkja má við eitt svar eða fleiri
            </Typography>
            <div className={classes.tableContainer}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell key="empty"></TableCell>
                            {options.map((opt) => (
                                <TableCell key={opt.value}>
                                    <Typography {...(disabled && { color: "textSecondary" })}>{opt.label}</Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sections.map((section) => (
                            <TableRow key={section.surveyProperty}>
                                <TableCell key="label">
                                    <Typography {...(disabled && { color: "textSecondary" })}>
                                        {section.label}
                                    </Typography>
                                </TableCell>
                                {options.map((opt) => (
                                    <TableCell key={opt.value}>
                                        <div style={{ margin: "auto", width: "fit-content" }}>
                                            <Checkbox
                                                disabled={disabled}
                                                color="primary"
                                                checked={section.value.indexOf(opt.value) !== -1}
                                                onChange={(_ev, isChecked) => {
                                                    handleChange(
                                                        section.surveyProperty,
                                                        section.value,
                                                        opt.value,
                                                        isChecked
                                                    );
                                                }}
                                            />
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            <div className={classes.visibleOnlyInPrint}>
                {sections.map((sect) => {
                    return (
                        <Typography
                            key={sect.label}
                            variant="body1"
                            display="block"
                            {...(disabled && { color: "textSecondary" })}
                        >
                            {sect.label}:{" "}
                            <span>
                                {((Array.isArray(sect.value) && sect.value) || [])
                                    .map((val) => (options.filter((opt) => opt.value == val)[0] || { label: "" }).label)
                                    .join(", ")}
                            </span>
                        </Typography>
                    );
                })}
            </div>
        </div>
    );
}
