import { createContext, FC, PropsWithChildren, useEffect, useRef, useState } from "react";

declare global {
    interface Window {
        globalConfig: FrontEndConfiguration | undefined;
    }
}
export interface FrontEndConfiguration {
    stafraentIslandLoginId: string | undefined;
}

const defaultConfig: FrontEndConfiguration = {
    stafraentIslandLoginId: undefined,
};

const ConfigContext = createContext<FrontEndConfiguration>(defaultConfig);
export default ConfigContext;

export const ConfigProvider: FC = ({ children }: PropsWithChildren<Record<string, unknown>>) => {
    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
    const [config, setConfig] = useState<FrontEndConfiguration>(window.globalConfig || defaultConfig);

    useEffect(() => {
        const clearint = () => {
            intervalRef.current && clearInterval(intervalRef.current);
        };

        if (!config.stafraentIslandLoginId && !intervalRef.current) {
            intervalRef.current = setInterval(() => {
                if (window.globalConfig?.stafraentIslandLoginId) {
                    setConfig(window.globalConfig);
                    clearint();
                }
            }, 100);
        }

        return () => {
            clearint();
        };
    }, [config.stafraentIslandLoginId]);

    return <ConfigContext.Provider value={{ ...config }}>{children}</ConfigContext.Provider>;
};
