import { styled } from "@mui/styles";
import { Collapse, Typography } from "@mui/material";
import { useState } from "react";
import ExpandIcon from "@mui/icons-material/KeyboardArrowDown";
import CollapseIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";

const Header = styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    //cursor: "pointer",
    paddingBottom: 15,
}));

export interface FormIntroProps {
    isExpandedByDefault: boolean;
    title: string;
}

export default function FormIntro({ isExpandedByDefault, title, children }: React.PropsWithChildren<FormIntroProps>) {
    const [isExpanded, setExpanded] = useState(isExpandedByDefault);

    return (
        <div>
            <Header
                onClick={() => {
                    setExpanded(!isExpanded);
                }}
            >
                <Typography variant="h2">{title}</Typography>
                <IconButton size="medium" color="primary">
                    {isExpanded ? (
                        <CollapseIcon style={{ fontSize: "2.3rem" }} />
                    ) : (
                        <ExpandIcon style={{ fontSize: "2.3rem" }} />
                    )}
                </IconButton>
            </Header>
            <Collapse
                in={isExpanded}
                sx={{
                    a: { textDecoration: "none", color: (theme) => theme.palette.secondary.main },
                    "a:visited": { color: (theme) => theme.palette.secondary.main },
                }}
            >
                {children}
            </Collapse>
        </div>
    );
}
