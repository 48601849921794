import { User } from "./User";
import { IOrganization } from "./RiskTypes";

export interface RiskUser extends User {
    organizations: UserOrganization[];
    isAlmAdmin: boolean;
}

export interface UserOrganization extends Required<Pick<IOrganization, "id">> {
    roles: Roles[];
}
export interface ServiceUserOrganization extends Required<Pick<IOrganization, "id">> {
    roles: Roles[];
}
export interface FrontEndRoles {
    id: number;
    name: Roles;
}
//TODO  Viljum við ennþá halda þessum inni, erum við þá að nota enum'in bara til setta (og í bakenda)? Á kanski eftir að koma að því seinna í yfirferðinni en skil þetta comment eftir til að gleyma ekki
export enum Roles {
    almAdmin = "A_A",
    almEdit = "A_E",
    placeAdmin = "P_A",
    surveyAdmin = "S_A",
    riskAdmin = "R_A",
    placeEdit = "P_E",
    surveyEdit = "S_E",
    riskEdit = "R_E",
}
export enum NewRoles {
    almAdmin = 1,
    almEdit = 2,
    placeAdmin = 3,
    placeEdit = 4,
    riskAdmin = 5,
    riskEdit = 6,
    surveyAdmin = 7,
    surveyEdit = 8,
}

export const matchesRoles: (
    orgs: RiskUser["organizations"] | undefined,
    rolesToMatch: Roles[],
    orgID?: number
) => boolean = (orgs, rolesToMatch, orgID) => {
    return orgs
        ? rolesToMatch.some((role) =>
              role === Roles.almAdmin
                  ? orgs.some((org) => org.roles.some((r) => r == role))
                  : orgs.some((org) => org.roles.some((r) => r == role) && (orgID ? org.id == orgID : true))
          )
        : false;
};
