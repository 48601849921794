import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { history, Spinner } from "../../components/Shared/Common";
import FullPageError from "../../components/FullPageError";
import { EditPageParams, routes } from "../../routes/routes";
import mergeWith from "lodash.mergewith";
import cloneDeep from "lodash.clonedeep";
import { RedirectType } from "../../utils/Utilities";
import { Button, Grid } from "@mui/material";
import {
    AlmPlaceForm,
    IPlaceFormProps,
    IPlace,
    usePlaceValidation,
    newPlace,
    Url,
    IOrganization,
    ExtendedFile,
    NewAttachment,
    DisplayFile,
    AttachmentTag,
    useAsync,
    AlmFormError,
} from "almannavarnir-shared";
import { SubmittablePlace } from "../../services/types";
import { matchesRoles, Roles } from "../../models/RiskUser";
import { AppContext } from "../../utils/AppContext";

export interface EditPlaceProps
    extends Pick<IPlaceFormProps, "getPlaceTypes" | "getPostCodes" | "getPlaceSubTypes" | "getHazardousMaterials"> {
    getPlace: (ID: number) => Promise<IPlace>;
    updatePlace: (place: SubmittablePlace) => Promise<any>;
    getOrganizations: () => Promise<IOrganization[]>;
    addAttachment: (file: ExtendedFile) => Promise<NewAttachment>;
    editTag: (file: DisplayFile | Url, tagID: number, remove: boolean) => Promise<number | null>;
    removeAttachment: (blobPath: string) => Promise<void>;
    getAvailableTags: () => Promise<AttachmentTag[]>;
    addNewTag: (tag: string) => Promise<number>;
}

export default function EditPlace({
    getPlace,
    updatePlace,
    getPlaceTypes,
    getPlaceSubTypes,
    getHazardousMaterials,
    getPostCodes,
    getOrganizations,
    addAttachment,
    editTag,
    removeAttachment,
    getAvailableTags,
    addNewTag,
}: EditPlaceProps): JSX.Element {
    const { ID: strID } = useParams<EditPageParams>();
    const ID = useMemo(() => parseInt(strID ?? "0"), [strID]);
    const { user } = useContext(AppContext);
    const wrappedGetPlace = useCallback(() => (ID ? getPlace(ID) : Promise.resolve(null)), [ID, getPlace]);
    const {
        status,
        value: initialPlace,
        error: placeError,
        execute: refreshPlace,
    } = useAsync(wrappedGetPlace, undefined, false);
    const [place, setPlace] = useState<IPlace>(
        mergeWith(cloneDeep({ ...newPlace, status: { isEditable: true } }), initialPlace, (val, srcVal) =>
            srcVal === null ? val : undefined
        )
    );
    useEffect(() => {
        setPlace(
            mergeWith(cloneDeep({ ...newPlace, status: { isEditable: true } }), initialPlace, (val, srcVal) =>
                srcVal === null ? val : undefined
            )
        );
    }, [initialPlace]);

    const { hasError, errors, isValid } = usePlaceValidation(place);

    const [error, setError] = useState<string | undefined>(undefined);

    const handleSubmit = (newPlace: SubmittablePlace, redirectType: RedirectType = RedirectType.None) => {
        setError(undefined);
        return updatePlace(newPlace)
            .then((res) => {
                if (redirectType == RedirectType.Edit) {
                    history.push(`/${routes.places.edit.root}/${res.id}`);
                } else if (redirectType == RedirectType.Home) {
                    history.push(`/${routes.places.root}`);
                } else if (redirectType == RedirectType.Success) {
                    history.push(`/${routes.places.root}/${routes.places.submit.root}/${place.id}`);
                } else {
                    refreshPlace();
                }
            })
            .catch((err: string | Error) => {
                setError((typeof err == "string" ? err : err.message) || "Eitthvað fór úrskeiðis við að vista");
            });
    };
    const isDisabled = useMemo(() => !place?.status?.isEditable, [place?.status?.isEditable]);
    const [toast, setToast] = useState(false);
    console.log(errors);
    return (
        <div>
            <div>
                {status == "pending" && !place && <Spinner />}
                {/* TODO: Style error */}
                {status == "error" && <FullPageError message={placeError || "Something went wrong"} />}
                {place && place.id !== 0 && (
                    <>
                        <AlmPlaceForm
                            getPlaceTypes={getPlaceTypes}
                            getPlaceSubTypes={getPlaceSubTypes}
                            getHazardousMaterials={getHazardousMaterials}
                            place={place}
                            setPlace={setPlace}
                            errors={errors}
                            getPostCodes={getPostCodes}
                            getOrganizations={getOrganizations}
                            addAttachment={addAttachment}
                            editTag={editTag}
                            removeAttachment={removeAttachment}
                            getAvailableTags={getAvailableTags}
                            addNewTag={addNewTag}
                            error={{ error: error, setError: setError }}
                            handleSubmit={handleSubmit}
                            bottomButtons={
                                <Grid item container spacing={2} xs={12} sx={{ mt: 1 }}>
                                    <Grid item xs={6} sx={{ display: "flex" }}>
                                        <Button
                                            disableElevation
                                            disabled={isDisabled}
                                            size="large"
                                            variant={"outlined"}
                                            color="secondary"
                                            onClick={() => {
                                                handleSubmit(place, RedirectType.Home);
                                            }}
                                            sx={{
                                                color: (theme) => theme.palette.secondary.main,
                                                width: "100%",
                                            }}
                                        >
                                            Vista og klára síðar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} sx={{ display: "flex" }}>
                                        <Button
                                            disableElevation
                                            size="large"
                                            disabled={
                                                isDisabled ||
                                                !matchesRoles(
                                                    user?.organizations,
                                                    [Roles.placeAdmin, Roles.almAdmin],
                                                    place.organization.id
                                                )
                                            }
                                            variant={"contained"}
                                            color="primary"
                                            onClick={() => {
                                                const valid = isValid(place);
                                                valid && handleSubmit(place, RedirectType.Success);
                                                setToast(true);
                                            }}
                                            sx={{
                                                width: "100%",
                                            }}
                                        >
                                            Staðfesta
                                        </Button>
                                        {hasError && (
                                            <AlmFormError
                                                message={
                                                    "Vinsamlegast athugið að til að staðfesta þurfa allir reitir að vera útfylltir á viðeigandi máta. Hægt er þó að vista og klára síðar."
                                                }
                                                open={toast}
                                                setOpen={setToast}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            }
                        />
                    </>
                )}
            </div>
        </div>
    );
}
