import jwt from "jsonwebtoken";
import {
    IOrganization,
    IRisk,
    ISurvey,
    OrganizationUser,
    PostCode,
    RiskType,
    BackendSurvey,
    BackendRisk,
} from "../models/RiskTypes";
import { cloneDeep } from "lodash";
import { validationErrorBody } from "../models/ServiceTypes";
import { matchesRoles, RiskUser, Roles, ServiceUserOrganization, UserOrganization } from "../models/RiskUser";
import { JWTPayload } from "../models/JWT";
import { AuthRole } from "../models/Entities";
import { addNewTag, editAttachmentTag, getAttachmentTags, removeAttachment, uploadAttachment } from "./Attachments";
import { IMinimumPlace, ImportantPlaceAllSubmission, ImportantPlaceType } from "../models/PlaceTypes";
import { ImportantPlaceHazardousMaterial, IPlace } from "almannavarnir-shared";
import { SubmittablePlace } from "./types";

const tokenStorageKey = "token";

export interface OnSubmitInterface {
    id: IPlace["id"];
    contactIds: number[];
}

export default class Service {
    protected isLoggedIn = false;
    private token: string | undefined = undefined;
    private onUnauthorizedError: () => void;

    public risksDownloadUrl = "/api/downloads/risks";
    public surveysDownloadUrl = "/api/downloads/surveys";

    constructor(onUnauth: () => void) {
        this.onUnauthorizedError = onUnauth;
    }

    public postPlaceAttachment = uploadAttachment.bind(this);
    public editAttachmentTag = editAttachmentTag.bind(this);
    public removeAttachment = removeAttachment.bind(this);
    public getAttachmentTags = getAttachmentTags.bind(this);
    public addNewTag = addNewTag.bind(this);

    public getUserIfLoggedIn: () => RiskUser | undefined = () => {
        const token: string | null | undefined = this.token || localStorage.getItem(tokenStorageKey);
        if (!token) {
            return undefined;
        } else {
            if (tokenIsExpired(token)) {
                // TODO: refresh token?
                return undefined;
            } else if (this.token != token) {
                this.token = token;
            }
        }
        return this.parseToken(this.token);
    };

    public setTokenAndGetUser: (token: string) => RiskUser = (token) => {
        this.token = token;
        localStorage.setItem(tokenStorageKey, token);
        return this.parseToken(token);
    };

    private parseToken: (token: string) => RiskUser = (token) => {
        try {
            const payload: JWTPayload = jwt.decode(token) as JWTPayload;
            const organizations: ServiceUserOrganization[] =
                typeof payload.org == "string" ? [JSON.parse(payload.org)] : payload.org.map((o) => JSON.parse(o));
            const parsedOrgs: UserOrganization[] = organizations.map((k) => ({
                ...k,
            }));
            const user: RiskUser = {
                name: payload.name,
                kt: payload.sub,
                organizations: parsedOrgs,
                isAlmAdmin: matchesRoles(parsedOrgs, [Roles.almAdmin]),
            };
            return user;
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    public prepareLogin = () => {
        return this.get<{ id: string }>("/prepareLogin");
    };

    public logOut: () => void = () => {
        localStorage.removeItem(tokenStorageKey);
        this.token = undefined;
    };

    public getRisks = async (orgID?: number): Promise<IRisk[]> => {
        return this.get<IRisk[]>(`/risks${orgID ? `?orgID=${orgID}` : ""}`);
    };

    public getPlaceTypes = async (): Promise<ImportantPlaceType[]> => {
        return this.get<ImportantPlaceType[]>(`/places/types`);
    };
    public getPlaceSubTypes = async (): Promise<ImportantPlaceType[]> => {
        return this.get<ImportantPlaceType[]>(`/places/subtypes`);
    };
    public getPlaceHazardousMaterials = async (): Promise<ImportantPlaceHazardousMaterial[]> => {
        return this.get<ImportantPlaceType[]>(`/places/hazardousMaterials`);
    };

    public getRisksDownloadToken: () => Promise<string> = async () => {
        return this.get<{ token: string }>(`/risks/getXlsxDownloadToken`).then((res) => res.token);
    };

    public getPlacesDownloadToken: () => Promise<string> = async () => {
        return this.get<{ token: string }>(`/places/getXlsxDownloadToken`).then((res) => res.token);
    };

    // TODO, switch for specific token
    public getSurveysDownloadToken: () => Promise<string> = async () => {
        return this.get<{ token: string }>(`/risks/getXlsxDownloadToken`).then((res) => res.token);
    };

    public getRisk: (ID: number) => Promise<IRisk> = (ID) => {
        return this.get<BackendRisk>(`/risks/${ID}`).then(this.parseBackendRiskForFrontend);
    };

    public getPlace: (ID: number) => Promise<IPlace> = (ID) => {
        return this.get<IPlace>(`/places/${ID}`);
    };

    public getPlaces: () => Promise<ImportantPlaceAllSubmission[]> = () => {
        return this.get<ImportantPlaceAllSubmission[]>(`/places`);
    };
    public getPlacesPostCodes: () => Promise<PostCode[]> = () => {
        return this.get<PostCode[]>(`/places/postcodes`);
    };

    public getRisksAnalysis = async (orgID?: number): Promise<IRisk[]> => {
        const allRisks = await this.getRisks(orgID);

        const detailedAllRisks: IRisk[] = [];
        const promises: Promise<any>[] = [];

        allRisks.forEach(async (risk) => {
            if (risk.id !== undefined) {
                promises.push(this.getRisk(risk.id).then((val) => detailedAllRisks.push(val)));
            }
        });
        await Promise.all(promises);

        return detailedAllRisks;
    };

    public getRiskTypes: () => Promise<RiskType[]> = () => {
        return this.get<RiskType[]>(`/risks/types`);
    };

    public getPostCodes: () => Promise<PostCode[]> = () => {
        return this.get<PostCode[]>(`/risks/postcodes`);
    };

    public getSurveysOrganizations: () => Promise<IOrganization[]> = () => {
        return this.get<IOrganization[]>(`/surveys/organizations`);
    };
    public getRisksOrganizations: () => Promise<IOrganization[]> = () => {
        return this.get<IOrganization[]>(`/risks/organizations`);
    };
    public getPlacesOrganizations: () => Promise<IOrganization[]> = () => {
        return this.get<IOrganization[]>(`/places/organizations`);
    };

    public getOrganizations: () => Promise<IOrganization[]> = () => {
        return this.get<IOrganization[]>(`/admin/organizations`);
    };

    public getUsers: () => Promise<OrganizationUser[]> = () => {
        return this.get<OrganizationUser[]>(`/admin/users`);
    };

    public getSurveys: () => Promise<ISurvey[]> = async () => {
        return this.get<ISurvey[]>(`/surveys`);
    };

    public getSurvey: (ID: number) => Promise<ISurvey> = (ID) => {
        return this.get<BackendSurvey>(`/surveys/${ID}`).then(this.parseBackendSurveyForFrontend);
    };

    public addRisk: (risk: IRisk) => Promise<Required<Pick<IRisk, "id">>> = (risk) => {
        const backendRisk: BackendRisk = this.parseRiskForBackend(risk);
        return this.post<Required<Pick<IRisk, "id">>, BackendRisk>(`/risks/add`, backendRisk);
    };

    // ToDo: validate that this is in fact right way to do it compared to the addRisk (see backendRisk data conversion)
    public addPlace: (place: IPlace) => Promise<OnSubmitInterface> = (place) => {
        return this.post<OnSubmitInterface, IPlace>(`/places/add`, place);
    };

    public addMinimumPlace: (minimumPlace: IMinimumPlace) => Promise<Required<Pick<IPlace, "id">>> = (minimumPlace) => {
        return this.post<Required<Pick<IPlace, "id">>, IMinimumPlace>(`/places/addPlaceAndSubmission`, minimumPlace);
    };

    public updateRisk: (risk: IRisk) => Promise<any> = (risk) => {
        const backendRisk: BackendRisk = this.parseRiskForBackend(risk);
        return this.post<any, BackendRisk>(`/risks/${risk.id}`, backendRisk);
    };

    public updatePlace: (place: SubmittablePlace) => Promise<{ success: true }> = (place) => {
        return this.post<{ success: true }, SubmittablePlace>(`/places/${place.id}`, place);
    };

    public addSurvey: (survey: ISurvey) => Promise<any> = (survey) => {
        const serviceSurvey = this.parseSurveyForBackend(survey);
        return this.post<any, BackendSurvey>(`/surveys/add`, serviceSurvey);
    };

    public updateSurvey: (survey: ISurvey) => Promise<any> = (survey) => {
        const serviceSurvey = this.parseSurveyForBackend(survey);
        return this.post<any, BackendSurvey>(`/surveys/${survey.id}`, serviceSurvey);
    };

    public addUser: (user: OrganizationUser) => Promise<any> = (user) => {
        return this.post(`/admin/users/add`, user);
    };

    public updateUser: (user: OrganizationUser) => Promise<any> = (user) => {
        return this.post(`/admin/users/${user.id}`, user);
    };

    public addOrganization: (org: IOrganization) => Promise<any> = (org) => {
        return this.post(`/admin/organizations/add`, org);
    };

    public updateOrganization: (org: IOrganization) => Promise<any> = (org) => {
        if (!org.id) throw new Error("Organization not recognized");
        return this.post(`/admin/organizations/${org.id}`, org);
    };

    public getAuthRoles: () => Promise<AuthRole[]> = () => {
        return this.get<AuthRole[]>(`/admin/authRoles`);
    };

    private parseRiskForBackend = (risk: IRisk): BackendRisk => {
        const { affectedCommunityProjects, ...natElements } = risk;

        const parsedElements = {
            affectedCommunityProjects: JSON.stringify(affectedCommunityProjects),
        };

        return cloneDeep({ ...natElements, ...parsedElements });
    };

    private parseBackendRiskForFrontend = (survey: BackendRisk): IRisk => {
        const { affectedCommunityProjects, ...natElements } = survey;

        const parsedElements = {
            affectedCommunityProjects: JSON.parse(affectedCommunityProjects || "[]"),
        };

        return cloneDeep({ ...natElements, ...parsedElements });
    };

    private parseSurveyForBackend: (survey: ISurvey) => BackendSurvey = (survey) => {
        const {
            q7_WorkRegardingSafety,
            q16_P1_Collaboration,
            q16_P2_Collaboration,
            q16_P3_Collaboration,
            q16_P4_Collaboration,
            q16_P5_Collaboration,
            themeOverview_Q1_CommunicationTools,
            themeOverview_Q2_MapSolutions,
            themeOwnPreparation_Q2_WhatOperation,
            ...natElements
        } = survey;

        const parsedElements = {
            q7_WorkRegardingSafety: JSON.stringify(q7_WorkRegardingSafety),
            q16_P1_Collaboration: JSON.stringify(q16_P1_Collaboration),
            q16_P2_Collaboration: JSON.stringify(q16_P2_Collaboration),
            q16_P3_Collaboration: JSON.stringify(q16_P3_Collaboration),
            q16_P4_Collaboration: JSON.stringify(q16_P4_Collaboration),
            q16_P5_Collaboration: JSON.stringify(q16_P5_Collaboration),
            themeOverview_Q1_CommunicationTools: JSON.stringify(themeOverview_Q1_CommunicationTools),
            themeOverview_Q2_MapSolutions: JSON.stringify(themeOverview_Q2_MapSolutions),
            themeOwnPreparation_Q2_WhatOperation: JSON.stringify(themeOwnPreparation_Q2_WhatOperation),
        };

        return cloneDeep({ ...natElements, ...parsedElements });
    };

    private parseBackendSurveyForFrontend: (survey: BackendSurvey) => ISurvey = (survey) => {
        const {
            q7_WorkRegardingSafety,
            q16_P1_Collaboration,
            q16_P2_Collaboration,
            q16_P3_Collaboration,
            q16_P4_Collaboration,
            q16_P5_Collaboration,
            themeOverview_Q1_CommunicationTools,
            themeOverview_Q2_MapSolutions,
            themeOwnPreparation_Q2_WhatOperation,
            ...natElements
        } = survey;

        const parsedElements = {
            q7_WorkRegardingSafety: JSON.parse(q7_WorkRegardingSafety),
            q16_P1_Collaboration: JSON.parse(q16_P1_Collaboration),
            q16_P2_Collaboration: JSON.parse(q16_P2_Collaboration),
            q16_P3_Collaboration: JSON.parse(q16_P3_Collaboration),
            q16_P4_Collaboration: JSON.parse(q16_P4_Collaboration),
            q16_P5_Collaboration: JSON.parse(q16_P5_Collaboration),
            themeOverview_Q1_CommunicationTools: JSON.parse(themeOverview_Q1_CommunicationTools),
            themeOverview_Q2_MapSolutions: JSON.parse(themeOverview_Q2_MapSolutions),
            themeOwnPreparation_Q2_WhatOperation: JSON.parse(themeOwnPreparation_Q2_WhatOperation),
        };

        return cloneDeep({ ...natElements, ...parsedElements });
    };

    protected get<T>(url: string): Promise<T> {
        return fetch(`/api${url}`, {
            method: "GET",
            headers: {
                ContentType: "application/json",
                Authorization: `Bearer ${this.token}`,
            },
        }).then(this.parseResponse);
    }

    protected post<T, U>(url: string, body: U): Promise<T> {
        console.log("posting ", url);
        console.log("posting ", body);
        return fetch(`/api${url}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this.token}`,
            },
            body: JSON.stringify(body),
        }).then(this.parseResponse);
    }

    private parseResponse = async (res: Response) => {
        if (res.status == 401) {
            this.onUnauthorizedError();
        } else if (res.status == 403) {
            throw new Error("Þú hefur ekki næg réttindi til að framkvæma þessa aðgerð");
        } else if (res.status == 204) {
            return "204 No Content";
        } else if (res.status == 409) {
            return res.json().then((body) => {
                throw new Error(body?.message || "");
            });
        } else if (res.status != 200) {
            return res
                .json()
                .then((body) => {
                    console.log("Caught error in request: ", body);
                    if (res.status == 400) {
                        const errors = (body as validationErrorBody).errors;
                        if (!errors) {
                            throw new Error(body?.message || res.statusText || "Eitthvað fór úrskeiðis");
                        }
                        throw new Error(
                            Object.keys(errors)
                                .flatMap((key) => errors[key])
                                .join(", ")
                        );
                    }
                    throw new Error(body?.message || res.statusText || "Eitthvað fór úrskeiðis");
                })
                .catch((err) => {
                    throw new Error(err.message || "Eitthvað fór úrskeiðis");
                });
        } else {
            return res.json();
        }
    };
}

function tokenIsExpired(token: string) {
    const payload = jwt.decode(token) as JWTPayload;
    return !payload?.exp || Date.now() > payload.exp * 1000;
}
