import { TypographyVariant, Typography } from "@mui/material";
import { createStyles, WithStyles, withStyles } from "@mui//styles/";

const styles = () =>
    createStyles({
        title: {
            fontWeight: 600,
        },
        subTitle: {
            fontStyle: "italic",
        },
    });

export interface SurveySectionHeaderProps extends WithStyles<typeof styles> {
    title: string;
    subTitle?: string;
    disabled?: boolean;
    variant?: TypographyVariant;
    boldTitle?: boolean;
}

function SurveySectionHeader({ classes, title, subTitle, disabled, variant, boldTitle }: SurveySectionHeaderProps) {
    return (
        <>
            <Typography
                variant={variant}
                className={boldTitle ? classes.title : ""}
                {...(disabled && { color: "textSecondary" })}
            >
                {title}
            </Typography>
            {subTitle && (
                <Typography className={classes.subTitle} {...(disabled && { color: "textSecondary" })}>
                    {subTitle}
                </Typography>
            )}
        </>
    );
}

export default withStyles(styles)(SurveySectionHeader);
