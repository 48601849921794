import { Typography } from "@mui/material";

export interface RiskCenterProps {
    text: string;
    isPrinting: boolean;
}

const RiskCenter = ({ text, isPrinting }: RiskCenterProps) => {
    return (
        <div
            style={{
                backgroundColor: "#E09E3D",
                width: "80%",
                height: "150px",
                textAlign: "center",
                margin: "auto",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: isPrinting ? "solid 2px #E09E3D" : "",
            }}
        >
            <Typography
                style={{
                    minWidth: "160px",
                    maxWidth: "80%",
                    color: "#ffffff",
                }}
            >
                {text}
            </Typography>
        </div>
    );
};

export default RiskCenter;
