import { Typography } from "@mui/material";
import FormIntro, { FormIntroProps } from "../FormIntro";

export type RiskIntroProps = Pick<FormIntroProps, "isExpandedByDefault">;

export default function RiskIntro({ isExpandedByDefault }: RiskIntroProps) {
    return (
        <FormIntro isExpandedByDefault={isExpandedByDefault} title="Greining á áhættu og áfallaþoli">
            <Typography paragraph>
                Hægt er að vista svör og taka upp þráðinn síðar. Þegar nýtt skjal er opnað þá þarf að vista það til að
                það stofnist í grunninum. Eftir að skjal hefur verið stofnað er það vistað sjálfkrafa á nokkurra
                sekúndna fresti til að passa upp á að gögn tapist síður. Athugið að bara einn sé að vinna í skjali á
                hverjum tíma, annars geymast bara gögn þess sem vistaði síðast.
            </Typography>
            <Typography paragraph>
                Greiningu á áhættu og áfallaþoli verður að gera rafrænt en hægt er að prenta matið fyrir hverja áhættu
                út með því að smella á prentaratáknið hægra megin á síðunni.
            </Typography>
        </FormIntro>
    );
}
