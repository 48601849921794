import { useContext } from "react";

import { Stack } from "@mui/material";

import { Link, matchPath, useLocation } from "react-router-dom";

import { routes } from "../../routes/routes";
import { AppContext } from "../../utils/AppContext";

import { StyledLink, StyledTabs, StyledTab } from "almannavarnir-shared";
import { useShouldShow } from "../../utils/Utilities";
export const TabsHeader = ({
    mobile,
    toggleDrawer,
}: {
    mobile: boolean;
    toggleDrawer?: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}) => {
    const { user } = useContext(AppContext);

    const { risks: shouldShowRisks, surveys: shouldShowSurveys, places: shouldShowPlaces } = useShouldShow(user);

    const paths = [
        `/${routes.risks.analysis}/*`,
        `/${routes.risks.root}/*`,
        `/${routes.surveys.root}/*`,
        `/${routes.places.root}/*`,
    ];
    const location = useLocation();
    const match = paths.find((path) => matchPath(path, location.pathname));
    return mobile ? (
        <Stack sx={{ mt: 15 }}>
            {shouldShowRisks && (
                <StyledLink
                    label="Áhættugátt"
                    to={`/${routes.risks.root}`}
                    toggledrawer={toggleDrawer}
                    linkcomponent={Link}
                />
            )}
            {shouldShowRisks && (
                <StyledLink
                    label="Yfirlit áhættna"
                    to={`/${routes.risks.analysis}`}
                    toggledrawer={toggleDrawer}
                    linkcomponent={Link}
                />
            )}
            {shouldShowSurveys && (
                <StyledLink
                    label="Könnun á stöðu"
                    to={`/${routes.surveys.root}`}
                    toggledrawer={toggleDrawer}
                    linkcomponent={Link}
                />
            )}
            {shouldShowPlaces && (
                <StyledLink
                    label="Mikilvægir staðir"
                    to={`/${routes.places.root}`}
                    toggledrawer={toggleDrawer}
                    linkcomponent={Link}
                />
            )}
        </Stack>
    ) : (
        <StyledTabs value={match !== undefined && match.slice(0, -2)} indicatorColor="secondary">
            {shouldShowRisks && (
                <StyledTab
                    label="Áhættugátt"
                    value={`/${routes.risks.root}`}
                    to={`/${routes.risks.root}`}
                    linkcomponent={Link}
                />
            )}
            {shouldShowRisks && (
                <StyledTab
                    label="Yfirlit áhættna"
                    value={`/${routes.risks.analysis}`}
                    to={`/${routes.risks.analysis}`}
                    linkcomponent={Link}
                />
            )}
            {shouldShowSurveys && (
                <StyledTab
                    label="Könnun á stöðu"
                    value={`/${routes.surveys.root}`}
                    to={`/${routes.surveys.root}`}
                    linkcomponent={Link}
                />
            )}
            {shouldShowPlaces && (
                <StyledTab
                    label="Mikilvægir staðir"
                    value={`/${routes.places.root}`}
                    to={`/${routes.places.root}`}
                    linkcomponent={Link}
                />
            )}
        </StyledTabs>
    );
};
