import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAsync } from "../../utils/UseAsync";
import { IOrganization, IRisk } from "../../models/RiskTypes";
import { IRiskFormProps, RiskForm } from "../../components/Risks/RiskForm";
import { Spinner } from "../../components/Shared/Common";
import FullPageError from "../../components/FullPageError";
import { EditPageParams } from "../../routes/routes";

export interface EditRiskProps extends Pick<IRiskFormProps, "getRiskTypes" | "getPostCodes"> {
    getRisk: (ID: number) => Promise<IRisk>;
    updateRisk: (risk: IRisk) => Promise<any>;
    getOrganizations: () => Promise<IOrganization[]>;
}

export default function EditRisk({
    getRisk,
    updateRisk,
    getRiskTypes,
    getPostCodes,
    getOrganizations,
}: EditRiskProps): JSX.Element {
    const { ID: strID } = useParams<EditPageParams>();
    const ID = useMemo(() => parseInt(strID ?? "0"), [strID]);
    const wrappedGetRisk = useCallback(() => (ID ? getRisk(ID) : Promise.resolve(null)), [ID, getRisk]);
    const { status, value: risk, error } = useAsync(wrappedGetRisk);

    return (
        <div>
            <div>
                {status == "pending" && <Spinner />}
                {/* TODO: Style error */}
                {status == "error" && <FullPageError message={error || "Something went wrong"} />}
                {status == "success" && risk && (
                    <RiskForm
                        title="Breyta áhættu"
                        getRiskTypes={getRiskTypes}
                        risk={risk}
                        onSubmit={updateRisk}
                        getPostCodes={getPostCodes}
                        getOrganizations={getOrganizations}
                    />
                )}
            </div>
        </div>
    );
}
