import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { IRisk, newRisk, RiskType, PostCode, IOrganization } from "../../models/RiskTypes";
import { Theme, Button, FormHelperText, Hidden } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CausesControl } from "./CauseControl";
import { isValueEmpty, RedirectType, replaceAtIndex } from "../../utils/Utilities";
import { EffectsTableControl } from "./EffectsTableControl";
import { hideButtonText, hideInPrint, printOnNewPage, showOnlyInPrint, wrapperClass } from "../../styles/Styles";

import mergeWith from "lodash.mergewith";
import cloneDeep from "lodash.clonedeep";
import { history } from "../Shared/Common";
import { routes } from "../../routes/routes";
import { useAsync } from "../../utils/UseAsync";
import { AppContext } from "../../utils/AppContext";
import { SurveyTextField } from "../Survey/SurveyTextField";
import { AffectableCommunityProjects, Risk_likelyhood_Values, Risk_uncertainty_Values } from "../../models/FormValues";
import SurveySelect from "../Survey/SurveySelect";
import SurveySectionHeader from "../Survey/SurveySectionHeader";
import RiskIntro from "./RiksIntro";
import FormError from "../FormError";
import { matchesRoles, Roles } from "../../models/RiskUser";

import PrintIcon from "@mui/icons-material/Print";
import SaveIcon from "@mui/icons-material/Save";

export interface IRiskFormProps {
    title: string;
    onSubmit: (risk: IRisk) => Promise<Required<Pick<IRisk, "id">>>;
    getRiskTypes: () => Promise<RiskType[]>;
    getPostCodes: () => Promise<PostCode[]>;
    getOrganizations: () => Promise<IOrganization[]>;
    risk?: IRisk;
}

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        width: "100%",
    },
    success: {
        "& label": {
            color: theme.palette.success.main,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: `2px solid ${theme.palette.success.light}`,
            "&:hover": {
                borderWidth: "1px",
            },
        },
        "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
            },
        },
    },
    containerGrid: {
        paddingTop: theme.spacing(2),
    },
    wrapperClass: {
        ...wrapperClass.wrapperClass,
        paddingTop: theme.spacing(2),
    },
    saveButtonsContainer: {
        ...hideInPrint,
        margin: `${theme.spacing(4)} 0px ${theme.spacing(10)}`,
    },
    effectsTable: {
        [theme.breakpoints.down("md")]: {
            overflow: "scroll",
        },
        ...printOnNewPage,
    },
    printButton: {
        ...hideInPrint,
        ...hideButtonText,
        color: "white",
        position: "fixed",
        right: 20,
    },
    hiddenInPrint: {
        ...hideInPrint,
    },
    visibleOnlyInPrint: {
        ...showOnlyInPrint,
    },
    gridItem: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
}));

export function RiskForm({
    onSubmit,
    getRiskTypes,
    risk: existingRisk,
    getPostCodes,
    getOrganizations,
}: IRiskFormProps) {
    const classes = useStyles();
    const inputVariant = "outlined";

    const { user } = useContext(AppContext);

    const {
        status: riskTypeFetchStatus,
        value: riskTypes,
        // error: riskTypeFetcherror
    } = useAsync(getRiskTypes);
    const {
        status: postCodesFetchStatus,
        value: postCodes,
        // error: postCodesFetcherror
    } = useAsync(getPostCodes);

    const getFilteredOrgs = useCallback(
        () => getOrganizations().then((orgs) => orgs.filter((org) => org.hasRisks)),
        [getOrganizations]
    );

    const {
        status: organizationsFetchStatus,
        value: organizations,
        // error: organizationsFetcherror,
    } = useAsync(getFilteredOrgs);

    const [shouldAutoSave, setShouldAutoSave] = useState<boolean>(false);
    const [risk, setRisk] = useState<IRisk>(
        mergeWith(cloneDeep(newRisk), existingRisk, (val, srcVal) => (srcVal === null ? val : undefined))
    );

    const [error, setError] = useState<string | undefined>(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isPrinting, setIsPrinting] = useState(false);

    useEffect(() => {
        if (isPrinting) {
            console.log("Starting printing");
            window.print();
            console.log("Done printing");
            setIsPrinting(false);
        }
    }, [isPrinting]);

    const handleChange = useCallback(
        (property: keyof IRisk, newVal: string | number | number[] | undefined | Pick<IOrganization, "id">) => {
            setRisk({ ...risk, [property]: newVal });
            if (!risk.isPublished) setShouldAutoSave(true);
        },
        [setRisk, risk]
    );
    useEffect(() => {
        if (organizationsFetchStatus == "success" && organizations && !risk.organization?.id)
            handleChange("organization", { id: organizations[0].id });
    }, [organizations, organizationsFetchStatus, handleChange, risk.organization?.id]);

    const isReadOnly = useMemo(
        () => isPrinting || (risk.isPublished && user != undefined && !user.isAlmAdmin),
        [risk, user, isPrinting]
    );

    const handleSubmit = (newRisk: IRisk, redirectType: RedirectType = RedirectType.Home) => {
        setError(undefined);
        setIsSubmitting(true);
        return onSubmit(newRisk)
            .then((res) => {
                setIsSubmitting(false);
                if (redirectType == RedirectType.Edit) {
                    history.push(`/${routes.risks.edit.root}/${res.id}`);
                } else if (redirectType == RedirectType.Home) {
                    history.push(`/${routes.risks.root}`);
                }
            })
            .catch((err: string | Error) => {
                setIsSubmitting(false);
                setError((typeof err == "string" ? err : err.message) || "Eitthvað fór úrskeiðis við að vista");
            });
    };

    const isNewForm = useMemo(() => !existingRisk, [existingRisk]);

    useEffect(() => {
        if (!isNewForm && shouldAutoSave) {
            const autoSaveInterval = setInterval(() => {
                handleSubmit(risk, RedirectType.None);
                autoSaveInterval && clearInterval(autoSaveInterval);
            }, 1000 * 10);

            return () => {
                autoSaveInterval && clearInterval(autoSaveInterval);
            };
        }
    }, [risk, shouldAutoSave]);

    return (
        <div className={`${classes.wrapperClass}`}>
            {error && <FormError message={error} />}
            <Button
                disableElevation
                className={classes.printButton}
                size="large"
                variant="contained"
                color="primary"
                startIcon={isNewForm ? <SaveIcon /> : <PrintIcon />}
                onClick={() => {
                    isNewForm ? handleSubmit(risk, RedirectType.Edit) : setIsPrinting(true);
                }}
                style={{ zIndex: 1, top: "7%" }}
            >
                {isNewForm ? "Vista" : "Prenta"}
            </Button>
            <Grid container spacing={2} className={classes.containerGrid}>
                <Grid item xs={12}>
                    <RiskIntro isExpandedByDefault={true} />
                </Grid>
                <Grid container item xs={12} sm={!isPrinting && 4}>
                    <SurveySelect<number, IRisk>
                        disabled={
                            isReadOnly ||
                            organizationsFetchStatus != "success" ||
                            organizations == undefined ||
                            organizations.length == 1 ||
                            (existingRisk !== undefined &&
                                user &&
                                !matchesRoles(user.organizations, [Roles.almAdmin], risk.organization?.id))
                        }
                        variant={inputVariant}
                        surveyProperty="organization"
                        label="Stofnun"
                        value={risk.organization?.id}
                        onChange={(prop, newVal) => {
                            handleChange(prop, { id: newVal });
                        }}
                        options={(organizations || []).map((org) => ({ label: org.name, value: org.id }))}
                    />
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    // className={classes.gridItem}
                    style={{ alignItems: "flex-end" }}
                >
                    <Grid item xs={12} sm={!isPrinting && 4} className={classes.gridItem}>
                        <SurveyTextField
                            maxLength={50}
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="name"
                            label="Áhættuþáttur"
                            helperText="Stutt heiti sem lýsir áhættu (óæskilegu atviki)"
                            value={risk.name}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4} className={classes.gridItem}>
                        <FormControl
                            className={classes.formControl + (!isValueEmpty(risk?.typeID) ? " " + classes.success : "")}
                            variant={inputVariant}
                        >
                            <InputLabel id="riskTypeSelect" htmlFor="TypeSelect">
                                Gerð atviks
                            </InputLabel>
                            <Select
                                name="TypeSelect"
                                variant={inputVariant}
                                labelId="riskTypeSelect"
                                id="demo-simple-select"
                                label="Gerð atviks"
                                disabled={isReadOnly || riskTypeFetchStatus == "pending"}
                                value={risk?.typeID || ""}
                                onChange={(ev) => {
                                    setRisk({
                                        ...risk,
                                        typeID: ev.target.value as number,
                                    });
                                }}
                            >
                                {riskTypeFetchStatus == "success" &&
                                    riskTypes &&
                                    riskTypes.map((riskType) => (
                                        <MenuItem key={riskType.id} value={riskType.id}>
                                            {riskType.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4} className={classes.gridItem}>
                        <FormControl
                            className={
                                classes.formControl + (!isValueEmpty(risk?.categoryID) ? " " + classes.success : "")
                            }
                            variant={inputVariant}
                        >
                            <InputLabel id="riskTypeSelect" htmlFor="riskType">
                                Flokkur atviks
                            </InputLabel>
                            <Select
                                name="riskType"
                                label="Flokkur atviks"
                                variant={inputVariant}
                                disabled={isReadOnly || riskTypeFetchStatus == "pending" || !risk.typeID}
                                labelId="riskTypeSelect"
                                value={risk?.categoryID || ""}
                                onChange={(ev) => {
                                    setRisk({
                                        ...risk,
                                        categoryID: ev.target.value as number,
                                    });
                                }}
                            >
                                {risk.typeID &&
                                    riskTypeFetchStatus == "success" &&
                                    riskTypes &&
                                    riskTypes
                                        .filter((type) => type.id == risk.typeID)[0]
                                        .categories.map((riskType) => (
                                            <MenuItem key={riskType.id} value={riskType.id}>
                                                {riskType.name}
                                            </MenuItem>
                                        ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12} sm={!isPrinting && 8}>
                        <SurveyTextField
                            disabled={isReadOnly}
                            multiline
                            variant={inputVariant}
                            surveyProperty="description"
                            label="Nánari lýsing á áhættu"
                            helperText="Lýsing á áhættuþætti (óæskilegu atviki) og aðstæðum"
                            value={risk.description}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <SurveySectionHeader disabled={isReadOnly} title="Orsakir" />
                        <FormHelperText>
                            Stutt heiti sem lýsir orsökum og forvörnum (þ.e. hindrunum eða öðrum ráðstöfunum sem draga
                            úr líkum sem þegar eru til staðar)
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 8}>
                        <CausesControl
                            disabled={isReadOnly}
                            entityName="orsök"
                            causes={risk.causes}
                            addCause={(newCause) => {
                                setRisk({ ...risk, causes: [...risk.causes, newCause] });
                            }}
                            updateCause={(causeIndex, updatedCause) => {
                                setRisk({
                                    ...risk,
                                    causes: replaceAtIndex(risk.causes, causeIndex, updatedCause),
                                });
                            }}
                            removeCause={(causeIndex) => {
                                setRisk({
                                    ...risk,
                                    causes: risk.causes.filter((_, i) => i !== causeIndex),
                                });
                            }}
                        />
                    </Grid>
                    <Hidden smDown>
                        <Grid item sm={!isPrinting && 4}></Grid>
                    </Hidden>
                    <Grid item xs={12} sm={!isPrinting && 8}>
                        <SurveyTextField
                            disabled={isReadOnly}
                            multiline
                            variant={inputVariant}
                            surveyProperty="causesDescription"
                            label="Nánari lýsing á orsökum og forvörnum"
                            helperText="Lýsing á orsökum og forvörnum (þ.e. hindrunum eða öðrum ráðstöfunum sem draga
                            úr líkum sem þegar eru til staðar)"
                            value={risk.causesDescription}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <SurveySectionHeader title="Líkur og viðkvæmni" disabled={isReadOnly} />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4}>
                        <SurveySelect
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="probability"
                            label="Líkur"
                            value={risk.probability}
                            onChange={handleChange}
                            options={Risk_likelyhood_Values}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4}>
                        <SurveyTextField
                            disabled={isReadOnly}
                            multiline
                            variant={inputVariant}
                            surveyProperty="probabilityDescription"
                            label="Stutt skýring á líkum"
                            value={risk.probabilityDescription}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 8}>
                        <SurveyTextField
                            disabled={isReadOnly}
                            multiline
                            variant={inputVariant}
                            surveyProperty="probabilityArgument"
                            // label="Rökstuðningur"
                            label="Rökstuðningur á líkum"
                            value={risk.probabilityArgument}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 8}>
                        <SurveyTextField
                            disabled={isReadOnly}
                            multiline
                            variant={inputVariant}
                            surveyProperty="vulnerabilityAssessment"
                            label="Mat á viðkvæmni"
                            value={risk.vulnerabilityAssessment}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}></Grid>
                </Grid>
                <Grid item xs={12} className={classes.effectsTable}>
                    <SurveySectionHeader title="Mat á afleiðingum" disabled={isReadOnly} />
                    <EffectsTableControl
                        disabled={isReadOnly}
                        effects={risk.effects}
                        onChange={(newEffs) => {
                            setRisk({ ...risk, effects: newEffs });
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12} sm={!isPrinting && 6}>
                        <SurveyTextField
                            disabled={isReadOnly}
                            multiline
                            variant={inputVariant}
                            surveyProperty="summaryArgumentForEffects"
                            label="Samandreginn rökstuðningur á afleiðingum"
                            value={risk.summaryArgumentForEffects}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={8}>
                    <SurveySectionHeader title="Aðrar afleiðingar" disabled={isReadOnly} />
                    <FormHelperText>
                        Stutt heiti sem lýsir afleiðingum og forvörnum (þ.e. hindrunum eða öðrum ráðstöfunum sem draga
                        úr afleiðingum sem þegar eru til staðar)
                    </FormHelperText>
                    <CausesControl
                        disabled={isReadOnly}
                        entityName="afleiðingu"
                        causes={risk.customEffects}
                        addCause={(newCause) => {
                            setRisk({ ...risk, customEffects: [...risk.customEffects, newCause] });
                        }}
                        updateCause={(causeIndex, updatedCause) => {
                            setRisk({
                                ...risk,
                                customEffects: replaceAtIndex(risk.customEffects, causeIndex, updatedCause),
                            });
                        }}
                        removeCause={(causeIndex) => {
                            setRisk({
                                ...risk,
                                customEffects: risk.customEffects.filter((_, i) => i !== causeIndex),
                            });
                        }}
                    />
                </Grid>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12} sm={!isPrinting && 8}>
                        <SurveyTextField
                            disabled={isReadOnly}
                            multiline
                            variant={inputVariant}
                            surveyProperty="preventionDescription"
                            label="Frekari lýsing á forvörnum"
                            helperText="Lýsing á afleiðingum og forvörnum (þ.e. hindrunum eða öðrum ráðstöfunum sem draga
                        úr afleiðingum sem þegar eru til staðar)"
                            value={risk.preventionDescription}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 8}>
                        <SurveyTextField
                            disabled={isReadOnly}
                            multiline
                            variant={inputVariant}
                            surveyProperty="needForPublicWarning"
                            label="Þörf fyrir viðvaranir til almennings"
                            value={risk.needForPublicWarning}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 8}>
                        <SurveyTextField
                            disabled={isReadOnly}
                            multiline
                            variant={inputVariant}
                            surveyProperty="needForEvacuation"
                            label="Þörf fyrir rýmingu"
                            value={risk.needForEvacuation}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} xs={12} style={{ alignItems: "flex-end" }}>
                    <Grid item xs={12} sm={!isPrinting && 4}>
                        <SurveySelect
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="uncertainty"
                            label="Óvissa"
                            value={risk.uncertainty}
                            onChange={handleChange}
                            options={Risk_uncertainty_Values}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 8}>
                        <SurveyTextField
                            disabled={isReadOnly}
                            multiline
                            variant={inputVariant}
                            surveyProperty="uncertaintyArgument"
                            label="Rökstuðningur á óvissu"
                            value={risk.uncertaintyArgument}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4}>
                        <SurveySelect
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="canRiskFactorsBeControlled"
                            label="Stjórn"
                            helperText="Er hægt að hafa stjórn á áhættuþáttum?"
                            value={risk.canRiskFactorsBeControlled}
                            onChange={handleChange}
                            options={Risk_uncertainty_Values}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 8}>
                        <SurveyTextField
                            disabled={isReadOnly}
                            multiline
                            variant={inputVariant}
                            surveyProperty="riskFactorControlArgument"
                            label="Rökstuðningur"
                            helperText="Rökstuðningur á því hversu mikla stjórn er hægt að hafa á áhættuþættinum"
                            value={risk.riskFactorControlArgument}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12} sm={!isPrinting && 8}>
                        <SurveyTextField
                            multiline
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="proposedPreventions"
                            label="Tillögur að forvörnum"
                            helperText="Tillögur að forvörnum/aðgerðum til að hafa stjórn á áhættuþættinum (óæskilega atvikinu)"
                            value={risk.proposedPreventions}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 8}>
                        <SurveyTextField
                            disabled={isReadOnly}
                            multiline
                            variant={inputVariant}
                            surveyProperty="possibleToTransferToAnotherScenario"
                            label="Sambærileiki"
                            helperText="Er hægt að yfirfæra þetta atvik á sambærilegar aðstæður annars staðar í sveitarfélaginu?"
                            value={risk.possibleToTransferToAnotherScenario}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 8}>
                        {/* TODO: Verify and test */}
                        <SurveySelect<number[], IRisk>
                            multiple
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="affectedCommunityProjects"
                            label="Áhrif á samfélagsleg verkefni"
                            helperText="Samfélagslega mikilvæg verkefni sem gætu orðið fyrir áhrifum vegna atviksins"
                            value={risk.affectedCommunityProjects}
                            onChange={handleChange}
                            options={AffectableCommunityProjects}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} xs={12} style={{ alignItems: "flex-end" }}>
                    <Grid item xs={12}>
                        <SurveySectionHeader title="Staðsetning" disabled={isReadOnly} />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4}>
                        <FormControl
                            className={
                                classes.formControl + (!isValueEmpty(risk?.postalCodeID) ? " " + classes.success : "")
                            }
                            variant={inputVariant}
                        >
                            <InputLabel id="postCodes" htmlFor="PostCodeSelect">
                                Póstnúmer
                            </InputLabel>
                            <Select
                                name="PostCodeSelect"
                                variant={inputVariant}
                                labelId="postCodes"
                                label="Póstnúmer"
                                disabled={isReadOnly || postCodesFetchStatus == "pending"}
                                value={risk?.postalCodeID || ""}
                                onChange={(ev) => {
                                    setRisk({
                                        ...risk,
                                        postalCodeID: ev.target.value as number,
                                    });
                                }}
                            >
                                {postCodesFetchStatus == "success" &&
                                    postCodes &&
                                    postCodes.map((postCode) => (
                                        <MenuItem key={postCode.postalCodeID} value={postCode.postalCodeID}>
                                            {postCode.postalCode}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4}>
                        <SurveyTextField
                            number
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="locationLat"
                            label="Lengdargráða"
                            precision={10}
                            // TODO: add helper text
                            helperText="Kommutala með að hámarki sex aukastöfum (t.d. 63,934055)"
                            value={risk.locationLat?.toString()?.replace(".", ",") || ""}
                            onValidateNumber={(value) => Math.min(Math.max(value, -90), 90)}
                            onChange={(survProp: keyof IRisk, newVal: string) => {
                                if (newVal.slice(-1) !== "," && newVal.slice(-1) !== "0" && newVal.slice(-1) !== "-") {
                                    let value: number | undefined = parseFloat(newVal.replace(",", "."));
                                    if (isNaN(value)) {
                                        value = undefined;
                                    }
                                    console.log("I am running");
                                    handleChange(survProp, value);
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4}>
                        <FormControl className={classes.formControl}>
                            <SurveyTextField
                                number
                                disabled={isReadOnly}
                                variant={inputVariant}
                                surveyProperty="locationLon"
                                label="Breiddargráða"
                                precision={10}
                                helperText="Kommutala með að hámarki sex aukastöfum (t.d. -20,999037)"
                                value={risk.locationLon?.toString()?.replace(".", ",") || ""}
                                onValidateNumber={(value) => Math.min(Math.max(value, -180), 180)}
                                onChange={(survProp: keyof IRisk, newVal: string) => {
                                    if (
                                        newVal.slice(-1) !== "," &&
                                        newVal.slice(-1) !== "0" &&
                                        newVal.slice(-1) !== "-"
                                    ) {
                                        let value: number | undefined = parseFloat(newVal.replace(",", "."));
                                        if (isNaN(value)) {
                                            value = undefined;
                                        }
                                        handleChange(survProp, value);
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item container xs={12} spacing={2} className={classes.saveButtonsContainer}>
                    <Hidden mdDown>
                        <Grid item xs={2}></Grid>
                    </Hidden>
                    {isReadOnly ? (
                        <Grid item xs={12} sm={!isPrinting && 6} md={4}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ width: "100%", color: "white" }}
                                onClick={() => {
                                    history.push(`/${routes.risks.root}`);
                                }}
                            >
                                Loka
                            </Button>
                        </Grid>
                    ) : (
                        <>
                            {/* TODO: AUTH - Hide from normal users if already submitted */}
                            {user &&
                                matchesRoles(
                                    user.organizations,
                                    // TODO: AUTH - remove orgAdmin, use riskAdmin
                                    [Roles.almAdmin, Roles.riskAdmin],
                                    risk.organization?.id
                                ) && (
                                    <Grid item xs={12} sm={!isPrinting && 6} md={4}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{ width: "100%", color: "white" }}
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                handleSubmit({ ...risk, isPublished: true });
                                            }}
                                        >
                                            Staðfesta og skila
                                        </Button>
                                    </Grid>
                                )}
                            {!risk.isPublished && (
                                <Grid item xs={12} sm={!isPrinting && 6} md={4}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        style={{ width: "100%" }}
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            handleSubmit(risk);
                                        }}
                                    >
                                        Vista og halda áfram síðar
                                    </Button>
                                </Grid>
                            )}
                        </>
                    )}
                    <Hidden mdDown>
                        <Grid item xs={2}></Grid>
                    </Hidden>
                </Grid>
            </Grid>
        </div>
    );
}
