import { Box, Checkbox, IconButton, TableCell, TableRow } from "@mui/material";
import { IOrganization, IUserOrganization } from "../../models/RiskTypes";
import { useCallback, useMemo } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { AlmSelect } from "almannavarnir-shared";
import { NewRoles } from "../../models/RiskUser";

export interface UserOrganizationsRowProps {
    org: IUserOrganization;
    onChange: (newVal: IUserOrganization) => void;
    onDelete: () => void;
    variant: "filled" | "standard" | "outlined";
    orgOptions: IOrganization[];
}

export default function UserOrganizationsRow({
    org,
    variant,
    orgOptions,
    onChange,
    onDelete,
}: UserOrganizationsRowProps) {
    const handleChange = useCallback(
        // eslint-disable-next-line @typescript-eslint/ban-types
        (property: keyof IUserOrganization, newVal: number | object | undefined) => {
            console.log("RowChanged: ", property, newVal);
            onChange({ ...org, [property]: newVal });
        },
        [org, onChange]
    );

    const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const permID = Number(event.target.name);
        const checked = event.target.checked;

        //TODO Refactor i fall const addRole = (x) => handleChange("roles", [...org.roles.filter((r) => r !== x), permId]);
        if (checked) {
            switch (permID) {
                case NewRoles.placeAdmin:
                    handleChange("roles", [...org.roles.filter((r) => r !== NewRoles.placeEdit), permID]);
                    break;
                case NewRoles.placeEdit:
                    console.log("placeEdit");
                    handleChange("roles", [...org.roles.filter((r) => r !== NewRoles.placeAdmin), permID]);
                    break;
                case NewRoles.riskAdmin:
                    handleChange("roles", [...org.roles.filter((r) => r !== NewRoles.riskEdit), permID]);
                    break;
                case NewRoles.riskEdit:
                    handleChange("roles", [...org.roles.filter((r) => r !== NewRoles.riskAdmin), permID]);
                    break;
                case NewRoles.surveyAdmin:
                    handleChange("roles", [...org.roles.filter((r) => r !== NewRoles.surveyEdit), permID]);
                    break;
                case NewRoles.surveyEdit:
                    handleChange("roles", [...org.roles.filter((r) => r !== NewRoles.surveyAdmin), permID]);
                    break;
                case NewRoles.almAdmin:
                    handleChange("roles", [...org.roles.filter((r) => r !== NewRoles.almEdit), permID]);
                    break;
                case NewRoles.almEdit:
                    handleChange("roles", [...org.roles.filter((r) => r !== NewRoles.almAdmin), permID]);
                    break;
            }
        } else {
            handleChange(
                "roles",
                org.roles.filter((r) => r !== permID)
            );
        }
    };

    const currentOrg = useMemo(() => {
        return orgOptions.filter((itm) => itm.id === org.id)[0];
    }, [org, orgOptions]);
    return (
        <TableRow key={org.id}>
            <TableCell width="350px">
                <AlmSelect<number, IUserOrganization>
                    value={org.id}
                    surveyProperty="id"
                    onChange={handleChange}
                    variant={variant}
                    options={orgOptions.map(({ name, id }) => ({
                        value: id,
                        label: name,
                    }))}
                    disabled={org.id !== undefined}
                />
            </TableCell>
            <TableCell>
                <Box>
                    {currentOrg !== undefined && (
                        <table>
                            <tbody>
                                <tr>
                                    {/* TODO Refactor */}
                                    <td>
                                        <Checkbox
                                            checked={org.roles.some((rid) => rid === NewRoles.placeAdmin)}
                                            onChange={handleCheckChange}
                                            size="small"
                                            sx={{ visibility: currentOrg.hasPlaces ? "" : "hidden" }}
                                            name={`${NewRoles.placeAdmin}`}
                                        />
                                    </td>
                                    <td>
                                        <Checkbox
                                            checked={org.roles.some((rid) => rid === NewRoles.surveyAdmin)}
                                            onChange={handleCheckChange}
                                            size="small"
                                            sx={{ visibility: currentOrg.hasSurveys ? "" : "hidden" }}
                                            name={`${NewRoles.surveyAdmin}`}
                                        />
                                    </td>
                                    <td>
                                        <Checkbox
                                            checked={org.roles.some((rid) => rid === NewRoles.riskAdmin)}
                                            onChange={handleCheckChange}
                                            size="small"
                                            sx={{ visibility: currentOrg.hasRisks ? "" : "hidden" }}
                                            name={`${NewRoles.riskAdmin}`}
                                        />
                                    </td>
                                    <td>
                                        <Checkbox
                                            checked={org.roles.some((rid) => rid === NewRoles.almAdmin)}
                                            onChange={handleCheckChange}
                                            size="small"
                                            sx={{ visibility: currentOrg.id !== 1 ? "hidden" : "" }}
                                            name={`${NewRoles.almAdmin}`}
                                        />
                                    </td>
                                    <td>Admin</td>
                                </tr>
                                <tr>
                                    <td>
                                        <Checkbox
                                            checked={org.roles.some((rid) => rid === NewRoles.placeEdit)}
                                            onChange={handleCheckChange}
                                            size="small"
                                            sx={{ visibility: currentOrg.hasPlaces ? "" : "hidden" }}
                                            name={`${NewRoles.placeEdit}`}
                                        />
                                    </td>
                                    <td>
                                        <Checkbox
                                            checked={org.roles.some((rid) => rid === NewRoles.surveyEdit)}
                                            onChange={handleCheckChange}
                                            size="small"
                                            sx={{ visibility: currentOrg.hasSurveys ? "" : "hidden" }}
                                            name={`${NewRoles.surveyEdit}`}
                                        />
                                    </td>
                                    <td>
                                        <Checkbox
                                            checked={org.roles.some((rid) => rid === NewRoles.riskEdit)}
                                            onChange={handleCheckChange}
                                            size="small"
                                            sx={{ visibility: currentOrg.hasRisks ? "" : "hidden" }}
                                            name={`${NewRoles.riskEdit}`}
                                        />
                                    </td>
                                    <td>
                                        {/* <Checkbox
                                            checked={org.roles.some((rid) => rid === NewRoles.almEdit)}
                                            onChange={handleCheckChange}
                                            size="small"
                                            sx={{ visibility: currentOrg.id !== 1 ? "hidden" : "" }}
                                            name={`${NewRoles.almEdit}`}
                                        /> */}
                                    </td>
                                    <td>Edit</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                </Box>
            </TableCell>
            <TableCell align="right">
                <IconButton
                    onClick={() => {
                        if (window.confirm("Ertu viss um að þú viljir eyða?")) {
                            onDelete();
                        }
                    }}
                    color="error"
                >
                    <DeleteIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    );
}
