import { makeStyles } from "@mui/styles";
import { useCallback, useState } from "react";
import { IOrganization, PostCode } from "../../models/RiskTypes";
import { SurveyTextField } from "../Survey/SurveyTextField";
import { useAsync } from "../../utils/UseAsync";
import SurveySelect from "../Survey/SurveySelect";
import { OrganizationTypes } from "../../models/FormValues";
import mergeWith from "lodash.mergewith";
import { FormControlLabel, Switch, FormLabel, Theme, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { AlmButton } from "almannavarnir-shared";

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
    },
}));

export interface OrganizationDialogProps {
    onClose: (wasUpdated?: boolean) => void;
    getPostCodes: () => Promise<PostCode[]>;
    onSubmit: (org: IOrganization) => Promise<void>;
    organization?: IOrganization;
}

const variant = "outlined";

export default function OrganizationDialog({
    onClose,
    getPostCodes,
    organization: defaultOrganization,
    onSubmit,
}: OrganizationDialogProps) {
    const classes = useStyles();
    const [formValid, setFormVaild] = useState({ name: true, typeID: true });

    const [organization, setOrganization] = useState<IOrganization>(
        mergeWith({ name: "" }, defaultOrganization, (val, srcVal) => (srcVal === null ? val : undefined))
    );
    const { status: postCodesFetchStatus, value: postCodes } = useAsync(getPostCodes);

    const handleChange = useCallback(
        (property: keyof IOrganization, newVal: string | number | number[] | undefined | boolean) => {
            setOrganization({ ...organization, [property]: newVal });
            setFormVaild({ ...formValid, [property]: true });
        },
        [setOrganization, organization, formValid]
    );

    const isValid = (): boolean => {
        let allValid = true;
        const validInput = { name: true, typeID: true };
        if (!organization.name || organization.name.replace(/ /g, "").length < 1) {
            validInput.name = false;
            allValid = false;
        }
        if (!organization.typeID) {
            validInput.typeID = false;
            allValid = false;
        }

        setFormVaild(validInput);
        return allValid;
    };

    return (
        <>
            <DialogTitle id="form-dialog-title">Stofnun</DialogTitle>
            <DialogContent className={classes.content}>
                <SurveyTextField
                    autoFocus
                    label="Nafn"
                    value={organization.name || ""}
                    surveyProperty="name"
                    onChange={handleChange}
                    variant={variant}
                    errorText={"Nafn má ekki vera tómt"}
                    required
                    error={!formValid.name}
                />
                <SurveySelect<number, IOrganization>
                    label="Tegund Stofnunnar"
                    value={organization.typeID}
                    surveyProperty="typeID"
                    onChange={handleChange}
                    variant={variant}
                    options={OrganizationTypes}
                    errorText={"Velja þarf tegund stofnunar"}
                    required
                    error={!formValid.typeID}
                />
                <SurveySelect<number, IOrganization>
                    label="Póstfang"
                    value={organization.postalCodeID}
                    surveyProperty="postalCodeID"
                    onChange={handleChange}
                    variant={variant}
                    disabled={postCodesFetchStatus != "success"}
                    options={(postCodes ?? []).map((postCode) => ({
                        value: postCode.postalCodeID,
                        label: postCode.postalCode,
                    }))}
                />
                <SurveyTextField<IOrganization>
                    label="Heimilisfang"
                    value={organization.address || ""}
                    surveyProperty="address"
                    onChange={handleChange}
                    variant={variant}
                />
                <FormLabel component="legend">Hefur aðgang að áhættumati</FormLabel>
                <FormControlLabel
                    control={
                        <Switch
                            checked={organization.hasRisks || false}
                            onChange={(_ev, val) => handleChange("hasRisks", val)}
                        />
                    }
                    label={organization.hasRisks ? "Já" : "Nei"}
                />
                <FormLabel component="legend">Hefur aðgang að könnun á stöðu</FormLabel>
                <FormControlLabel
                    control={
                        <Switch
                            checked={organization.hasSurveys || false}
                            onChange={(_ev, val) => handleChange("hasSurveys", val)}
                        />
                    }
                    label={organization.hasSurveys ? "Já" : "Nei"}
                />
                <FormLabel component="legend">Hefur aðgang að mikilvægum stöðum</FormLabel>
                <FormControlLabel
                    control={
                        <Switch
                            checked={organization.hasPlaces || false}
                            onChange={(_ev, val) => handleChange("hasPlaces", val)}
                        />
                    }
                    label={organization.hasPlaces ? "Já" : "Nei"}
                />
            </DialogContent>
            <DialogActions sx={{ m: 1 }}>
                <AlmButton variant={"contained"} color="secondary" onClick={() => onClose()} title={"Hætta við"} />
                <AlmButton
                    variant={"contained"}
                    color="primary"
                    onClick={() => {
                        isValid() &&
                            onSubmit(organization)
                                .then(() => onClose(true))
                                .catch((err) => {
                                    console.log("Error updating: ", err);
                                });
                    }}
                    sx={{
                        width: "100%",
                        "&.Mui-disabled": { backgroundColor: (theme) => theme.palette.primary.main },
                    }}
                    title={"Vista"}
                />
            </DialogActions>
        </>
    );
}
