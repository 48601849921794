import { createStyles, withStyles, WithStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";

import { wrapperClass } from "../styles/Styles";
import IndexImageButton from "../components/Shared/IndexImageButtonV2";
import riskButtonBackground from "../assets/DJI_05232540.jpg";
import surveyButtonBackground from "../assets/DJI_05622540.jpg";
import newRiskBackground from "../assets/riskImg.jpg";
import { routes } from "../routes/routes";
import { useContext } from "react";
import { AppContext } from "../utils/AppContext";
import { useShouldShow } from "../utils/Utilities";

const styles = (theme: Theme) =>
    createStyles({
        wrapperClass: {
            ...wrapperClass.wrapperClass,
            paddingTop: theme.spacing(2),
        },
        buttonContainer: {
            display: "flex",
            width: "100%",
            gap: theme.spacing(2),
            flexWrap: "wrap",
        },
    });

function RiskOverview({ classes }: WithStyles<typeof styles>) {
    const { user } = useContext(AppContext);

    const { risks: shouldShowRisks, surveys: shouldShowSurveys, places: shouldShowPlaces } = useShouldShow(user);
    return (
        <div className={classes.wrapperClass}>
            <div className={classes.buttonContainer}>
                <Grid container>
                    {shouldShowRisks && (
                        <Grid item xs={12} sm={6} md={4} sx={{ justifyContent: "center", display: "flex" }}>
                            <IndexImageButton
                                backgroundUrl={riskButtonBackground}
                                title="Áhættugátt"
                                href={`/${routes.risks.root}`}
                            />
                        </Grid>
                    )}
                    {shouldShowSurveys && (
                        <Grid item xs={12} sm={6} md={4} sx={{ justifyContent: "center", display: "flex" }}>
                            <IndexImageButton
                                backgroundUrl={surveyButtonBackground}
                                title="Könnun á stöðu"
                                href={`/${routes.surveys.root}`}
                            />
                        </Grid>
                    )}
                    {shouldShowPlaces && (
                        <Grid item xs={12} sm={6} md={4} sx={{ justifyContent: "center", display: "flex" }}>
                            <IndexImageButton
                                backgroundUrl={newRiskBackground}
                                title="Mikilvægir staðir"
                                href={`/${routes.places.root}`}
                            />
                        </Grid>
                    )}
                </Grid>
            </div>
        </div>
    );
}

export default withStyles(styles)(RiskOverview);
