import { Collapse, List, ListItem, ListItemText, ListSubheader, Typography } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { useEffect, useState } from "react";
import { ListItemButton } from "@mui/material";
import { RisksByImpact } from "../../models/RiskAnalysis";
import { IRisk } from "../../models/RiskTypes";
import CopyButton from "../CopyButton";

export interface RisksListProps {
    allRisks: IRisk[];
    almAdmin: boolean;
}

enum probability {
    low = "Lítil",
    medium = "Meðal",
    high = "Mikil",
}
const addToArr = (
    mid: number,
    arr: RisksByImpact,
    val: string[],
    a: number | null | undefined,
    aMax: number,
    b: number | null | undefined = 0,
    bMax = 0
) => {
    a = a === aMax || a === null || a === undefined ? 0 : a;
    b = b === bMax || b === null || b === undefined ? 0 : b;

    const sum = a + b;
    if (sum !== 0) {
        if (sum < mid) {
            arr.low.push(val);
        } else if (sum === mid) {
            arr.medium.push(val);
        } else {
            arr.high.push(val);
        }
    }
};
const getImpact = (risk: IRisk, almAdmin: boolean): RisksByImpact => {
    const risksByImpact: RisksByImpact = { low: [], medium: [], high: [] };
    if (!risk) return risksByImpact;
    const stringRest: string[] = [
        `vegna áhættuþáttarins `,
        risk.name,
        almAdmin && risk.organization ? ` - ${risk.organization?.name}` : "",
    ];
    Object.keys(risk.effects).forEach((key) => {
        if (key === "lifeAndHealth") {
            addToArr(
                3,
                risksByImpact,
                [`• Fjölda slasaðra eða veikra `, ...stringRest],
                risk.effects[key].numberInjuredOrSick.weight,
                6
            );
            addToArr(
                3,
                risksByImpact,
                [`• Fjölda látinna `, ...stringRest],
                risk.effects[key].numberOfDeceased.weight,
                6
            );
        } else if (key === "natureAndEnvironment") {
            addToArr(
                4,
                risksByImpact,
                [`• Varanlegum skaða á menningarverðmætum, -starfsemi eða -minjum `, ...stringRest],
                risk.effects[key].permanentDamageToCulture_Protection.weight,
                5,
                risk.effects[key].permanentDamageToCulture_Scope.weight,
                3
            );
            addToArr(
                4,
                risksByImpact,
                [`• Varanlegum skaða á náttúru/umhverfi `, ...stringRest],
                risk.effects[key].permanentDamageToNature_Area.weight,
                5,
                risk.effects[key].permanentDamageToNature_Duration.weight,
                3
            );
        } else if (key === "physicalValuables") {
            addToArr(
                3,
                risksByImpact,
                [`• Beins efnahagslegs tjóns `, ...stringRest],
                risk.effects[key].directEconomicalDamage.weight,
                6
            );
            addToArr(
                3,
                risksByImpact,
                [`• Óbeins efnahagslegs tjóns `, ...stringRest],
                risk.effects[key].indirectEconomicalDamage.weight,
                6
            );
        } else if (key === "stability") {
            addToArr(
                5,
                risksByImpact,
                [`• Óuppfylltum grunnþörfum `, ...stringRest],
                risk.effects[key].basicNeedsUnfulfilled_Duration.weight,
                5,
                risk.effects[key].basicNeedsUnfulfilled_NumPeople.weight,
                5
            );
            addToArr(
                5,
                risksByImpact,
                [`• Truflunum á daglegu lífi `, ...stringRest],
                risk.effects[key].disturbancesToDailyLife_Duration.weight,
                5,
                risk.effects[key].disturbancesToDailyLife_NumPeople.weight,
                5
            );
        }
    });

    return risksByImpact;
};
const sortRisksByImpact = (risks: IRisk[], almAdmin: boolean): RisksByImpact => {
    const risksByImpact: RisksByImpact = { low: [], medium: [], high: [] };
    risks.forEach((risk) => {
        const r = getImpact(risk, almAdmin);
        risksByImpact.low = risksByImpact.low.concat(r.low);
        risksByImpact.medium = risksByImpact.medium.concat(r.medium);
        risksByImpact.high = risksByImpact.high.concat(r.high);
    });

    return risksByImpact;
};

const RiskListItems = (title: probability, list: string[][] | undefined) => {
    const [open, setOpen] = useState(true);

    const handleClick = () => {
        setOpen(!open);
    };
    if (list === undefined) {
        return;
    }
    const color = title === probability.high ? "#E31B0C" : title === probability.medium ? "#C77700" : "#3B873E";
    return (
        <>
            <ListItemButton style={{ justifyContent: "space-between" }} onClick={handleClick}>
                <ListSubheader
                    disableSticky={true}
                    style={{ paddingLeft: "0px", fontWeight: 600, fontSize: "1rem", color: color }}
                >
                    {`${title} áhætta er fyrir`}
                </ListSubheader>

                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {list.map((item, index) => (
                    <ListItem key={`${item}${index}`}>
                        <ListItemText
                            disableTypography={true}
                            style={{ display: "flex", flexDirection: "row", whiteSpace: "pre", flexWrap: "wrap" }}
                        >
                            {item.map((it, subindex) => (
                                <Typography
                                    variant="body2"
                                    key={`${it}${subindex}`}
                                    style={{
                                        fontWeight: subindex === 3 ? "600" : "",
                                        fontStyle: subindex === 2 ? "italic" : "",
                                        // border: "2px solid red",
                                    }}
                                >
                                    {it}
                                </Typography>
                            ))}
                        </ListItemText>
                    </ListItem>
                ))}
            </Collapse>
        </>
    );
};
const CopyRiskListItems = (title: probability, list: string[][] | undefined) => {
    const color = title === probability.high ? "#E31B0C" : title === probability.medium ? "#C77700" : "#3B873E";
    if (list === undefined) {
        return;
    }
    return (
        <>
            <p style={{ fontSize: "20px", fontWeight: "800", color: color }}>{title} áhætta er fyrir</p>
            <ul>
                {list.map((item, index) => (
                    <li key={`copyrisklist${item}${index}`}>
                        {item.map((it, subindex) => {
                            if (subindex === 3) {
                                return <b key={`${it}${subindex}`}>{it}</b>;
                            } else if (subindex === 2) {
                                return <i key={`${it}${subindex}`}>{it}</i>;
                            } else if (subindex === 0) {
                                return <span key={`${it}${subindex}`}>{it.slice(2)}</span>;
                            } else {
                                return <span key={`${it}${subindex}`}>{it}</span>;
                            }
                        })}
                    </li>
                ))}
            </ul>
        </>
    );
};

const RisksList = ({ allRisks, almAdmin }: RisksListProps) => {
    const [risks, setRisks] = useState<RisksByImpact>();
    useEffect(() => {
        if (allRisks !== null) {
            setRisks(sortRisksByImpact(allRisks, almAdmin));
        }
    }, [allRisks]);

    return (
        <div style={{ pageBreakAfter: "always" }}>
            <List dense={true}>
                <ListItem>
                    <ListItemText
                        disableTypography
                        primary={
                            <Typography style={{ paddingLeft: 0, fontWeight: 550, fontSize: "1.05rem" }}>
                                Áhættuatvik röðuð eftir vægi
                            </Typography>
                        }
                    />
                    <CopyButton elementID={"CopyToClipboardRiskList"} />
                </ListItem>
                {RiskListItems(probability.high, risks?.high)}
                {RiskListItems(probability.medium, risks?.medium)}
                {RiskListItems(probability.low, risks?.low)}
            </List>
            <div id="CopyToClipboardRiskList" style={{ display: "none", fontFamily: "PT Sans,sans-serif" }}>
                {CopyRiskListItems(probability.high, risks?.high)}
                {CopyRiskListItems(probability.medium, risks?.medium)}
                {CopyRiskListItems(probability.low, risks?.low)}
            </div>
        </div>
    );
};

export default RisksList;
