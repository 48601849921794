import { AttachmentTag } from "../services/Attachments";
import { IOrganization, OrganizationUser } from "./RiskTypes";

export interface IMinimumPlace {
    id?: number;
    title: string;
    accessAddress: string;
    organization: IOrganization;
}

export interface IPlace {
    id: number;
    author: OrganizationUser;
    organization: IOrganization;
    title: string;
    authorName?: string;
    authorPhoneNumber?: string;
    authorEmail?: string;
    type?: ImportantPlaceType;
    accessAddress: string;
    address?: string;
    postalCode?: string;
    locationLat?: number;
    locationLon?: number;
    propertyDescription?: string;
    contacts: ImportantPlaceContact[];
    generalDescription?: string;
    environmentDescription?: string;
    operationSpecificDescription?: string;
    status: ImportantPlaceSubmissionStatus;
    doesRequireSpecialHandover: boolean;
    specialHandoverDescription: string;
    submissionDescription: string;
    attachments: Attachment[];
    urls: Url[];
    updatedAt?: string;
}
export interface ImportantPlaceAllSubmission {
    id: string;
    importantPlaceID: string;
    organizationName: string;
    title: string;
    accessAddress: string;
    lastModified: string;
    status: string;
    activeSubmissionID?: number;
    historyID?: number;
    submittersDescription?: string;
    metadataSummary?: MetadataSummary;
}
export interface MetadataSummary {
    author: {
        name: string;
        email: string;
    };
    attachments?: {
        name: string;
        tagNames: string[];
        lastModified: string;
    }[];
    links?: {
        title: string;
        fullUrl: string;
        tagNames: string[];
    }[];
    contacts?: Pick<ImportantPlaceContact, "name" | "jobRoleName" | "email" | "workPhone" | "mobilePhone">[];
}

export interface Attachment {
    id: number;
    name: string;
    blobPath: string;
    size: number;
    tags: AttachmentTag[];
}
export interface Url {
    id: string;
    title: string;
    fullUrl: string;
    lastModified: Date;
    tags: AttachmentTag[];
    shouldDelete: boolean;
    isNew: boolean;
}

export interface ImportantPlaceType {
    id: number;
    title: string;
}

export interface ImportantPlaceSubmissionStatus {
    stringID: string;
    title: string;
    isEditable: boolean;
    isReviewable: boolean;
    isSubmitted: boolean;
}

export interface ImportantPlaceContact {
    id: string;
    importantPlaceSubmissionID: number;
    name: string;
    jobRoleName: string;
    address: string;
    mobilePhone: string;
    workPhone: string;
    email: string;
    shouldDelete?: boolean;
    isNew?: boolean;
}

const organizationDefaultUser: OrganizationUser = {
    id: 1,
    kennitala: "",
    firstName: "",
    lastName: "",
    mobilePhone: "",
    email: "",
    organizations: [],
    activeStatus: false,
};

const organizationDefault: IOrganization = {
    id: 0,
    name: "",
    hasRisks: false,
    hasPlaces: false,
    hasSurveys: false,
    type: undefined,
    typeID: undefined,
    address: undefined,
    postalCode: undefined,
    postalCodeID: undefined,
};

export const newPlace: Omit<IPlace, "status"> = {
    id: 0,
    author: organizationDefaultUser,
    organization: organizationDefault,
    title: "",
    authorName: undefined,
    authorPhoneNumber: undefined,
    authorEmail: undefined,
    attachments: [],
    type: { id: 1, title: "" },
    accessAddress: "",
    address: undefined,
    postalCode: undefined,
    urls: [],
    locationLat: undefined,
    locationLon: undefined,
    propertyDescription: undefined,
    contacts: [],
    generalDescription: undefined,
    environmentDescription: undefined,
    operationSpecificDescription: undefined,
    doesRequireSpecialHandover: false,
    specialHandoverDescription: "",
    submissionDescription: "",
};

export const newMinimumPlace: IMinimumPlace = {
    id: 0,
    title: "",
    accessAddress: "",
    organization: organizationDefault,
};
