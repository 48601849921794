import { Collapse, List, ListItem, ListItemText, Theme, Divider, Button } from "@mui/material";
import { ICauseOrEffect, ICausePrevention } from "../../models/RiskTypes";
import { EditableTextRow } from "../TableUtilities/EditableTextRow";
import { replaceAtIndex } from "../../utils/Utilities";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
    },
    success: {
        "& label": {
            color: theme.palette.success.main,
        },
        border: `2px solid ${theme.palette.success.light}`,
        borderRadius: "4px",

        "&:hover": {
            borderWidth: "1px",
            borderColor: "black",
            padding: "9px 1px 9px 1px",
        },
    },
    nested: {},
    listHeader: {
        color: theme.palette.text.primary,
        fontSize: theme.typography.body1.fontSize,
    },
    listText: {},
    addButtonContainer: {
        display: "flex",
        justifyContent: "center",
        paddingTop: theme.spacing(2),
    },
    addButton: {
        alignContent: "center",
        minWidth: "15em",
        width: "40%",
    },
    subListAddButton: {
        marginLeft: "25px",
    },
    collapseInnerContainer: {
        paddingLeft: theme.spacing(4),
    },
}));

interface ICauseListItemProps {
    cause: ICauseOrEffect;
    removeItem: () => void;
    itemEdited: (newVal: string) => void;
    removeSubItem: (obstacleIndex: number) => void;
    subItemEdited: (obstacleIndex: number, newVal: ICauseOrEffect["preventions"][number]) => void;
    subItemAdded: () => void;
    isLast: boolean;
    disabled?: boolean;
}

function CauseListItem({
    cause,
    removeItem,
    itemEdited,
    removeSubItem,
    subItemEdited,
    subItemAdded,
    isLast,
    disabled,
}: ICauseListItemProps) {
    const [isOpen, setIsOpen] = useState(true);
    const classes = useStyles();
    const maxSubItems = 2;
    return (
        <>
            <ListItem
                className="test"
                button
                disableRipple
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                {isOpen ? <ExpandLess /> : <ExpandMore />}
                <ListItemText>
                    <EditableTextRow
                        disabled={disabled}
                        value={cause.name}
                        showEdit
                        showDelete
                        rowDeleted={removeItem}
                        rowEdited={(newVal) => {
                            const trimmedNewVal = newVal.trim()
                            if (trimmedNewVal.length > 0) {
                                itemEdited(newVal);
                            } else if (!(cause.preventions && cause.preventions.length)) {
                                removeItem();
                            } else {
                                if (window.confirm("Ertu viss um að þú viljir eyða?")) {
                                    removeItem();
                                }
                            }
                        }}
                    />
                </ListItemText>
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <div className={classes.collapseInnerContainer}>
                    <List component="div" disablePadding className={classes.nested}>
                        <Divider />
                        {cause.preventions &&
                            cause.preventions
                                .map((prevention, preventionIndex) => {
                                    if (prevention.shouldDelete) return undefined;
                                    return (
                                        <div key={`${preventionIndex}`}>
                                            <ListItem>
                                                <EditableTextRow
                                                    disabled={disabled}
                                                    showEdit
                                                    showDelete
                                                    color="secondary"
                                                    value={prevention.name}
                                                    key={`sub-${preventionIndex}`}
                                                    rowDeleted={() => {
                                                        removeSubItem(preventionIndex);
                                                    }}
                                                    rowEdited={(newVal) =>
                                                        subItemEdited(preventionIndex, {
                                                            ...prevention,
                                                            name: newVal,
                                                        })
                                                    }
                                                />
                                            </ListItem>
                                            {preventionIndex != cause.preventions.length - 1 && <Divider />}
                                        </div>
                                    );
                                })
                                .filter((val) => val != undefined)}
                    </List>
                    {(!cause.preventions ||
                        cause.preventions.filter((prev) => !prev.shouldDelete).length < maxSubItems) &&
                        cause.name != "" &&
                        !disabled && (
                            <Button
                                className={`${classes.addButton} ${classes.subListAddButton}`}
                                variant="outlined"
                                color="secondary"
                                style={{ ...(!isLast ? { marginBottom: 10 } : {}) }}
                                onClick={() => subItemAdded()}
                                startIcon={<AddIcon />}
                            >
                                Bæta við forvörn
                            </Button>
                        )}
                </div>
            </Collapse>
        </>
    );
}

export interface ICausesControlProp {
    causes: ICauseOrEffect[];
    updateCause: (index: number, cause: ICauseOrEffect) => void;
    removeCause: (index: number) => void;
    addCause: (cause: ICauseOrEffect) => void;
    entityName: string;
    disabled?: boolean;
}

export function CausesControl({
    causes,
    updateCause,
    addCause,
    removeCause,
    entityName,
    disabled,
}: ICausesControlProp) {
    const classes = useStyles();

    const handleItemRemoved = (itemIndex: number, item: ICauseOrEffect) => {
        if (item.isNew) {
            removeCause(itemIndex);
        } else {
            (item.preventions || []).forEach((prevention) => (prevention.shouldDelete = true));
            updateCause(itemIndex, { ...item, shouldDelete: true });
        }
    };

    const removeSubItem = (causeIndex: number, preventionIndex: number) => {
        const cause = causes[causeIndex];
        const newPreventions: ICausePrevention[] = [...causes[causeIndex].preventions];

        if (newPreventions[preventionIndex].isNew) {
            newPreventions.splice(preventionIndex, 1);
        } else {
            newPreventions[preventionIndex].shouldDelete = true;
        }

        updateCause(causeIndex, {
            ...cause,
            preventions: newPreventions,
        });
    };

    const subItemAdded = (indexOfEffext: number, prevention: ICausePrevention) => {
        const cause = causes[indexOfEffext];
        const existingPreventions = cause.preventions ? cause.preventions : [];
        updateCause(indexOfEffext, {
            ...cause,
            preventions: [...existingPreventions, prevention],
        });
    };

    const subItemEdited = (
        causeIndex: number,
        obstacleIndex: number,
        newValue: ICauseOrEffect["preventions"][number]
    ) => {
        if (!(newValue.name || "").trim()) {
            removeSubItem(causeIndex, obstacleIndex);
            return;
        } else {
            const cause = causes[causeIndex];
            updateCause(causeIndex, {
                ...cause,
                preventions: replaceAtIndex(cause.preventions, obstacleIndex, newValue),
            });
        }
    };
    return (
        <List
            className={classes.root + (causes.length > 0 && causes[0].name !== "" ? " " + classes.success : "")}
            component="div"
        >
            {causes
                .map((cause, causeIndex) => {
                    if (cause.shouldDelete) return undefined;
                    return (
                        <div key={cause.id}>
                            <CauseListItem
                                disabled={disabled}
                                cause={cause}
                                isLast={causeIndex == causes.length - 1}
                                // itemEdited={(newValue: string) => itemEdited(causeIndex, newValue)}
                                // removeItem={() => removeCause(causeIndex)}
                                itemEdited={(newValue: string) => updateCause(causeIndex, { ...cause, name: newValue })}
                                removeItem={() => handleItemRemoved(causeIndex, cause)}
                                removeSubItem={(obstacleIndex) => removeSubItem(causeIndex, obstacleIndex)}
                                subItemEdited={(obstacleIndex, newVal) =>
                                    subItemEdited(causeIndex, obstacleIndex, newVal)
                                }
                                subItemAdded={() =>
                                    subItemAdded(causeIndex, {
                                        id:
                                            Math.max(
                                                0,
                                                ...(causes[causeIndex].preventions || []).map((prev) => prev.id)
                                            ) + 1,
                                        name: "",
                                        isNew: true,
                                    })
                                }
                            />
                            {causeIndex != causes.length - 1 && <Divider />}
                        </div>
                    );
                })
                .filter((val) => val != undefined)}
            {!disabled && (
                <div className={classes.addButtonContainer}>
                    <Button
                        className={classes.addButton}
                        variant="contained"
                        color="primary"
                        style={{ color: "white" }}
                        onClick={() => {
                            const newId = Math.max(0, ...causes.map((cause) => cause.id)) + 1;
                            addCause({ id: newId, name: "", isNew: true, preventions: [] });
                        }}
                        startIcon={<AddIcon />}
                    >
                        Bæta við {entityName}
                    </Button>
                </div>
            )}
        </List>
    );
}
