import Grid from "@mui/material/Grid";
import { ISurvey, defaultSurvey, IOrganization } from "../../models/RiskTypes";
import { Button, Hidden } from "@mui/material";
import { Theme } from "@mui/material/styles/createTheme";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { hideButtonText, hideInPrint, wrapperClass } from "../../styles/Styles";

import mergeWith from "lodash.mergewith";
import cloneDeep from "lodash.clonedeep";
import { history } from "../Shared/Common";
import { SurveyTextField } from "./SurveyTextField";
import { routes } from "../../routes/routes";
import SurveySelect from "./SurveySelect";
import {
    Q1_AnalyzeRisk_Values,
    Q5_AnalyzeRisk_Values,
    Q7_AnalyzeRisk_Values,
    Q9_AnalyzeRisk_Values,
    Q12_AnalyzeRisk_Values,
    QBasic_AnalyzeRisk_Values,
    Q16_Collaboration,
    themeOverview_Q3_SystemUsed,
    themeFuel_Q6_ProblemsOccured,
    themeOwnPreparation_Q1_WeAreAllAlmannavarnir,
    TQ1_Values,
    TQ2_Values,
    themeOwnPreparation_Q2_WhatOperationValues,
} from "../../models/FormValues";
import SurveyMultiPartSelect from "./SurveyMultiPartSelect";
import SurveySectionHeader from "./SurveySectionHeader";
import SurveySlider from "./SurveySlider";
import SurveyMultiPartMultiOptionTable from "./SurveyMultiPartMultiOptionTable";
import { AppContext } from "../../utils/AppContext";
import { matchesRoles, Roles } from "../../models/RiskUser";
import { useAsync } from "../../utils/UseAsync";
import PrintIcon from "@mui/icons-material/Print";
import SurveyIntro from "./SurveyIntro";
import FormError from "../FormError";
import { RedirectType } from "../../utils/Utilities";
import SaveIcon from "@mui/icons-material/Save";
import { makeStyles } from "@mui/styles";

export interface ISurveyFormProps {
    title: string;
    onSubmit: (survey: ISurvey) => Promise<any>;
    survey?: ISurvey;
    getOrganizations: () => Promise<IOrganization[]>;
}

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        width: "100%",
        "& .MuiSelect-selectMenu": {
            whiteSpace: "normal",
        },
    },
    containerGrid: {
        paddingTop: theme.spacing(2),
    },
    wrapperClass: {
        ...wrapperClass.wrapperClass,
        paddingTop: theme.spacing(2),
    },
    saveButtonsContainer: {
        ...hideInPrint,
        margin: `${theme.spacing(4)} 0px ${theme.spacing(10)}`,
    },
    tableOverflow: {
        [theme.breakpoints.down("md")]: {
            overflow: "scroll",
        },
        "@media print": {
            overflow: "auto",
        },
    },
    printButton: {
        ...hideInPrint,
        ...hideButtonText,
        color: "white",
        position: "fixed",
        right: 20,
    },
    gridItem: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
}));

export function SurveyForm({ onSubmit, survey: existingSurvey, getOrganizations }: ISurveyFormProps) {
    const classes = useStyles();
    const inputVariant: "filled" | "standard" | "outlined" = "outlined";

    const { user } = useContext(AppContext);

    const [shouldAutoSave, setShouldAutoSave] = useState<boolean>(false);
    const [survey, setSurvey] = useState<ISurvey | ISurvey>(
        mergeWith(cloneDeep(defaultSurvey), existingSurvey, (val, srcVal) => (srcVal === null ? val : undefined))
    );

    const [error, setError] = useState<string | undefined>(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isPrinting, setIsPrinting] = useState(false);

    useEffect(() => {
        if (isPrinting) {
            console.log("Starting printing");
            window.print();
            console.log("Done printing");
            setIsPrinting(false);
        }
    }, [isPrinting]);

    const getFilteredOrgs = useCallback(
        () => getOrganizations().then((orgs) => orgs.filter((org) => org.hasSurveys)),
        [getOrganizations]
    );

    const {
        status: organizationsFetchStatus,
        value: organizations,
        error: organizationsFetcherror,
    } = useAsync(getFilteredOrgs);

    const handleChange = useCallback(
        (property: keyof ISurvey, newVal: string | number | number[] | Pick<IOrganization, "id">) => {
            setSurvey({ ...survey, [property]: newVal });
            if (!survey.isPublished) setShouldAutoSave(true);
        },
        [setSurvey, survey]
    );

    useEffect(() => {
        if (organizationsFetchStatus == "success" && organizations && !survey.organization?.id)
            handleChange("organization", { id: organizations[0].id });
    }, [organizations, organizationsFetchStatus, survey.organization?.id, handleChange]);

    const isReadOnly = useMemo(
        () => isPrinting || (survey.isPublished && user != undefined && !user.isAlmAdmin),
        [survey, user, isPrinting]
    );

    const handleSubmit = (newSurvey: ISurvey, redirectType: RedirectType = RedirectType.Home) => {
        // TODO: Allow print, if not print redirect to almannavarnir!
        setError(undefined);
        setIsSubmitting(true);
        return onSubmit(newSurvey)
            .then((res) => {
                // if (shouldRedirect) {
                //     history.push(`/${routes.surveys.root}`);
                // }
                setIsSubmitting(false);
                if (redirectType == RedirectType.Edit) {
                    history.push(`/${routes.surveys.edit.root}/${res.id}`);
                } else if (redirectType == RedirectType.Home) {
                    history.push(`/${routes.surveys.root}`);
                }
            })
            .catch((err: string | Error) => {
                console.error(err);
                setIsSubmitting(false);
                setError((typeof err == "string" ? err : err.message) || "Eitthvað fór úrskeiðis við að vista");
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const isNewForm = useMemo(() => !existingSurvey, [existingSurvey]);

    useEffect(() => {
        if (!isNewForm && shouldAutoSave) {
            const autoSaveInterval = setInterval(() => {
                handleSubmit(survey, RedirectType.None);
                autoSaveInterval && clearInterval(autoSaveInterval);
            }, 1000 * 10);

            return () => {
                autoSaveInterval && clearInterval(autoSaveInterval);
            };
        }
    }, [survey, shouldAutoSave]);

    return (
        <div className={`${classes.wrapperClass}`}>
            {error && <FormError message={error} />}
            <Button
                disableElevation
                className={classes.printButton}
                size="large"
                variant="contained"
                color="primary"
                startIcon={isNewForm ? <SaveIcon /> : <PrintIcon />}
                onClick={() => {
                    isNewForm ? handleSubmit(survey, RedirectType.Edit) : setIsPrinting(true);
                }}
                style={{ zIndex: 1, top: "7%" }}
            >
                {isNewForm ? "Vista" : "Prenta"}
            </Button>
            <Grid container spacing={2} className={classes.containerGrid}>
                <Grid item xs={12}>
                    <SurveyIntro isExpandedByDefault={true} />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={!isPrinting && 4}
                    className={classes.gridItem}
                    style={{ justifyContent: "flex-end" }}
                >
                    <SurveySelect<number, ISurvey>
                        disabled={
                            isReadOnly ||
                            organizationsFetchStatus != "success" ||
                            organizations == undefined ||
                            organizations.length == 1 ||
                            (existingSurvey !== undefined &&
                                user &&
                                !matchesRoles(user.organizations, [Roles.almAdmin], survey.organization?.id))
                        }
                        {...(organizationsFetchStatus == "error"
                            ? {
                                  error: true,
                                  helperText:
                                      organizationsFetcherror || "Eitthvað fór úrskeiðis við að sækja stofnanir",
                              }
                            : {})}
                        variant={inputVariant}
                        surveyProperty="organization"
                        label="Stofnun"
                        value={survey.organization?.id}
                        onChange={(prop, newVal) => {
                            handleChange(prop, { id: newVal });
                        }}
                        options={(organizations || []).map((org) => ({ label: org.name, value: org.id }))}
                    />
                </Grid>
                <Grid item xs={12} sm={!isPrinting && 4} className={classes.gridItem}>
                    <SurveySelect
                        disabled={isReadOnly}
                        variant={inputVariant}
                        surveyProperty="q1_AnalyzeRisk"
                        label="Síðasta greining"
                        title="Hefur sveitarfélagið framkvæmt greiningu á áhættu og áfallaþoli ? Ef svo er, hvenær var hún síðast framkvæmd?"
                        value={survey.q1_AnalyzeRisk}
                        onChange={handleChange}
                        options={Q1_AnalyzeRisk_Values}
                        // MenuProps={{
                        //     // anchorOrigin: { vertical: "bottom", horizontal: "center" },
                        //     transformOrigin: { vertical: "bottom", horizontal: "center" },
                        // }}
                    />
                </Grid>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <SurveySectionHeader
                            disabled={isReadOnly}
                            variant={"h4"}
                            title="Lágmarkskröfur fyrir greiningu á áhættu og áfallaþoli"
                            subTitle="Sveitarfélög þurfa að gera áhættugreiningu, þ.m.t. að kortleggja og meta líkur á óæskilegum atvikum sem
                        geta komið upp í sveitarfélagi og hvaða afleiðingar þau geta haft á sveitarfélagið."
                        />
                    </Grid>
                    <SurveyMultiPartSelect
                        titleVariant={"body1"}
                        isPrinting={isPrinting}
                        disabled={isReadOnly}
                        variant={inputVariant}
                        onChange={handleChange}
                        label="Eru eftirfarandi þættir skoðaðir í greiningu á áhættu og áfallaþoli sveitarfélagsins (lágmarkskröfur)?"
                        options={QBasic_AnalyzeRisk_Values}
                        sections={[
                            {
                                surveyProperty: "q3_P1_AnalyzeRisk",
                                label: "Utanaðkomandi áhætta",
                                helperText:
                                    "Áhætta og áfallaþol utan landfræðilegs svæðis sveitarfélagsins sem kann að hafa þýðingu fyrir sveitarfélagið",
                                value: survey.q3_P1_AnalyzeRisk,
                            },
                            {
                                surveyProperty: "q3_P2_AnalyzeRisk",
                                label: "Sérstakar áskoranir",
                                helperText:
                                    "Sérstakar áskoranir fyrir samfélagslega mikilvæg verkefni (þ.e. verkefni sem eru nauðsynleg til að sinna grunnþörfum íbúa og samfélags).",
                                value: survey.q3_P2_AnalyzeRisk,
                            },
                            {
                                surveyProperty: "q3_P3_AnalyzeRisk",
                                label: "Áhætta og áfallaþol",
                                helperText: "Framtíðaráhætta og framtíðaráfallaþol",
                                value: survey.q3_P3_AnalyzeRisk,
                            },
                        ]}
                    />
                    <SurveyMultiPartSelect<number, ISurvey>
                        isPrinting={isPrinting}
                        disabled={isReadOnly}
                        titleVariant={"body1"}
                        variant={inputVariant}
                        onChange={handleChange}
                        label="Hefur sveitarfélagið, í greiningu á áhættu og áfallaþoli, metið eigin getu til að viðhalda eftirfarandi
                        þjónustu þegar óæskilegir atburðir verða?"
                        options={QBasic_AnalyzeRisk_Values}
                        sections={[
                            {
                                surveyProperty: "q4_P1_AnalyzeRisk",
                                label: "Neyðarstjórn",
                                helperText: "",
                                value: survey.q4_P1_AnalyzeRisk,
                            },
                            {
                                surveyProperty: "q4_P2_AnalyzeRisk",
                                label: "Heilbrigðis- og velferðarþjónusta",
                                helperText: "",
                                value: survey.q4_P2_AnalyzeRisk,
                            },
                            {
                                surveyProperty: "q4_P3_AnalyzeRisk",
                                label: "Slökkvilið og annar viðbúnaður",
                                helperText: "Slökkvilið og annar viðbúnaður á vegum sveitarfélagsins",
                                value: survey.q4_P3_AnalyzeRisk,
                            },
                            {
                                surveyProperty: "q4_P4_AnalyzeRisk",
                                label: "Vatns- og fráveitur",
                                helperText: "",
                                value: survey.q4_P4_AnalyzeRisk,
                            },
                            {
                                surveyProperty: "q4_P5_AnalyzeRisk",
                                label: "Hitaveitur",
                                helperText: "",
                                value: survey.q4_P5_AnalyzeRisk,
                            },
                            {
                                surveyProperty: "q4_P6_AnalyzeRisk",
                                label: "Gagnaveitur",
                                helperText: "T.d. ljósleiðarar",
                                value: survey.q4_P6_AnalyzeRisk,
                            },
                        ]}
                    />
                    <Grid item xs={12} sm={!isPrinting && 8}>
                        <SurveyTextField
                            disabled={isReadOnly}
                            multiline
                            variant={inputVariant}
                            surveyProperty="disasterToleranceDesc"
                            label="Nánari svör / athugasemdir "
                            title="Nánari svör eða athugasemdir vegna spurninga um áfallaþol"
                            value={survey.disasterToleranceDesc}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <SurveySectionHeader
                            disabled={isReadOnly}
                            variant={"h4"}
                            title="Kröfur um alhliða og markvisst almannavarnastarf – Sveitarstjórnarlög"
                        />
                        <SurveySectionHeader
                            disabled={isReadOnly}
                            variant={"h6"}
                            title="Velferðarverkefni, umhverfisverkefni og efnahagsverkefni"
                            subTitle="Kröfurnar fjalla um að sveitarfélagið setji sér langtímamarkmið og hafi framkvæmdaáætlun um
eftirfylgni varðandi ýmsa þætti sem snúa að því að tryggja öryggi borgaranna sem og viðbúnað.
Langtímamarkmið þurfa að byggja á greiningu á áhættu og áfallaþoli sveitarfélagsins og ætti að
endurspegla hvernig sveitarfélagið vinnur að því að tryggja öryggi borgaranna.
Langtímamarkmiðin þurfa að vera staðfest og vel kynnt í sveitarfélaginu."
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4}>
                        <SurveySelect
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="q5_HasPreparedObjectives"
                            label="Markmið"
                            title="Hefur sveitarfélagið útbúið markmið vegna vinnu er varðar þætti sem snúa að því að tryggja öryggi borgaranna sem og viðbúnað?"
                            value={survey.q5_HasPreparedObjectives}
                            onChange={handleChange}
                            options={Q5_AnalyzeRisk_Values}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SurveySectionHeader
                            title=""
                            disabled={isReadOnly}
                            subTitle="Framkvæmdaáætlun um eftirfylgni þarf að byggja á greiningu á áhættu og áfallaþoli sveitarfélags.
                        Áætlunin þarf annars vegar að tengja saman greiningu á áhættu og áfallaþoli sveitarfélagsins og hins vegar aðrar
                        áætlanir sveitarfélagsins. Um leið þarf hún að vera burðarás vinnu við að tryggja öryggi borgaranna og samfélagsins sem og
                        viðbúnað í sveitarfélaginu. Framkvæmdaáætlunin þarf að taka til langtímamarkmiða, áætlana og
                        forgangsröðunar verkefna sem varða úrbætur, forvarnir, viðbúnað og neyðarstjórnun."
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 6} className={classes.gridItem}>
                        <SurveySelect
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="q6_HasPreparedExecutionPlan"
                            label="Framkvæmdaáætlun"
                            title="Hefur sveitarfélagið unnið framkvæmdaáætlun til að halda utan um eftirfylgni með þáttum sem snúa að því að tryggja öryggi borgaranna sem og viðbúnað?"
                            value={survey.q6_HasPreparedExecutionPlan}
                            onChange={handleChange}
                            options={Q5_AnalyzeRisk_Values}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 6} className={classes.gridItem}>
                        <SurveySelect
                            disabled={isReadOnly}
                            multiple
                            variant={inputVariant}
                            surveyProperty="q7_WorkRegardingSafety"
                            label="Samþætting við áætlanir"
                            // Upphafleg útgáfa alma:
                            // title="Er starf sveitarfélagsins samþætt þegar kemur að því að tryggja öryggi borgaranna sem og viðbúnað í einni eða fleiri af eftirfarandi áætlununum?"
                            title="Í hverjum af eftirfarandi áætlunum er starf sveitarfélagsins samþætt þegar kemur að því að tryggja öryggi borgaranna sem og viðbúnað?"
                            helperText="Þetta eru áætlanir sem m.a. er krafist í skipulags- og byggingarlögum"
                            value={survey.q7_WorkRegardingSafety}
                            onChange={handleChange}
                            options={Q7_AnalyzeRisk_Values}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 8}>
                        <SurveyTextField
                            multiline
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="generalSafetyWorkDescription"
                            label="Nánari svör / athugasemdir "
                            title="Athugasemdir eða útfærsla á spurningunum um heildrænt og kerfisbundið almannavarnastarf"
                            value={survey.generalSafetyWorkDescription}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <SurveySectionHeader
                            disabled={isReadOnly}
                            variant={"h4"}
                            title="Viðbragðsáætlun – 16. gr. í lögum um almannavarnir"
                            subTitle="Viðbragðsáætlun fyrir umdæmi almannavarnanefndar þarf að samræma og samþætta við aðrar
                        viðbragðsáætlanir sem sveitarfélög með sameiginlega almannavarnanefnd bera ábyrgð á. Að auki
                        verður að samræma viðbragðsáætlunina við áætlanir annarra aðila. Viðbragðsáætlunina þarf uppfæra
                        og endurskoða reglulega. Fara þarf yfir viðbragðsáætlunina að lágmarki einu sinni á ári."
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4} className={classes.gridItem}>
                        <SurveySelect
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="q8_RecoveryPlanAvailable"
                            label="Viðbragðsáætlun"
                            title="Er til viðbragðsáætlun fyrir umdæmi þeirrar almannavarnanefndar sem sveitarfélagið tilheyrir?"
                            value={survey.q8_RecoveryPlanAvailable}
                            onChange={handleChange}
                            options={Q5_AnalyzeRisk_Values}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4} className={classes.gridItem}>
                        <SurveySelect
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="q9_RecoveryPlanLastReview"
                            label="Síðasta endurskoðun"
                            title="Hvenær var núverandi viðbragðsáætlun síðast endurskoðuð?"
                            helperText="Merktu við hvenær áætlunin var gerð ef hún hefur ekki verið endurskoðuð."
                            value={survey.q9_RecoveryPlanLastReview}
                            onChange={handleChange}
                            options={Q9_AnalyzeRisk_Values}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4} className={classes.gridItem}>
                        <SurveySelect
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="q10_RecoveryPlanBasedOnRiskAnalysis"
                            label="Áhætta og áfallaþol"
                            title="Byggir viðbragðsáætlun almannavarnarnefndar sem sveitarfélagið tilheyrir á greiningu sveitarfélagsins á áhættu og áfallaþoli?"
                            value={survey.q10_RecoveryPlanBasedOnRiskAnalysis}
                            onChange={handleChange}
                            options={QBasic_AnalyzeRisk_Values}
                        />
                    </Grid>
                    <SurveyMultiPartSelect<number, ISurvey>
                        isPrinting={isPrinting}
                        disabled={isReadOnly}
                        variant={inputVariant}
                        onChange={handleChange}
                        titleVariant={"body1"}
                        label="Inniheldur viðbragðsáætlun almannavarnarnefndar sem sveitarfélagið tilheyrir eftirfarandi?"
                        options={QBasic_AnalyzeRisk_Values}
                        sections={[
                            {
                                surveyProperty: "q11_P1_RecoveryPlan",
                                label: "Skipulag",
                                helperText: "Skipulag fyrir neyðarstjórnun sveitarfélagsins",
                                value: survey.q11_P1_RecoveryPlan,
                            },
                            {
                                surveyProperty: "q11_P2_RecoveryPlan",
                                label: "Boðunarlisti",
                                helperText: "Boðunarlisti fyrir aðila í neyðarstjórnun sveitarfélagsins",
                                value: survey.q11_P2_RecoveryPlan,
                            },
                            {
                                surveyProperty: "q11_P6_RecoveryPlan",
                                label: "Gátlistar og leiðbeiningar",
                                helperText: "Gátlistar og leiðbeiningar fyrir mismunandi starfsemi í sveitarfélaginu",
                                value: survey.q11_P6_RecoveryPlan,
                            },
                            {
                                surveyProperty: "q11_P3_RecoveryPlan",
                                label: "Yfirlit yfir innri og ytri aðila ",
                                helperText: "",
                                value: survey.q11_P3_RecoveryPlan,
                            },
                            {
                                surveyProperty: "q11_P4_RecoveryPlan",
                                label: "Rýmingaráætlun",
                                helperText: "",
                                value: survey.q11_P4_RecoveryPlan,
                            },
                            {
                                surveyProperty: "q11_P5_RecoveryPlan",
                                label: "Skipulag fyrir neyðarsamskipti",
                                helperText: "",
                                value: survey.q11_P5_RecoveryPlan,
                            },
                        ]}
                    />
                    <Grid item xs={12} sm={!isPrinting && 8}>
                        <SurveyTextField
                            disabled={isReadOnly}
                            multiline
                            variant={inputVariant}
                            surveyProperty="recoveryPlanFurtherDescription"
                            label="Nánari svör / athugasemdir "
                            title="Nánari svör eða athugasemdir vegna spurninga um viðbragðsáætlun almannavarnarnefndar sem sveitarfélagið tilheyrir"
                            value={survey.recoveryPlanFurtherDescription}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <SurveySectionHeader
                            disabled={isReadOnly}
                            variant={"h6"}
                            title="Æfingar - 17. gr. í lögum um almannavarnir"
                            subTitle="Æfa þarf viðbragðsáætlun almannavarnarnefndar sem sveitarfélagið tilheyrir og gott er að miða við
                        æfingu á tveggja ára fresti. Aðstæður fyrir æfingar þurfa að byggja á greiningu á áhættu og
                        áfallaþoli sveitarfélags. Sveitarfélagið verður að æfa með öðrum sveitarfélögum og hlutaðeigandi
                        aðilum eftir því sem er viðeigandi út frá valinni atburðarás og formi æfingar. Ekki er hægt að
                        líta á raunverulega atburði sem æfingar."
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4}>
                        <SurveySelect
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="q12_PerformedTraining"
                            label="Síðasta æfing"
                            title="Hefur sveitarfélagið æft eigin viðbúnað í samræmi við kröfur í lögum um almannavarnir? Ef svo er, hvaða ár var síðasta æfing haldin?"
                            value={survey.q12_PerformedTraining}
                            onChange={handleChange}
                            options={Q12_AnalyzeRisk_Values}
                        />
                    </Grid>
                    <Hidden smDown>
                        <Grid item xs={8}></Grid>
                    </Hidden>
                    <Grid item xs={12} sm={!isPrinting && 8}>
                        <SurveyTextField
                            disabled={isReadOnly}
                            multiline
                            variant={inputVariant}
                            surveyProperty="performedTrainingDescription"
                            label="Nánari svör / athugasemdir "
                            title="Nánari svör eða athugasemdir vegna spurninga um æfingar"
                            value={survey.performedTrainingDescription}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <SurveySectionHeader
                            disabled={isReadOnly}
                            variant={"h4"}
                            title="Staðfesting – 17. gr. í lögum um almannavarnir"
                            subTitle="Í lögum um almannavarnir er gerð krafa um staðfestingu á viðbragðsáætlun. Staðfesting
                        framkvæmdaáætlunar, sem gerð er upp úr greiningu á áhættu og áfallaþoli sveitarfélags er ekki reglugerðarkrafa. 
                        Engu að síður er skynsamlegt að haga fyrirkomulagi svo að stjórnendur sveitarfélags taki
                        afstöðu til allra þessara þátta í starfinu við að tryggja öryggi borgaranna og samfélagsins sem og viðbúnað. Það er undir
                        sveitarfélaginu sjálfu komið hvenær, hvernig og hversu oft sveitarstjórn kemur að máli."
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4} className={classes.gridItem}>
                        <SurveySelect
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="q13_AnalyzeRiskConfirmed"
                            label="Áhætta og áfallaþol"
                            title="Hefur greining á áhættu og áfallaþoli sveitarfélagsins verið staðfest af sveitarstjórn?"
                            value={survey.q13_AnalyzeRiskConfirmed}
                            onChange={handleChange}
                            options={Q5_AnalyzeRisk_Values}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4} className={classes.gridItem}>
                        <SurveySelect
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="q14_ExecutionPlanConfirmed"
                            label="Framkvæmdaáætlun"
                            title="Hefur framkvæmdaáætlun vegna eftirfylgni með greiningu á áhættu og áfallaþoli verið staðfest af sveitarstjórn?"
                            value={survey.q14_ExecutionPlanConfirmed}
                            onChange={handleChange}
                            options={Q5_AnalyzeRisk_Values}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4} className={classes.gridItem}>
                        <SurveySelect
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="q15_RecoveryPlanConfirmed"
                            label="Viðbragðsáætlun"
                            title="Hefur viðbragðsáætlun almannavarnarnefndar sem sveitarfélagið tilheyrir verið staðfest af sveitarstjórn?"
                            value={survey.q15_RecoveryPlanConfirmed}
                            onChange={handleChange}
                            options={Q5_AnalyzeRisk_Values}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 8}>
                        <SurveyTextField
                            disabled={isReadOnly}
                            multiline
                            variant={inputVariant}
                            surveyProperty="confirmationDescription"
                            label="Nánari svör / athugasemdir "
                            title="Nánari svör eða athugasemdir vegna spurninga um staðfestingu"
                            value={survey.confirmationDescription}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <SurveySectionHeader
                            disabled={isReadOnly}
                            variant={"h4"}
                            title="Samstarf og samhæfing"
                            // skáletra sviðsábyrgðarreglan, grenndarreglan, samkvæmisreglan \og samræmingarreglan
                            subTitle="Sveitarfélag þarf að auðvelda samvinnu og samhæfingu við innri og ytri aðila í vinnunni við að
                        tryggja öryggi borgaranna í sveitarfélaginu, skv. grundvallarreglum skipulags almannavarna:
                        sviðsábyrgðarreglan, grenndarreglan, samkvæmisreglan og samræmingarreglan. Þetta hefur í för með
                        sér að sveitarfélagið taki að sér hlutverk þess sem drífur vinnuna áfram, bjóði viðeigandi aðilum að
                        taka þátt og leggi fram verkfæri sem auðvelda aðilum að taka þátt í samstarfinu."
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.tableOverflow}>
                        <SurveyMultiPartMultiOptionTable
                            disabled={isReadOnly}
                            options={Q16_Collaboration}
                            sections={[
                                {
                                    label: "Áhættugreining",
                                    surveyProperty: "q16_P1_Collaboration",
                                    value: survey.q16_P1_Collaboration,
                                },
                                {
                                    label: "Könnun á áfallaþoli",
                                    surveyProperty: "q16_P2_Collaboration",
                                    value: survey.q16_P2_Collaboration,
                                },
                                {
                                    label: "Viðbúnaðaráætlun",
                                    surveyProperty: "q16_P3_Collaboration",
                                    value: survey.q16_P3_Collaboration,
                                },
                                {
                                    label: "Æfingar",
                                    surveyProperty: "q16_P4_Collaboration",
                                    value: survey.q16_P4_Collaboration,
                                },
                                {
                                    label: "Neyðarstjórnun",
                                    surveyProperty: "q16_P5_Collaboration",
                                    value: survey.q16_P5_Collaboration,
                                },
                            ]}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <SurveySectionHeader
                            disabled={isReadOnly}
                            variant={"h4"}
                            title="Neyðarstjórn sveitarfélaga"
                            subTitle="Neyðarstjórn getur verið mikilvægur vettvangur fyrir sveitarfélag við að sjá um samhæfingarhlutverkið.
                            Almannavarnadeild ríkislögreglustjóra mælir með því að stofnuð sé neyðarstjórn sveitarstjórnar í því skyni
                            að safna þeim aðilum saman sem koma að stjórnun þegar sveitarfélag er undir álagi."
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4}>
                        <SurveySelect
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="q17_EstablishedEmergencyControl"
                            label="Neyðarstjórn"
                            title="Hefur sveitarfélagið stofnað neyðarstjórn?"
                            value={survey.q17_EstablishedEmergencyControl}
                            onChange={handleChange}
                            options={Q5_AnalyzeRisk_Values}
                        />
                    </Grid>
                    <Hidden smDown>
                        <Grid item xs={8}></Grid>
                    </Hidden>
                    <Grid item xs={12} sm={!isPrinting && 8}>
                        <SurveyTextField
                            multiline
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="collaborationFurtherDescription"
                            label="Nánari svör / athugasemdir"
                            title="Nánari svör eða athugasemdir vegna spurninga um samvinnu og samhæfingu"
                            value={survey.collaborationFurtherDescription}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <SurveySectionHeader
                            disabled={isReadOnly}
                            variant={"h3"}
                            title="Sérstök þemu 2021"
                            subTitle="Yfirsýn – Eldsneyti – Eigin viðbúnaður"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SurveySectionHeader
                            title="Yfirsýn"
                            variant={"h6"}
                            disabled={isReadOnly}
                            subTitle="Í 2. gr. laga um almannavarnir segir að sveitarfélög fari með almannavarnir í héraði. 
                            Eitt af verkefnum sveitarfélags í neyðarstjórnun er að þróa og miðla yfirsýn yfir aðstæður þegar óæskileg
                        atvik eiga sér stað."
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4} className={classes.gridItem}>
                        <SurveySelect
                            multiple
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="themeOverview_Q1_CommunicationTools"
                            label="Verkfæri"
                            title="Hvert eftirtalinna verkfæra notar sveitarfélagið til að eiga samskipti við samstarfsaðila meðan á óæskilegum atvikum
                            stendur til að koma á sameiginlegri yfirsýn yfir aðstæður?"
                            value={survey.themeOverview_Q1_CommunicationTools}
                            onChange={handleChange}
                            options={TQ1_Values}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4} className={classes.gridItem}>
                        <SurveySelect
                            multiple
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="themeOverview_Q2_MapSolutions"
                            label="Kortalausnir"
                            title="Hvaða kortalausnir notar sveitarfélagið til að fá yfirsýn yfir atvik?"
                            value={survey.themeOverview_Q2_MapSolutions}
                            onChange={handleChange}
                            options={TQ2_Values}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4} className={classes.gridItem}>
                        <SurveySelect
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="themeOverview_Q3_SystemUsed"
                            label="Vatnar kerfi og/eða þjónustur?"
                            title="Vantar einhver kerfi eða þjónustu að svo stöddu til þess að sveitarfélagið geti komið á yfirsýn og miðlað henni áfram?"
                            value={survey.themeOverview_Q3_SystemUsed}
                            onChange={handleChange}
                            options={themeOverview_Q3_SystemUsed}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 8}>
                        <SurveyTextField
                            disabled={isReadOnly}
                            multiline
                            variant={inputVariant}
                            surveyProperty="themeOverview_FurtherDetails"
                            label="Nánari svör / athugasemdir "
                            title="Nánari svör eða athugasemdir vegna spurninga um yfirsýn"
                            value={survey.themeOverview_FurtherDetails}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <SurveySectionHeader
                            disabled={isReadOnly}
                            variant={"h6"}
                            title="Viðbúnaður til að tryggja eldsneyti"
                            subTitle="Eldsneyti er mikilvægur liður í að viðhalda samfélagslega mikilvægum verkefnum. Ríkisstjórnin ber
                        ábyrgð á að koma á viðeigandi viðbúnaði fyrir eldsneyti á landsvísu, sbr. 27. gr. laga um almannavarnir.
                        Eftirfarandi spurningar snúa að frekari upplýsingum um staðbundnar þarfir og þann viðbúnað sem er til
                        staðar í sveitarfélögum."
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4} className={classes.gridItem}>
                        <SurveySelect
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="themeFuel_Q1_FuelSupply"
                            label="Birgðastöð"
                            title="Er sveitarfélagið með sína eigin birgðastöð fyrir eldsneyti?"
                            value={survey.themeFuel_Q1_FuelSupply}
                            onChange={handleChange}
                            options={Q5_AnalyzeRisk_Values}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 8} className={classes.gridItem}>
                        <SurveyTextField
                            disabled={isReadOnly}
                            multiline
                            variant={inputVariant}
                            surveyProperty="themeFuel_Q2_FuelSupplyPriority"
                            label="Nánari svör / athugasemdir "
                            title="Ef svo er, hvaða aðilar hafa forgang að eldsneyti frá aðstöðu sveitarfélagsins þegar óæskileg atvik koma upp?"
                            value={survey.themeFuel_Q2_FuelSupplyPriority}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4} className={classes.gridItem}>
                        <SurveySelect
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="themeFuel_Q3_EmergencyFuelDistribution"
                            label="Svar"
                            title="Hefur sveitarfélagið komið á fót öðrum neyðarviðbúnaði til dreifingar á eldsneyti?"
                            helperText="T.d. samningar við bensínstöðvar eða aðra birgja"
                            value={survey.themeFuel_Q3_EmergencyFuelDistribution}
                            onChange={handleChange}
                            options={Q5_AnalyzeRisk_Values}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 8} className={classes.gridItem}>
                        <SurveyTextField
                            disabled={isReadOnly}
                            multiline
                            variant={inputVariant}
                            surveyProperty="themeFuel_Q4_EmergencyPreparation"
                            label="Nánari svör / athugasemdir"
                            title="Ef já, lýsið núverandi fyrirkomulagi"
                            value={survey.themeFuel_Q4_EmergencyPreparation}
                            onChange={handleChange}
                        />
                    </Grid>
                    <SurveyMultiPartSelect<number, ISurvey>
                        disabled={isReadOnly}
                        variant={inputVariant}
                        onChange={handleChange}
                        label="Aðstæður geta komið upp sem leiða til þess að bensínstöðvar geta ekki dreift eldsneyti, t.d.
                        ef langvarandi rafmagnsleysi verður. Hefur sveitarfélagið viðbragð við eftirfarandi þáttim í slíkum tilfellum? Hægt er að gera nánari grein fyrir svarinu í athugasemdareitunum."
                        options={Q5_AnalyzeRisk_Values}
                        sections={[
                            {
                                surveyProperty: "themeFuel_Q4_P1_Situation",
                                label: "Framboð á eldsneyti",
                                helperText: "Framboð á eldsneyti til ökutækja sveitarfélagsins",
                                value: survey.themeFuel_Q4_P1_Situation,
                            },
                            {
                                surveyProperty: "themeFuel_Q4_P2_Situation",
                                label: "Áfylling eldsneytis",
                                helperText: "Áfylling eldsneytis fyrir varaafl vegna samfélagslega mikilvægra verkefna",
                                value: survey.themeFuel_Q4_P2_Situation,
                            },
                        ]}
                    />
                    <Grid item xs={12} sm={!isPrinting && 8}>
                        <SurveyTextField
                            disabled={isReadOnly}
                            multiline
                            variant={inputVariant}
                            surveyProperty="themeFuel_Q5_SituationDesc"
                            label="Nánar um framboð og áfyllingu"
                            // title="Hægt er að gera nánari grein fyrir svarinu í athugasemdareitnum"
                            value={survey.themeFuel_Q5_SituationDesc}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Hidden smDown>
                        <Grid item xs={4}></Grid>
                    </Hidden>
                    <Grid item xs={12} sm={!isPrinting && 4} className={classes.gridItem}>
                        <SurveySelect
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="themeFuel_Q6_ProblemsOccured"
                            label="Eldsneytisskortur"
                            title="Hversu oft hefur sveitarfélagið lent í vandræðum með aðgang að eldsneyti fyrir ökutæki
                            sveitarfélagsins síðustu tvö ár?"
                            value={survey.themeFuel_Q6_ProblemsOccured}
                            onChange={handleChange}
                            options={themeFuel_Q6_ProblemsOccured}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={!isPrinting && 8}
                        className={classes.gridItem}
                        style={{ justifyContent: "flex-end" }}
                    >
                        <SurveyTextField
                            disabled={isReadOnly}
                            multiline
                            variant={inputVariant}
                            surveyProperty="themeFuel_Q7_ProblemsOccuredExplanation"
                            label="Nánar, s.s. tímalengd og umfang"
                            // title="Hægt er að gera nánari grein fyrir svarinu í athugasemdareitnum, svo sem um tímalengd og umfang"
                            value={survey.themeFuel_Q7_ProblemsOccuredExplanation}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4} className={classes.gridItem}>
                        <SurveySelect
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="themeFuel_Q8_ProblemsAccessFuel"
                            label="Eldsneyti fyrir varaafl"
                            title="Hversu oft hefur sveitarfélagið lent í vandræðum með aðgang að eldsneyti fyrir varaafl á vegum
                            sveitarfélagsins síðustu tvö ár?"
                            value={survey.themeFuel_Q8_ProblemsAccessFuel}
                            onChange={handleChange}
                            options={themeFuel_Q6_ProblemsOccured}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={!isPrinting && 8}
                        className={classes.gridItem}
                        style={{ justifyContent: "flex-end" }}
                    >
                        <SurveyTextField
                            disabled={isReadOnly}
                            multiline
                            variant={inputVariant}
                            surveyProperty="themeFuel_Q9_ProblemsAccessFuelExplanation"
                            label="Nánar, s.s. tímalengd og umfang"
                            // helperText="Þú getur gert nánari grein fyrir svarinu þínu í athugasemdareitnum, svo sem um tímalengd og
                            // umfang"
                            value={survey.themeFuel_Q9_ProblemsAccessFuelExplanation}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <SurveySectionHeader
                            disabled={isReadOnly}
                            variant={"h6"}
                            title="Eigin viðbúnaður"
                            // TODO: líta á að gera að paragraph
                            subTitle="„Við erum öll almannavarnir“ er átak sem hefur verið mjög áberandi vegna C-19 árið 2021. Til að
                        bregðast sem best við þeim skaða sem ýmis atvik geta haft í för með sér, þurfa allir að útbúa viðbrögð sín fyrir fram 
                        vegna þeirra. Almannavarnir eru á allra ábyrgð. Mikilvægt er að vera vel
                        undirbúin(n) og kynna sér viðbrögð og viðbúnað við þeirri vá sem getur verið í sveitarfélaginu.
                        Sveitarfélagið getur t.d. nýtt sér verkefnið „3 dagar“ sem Rauði krossinn á Íslandi sér um og snýr að viðbúnaði einstaklingsins
                        til að vera sjálfbjarga í þrjá daga í hamförum.
                        Stefnt er að því að halda áfram með átakið „Við erum öll almannavarnir“ á vegum
                        Almannavarnardeildar ríkislögreglustjóra árið 2022."
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4} className={classes.gridItem}>
                        <SurveySelect
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="themeOwnPreparation_Q1_WeAreAllAlmannavarnir"
                            label="Við erum öll almannavarnir"
                            title="Er sveitarfélagið meðvitað um átakið „Við erum öll almannavarnir“ er varðar eigin viðbúnað?"
                            value={survey.themeOwnPreparation_Q1_WeAreAllAlmannavarnir}
                            onChange={handleChange}
                            options={themeOwnPreparation_Q1_WeAreAllAlmannavarnir}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4} className={classes.gridItem}>
                        <SurveySelect
                            disabled={isReadOnly}
                            multiple
                            variant={inputVariant}
                            surveyProperty="themeOwnPreparation_Q2_WhatOperation"
                            label="Starfsemi"
                            title="Hvaða starfsemi hefur sveitarfélagið sinnt í tengslum við eigin viðbúnað árið 2021?"
                            value={survey.themeOwnPreparation_Q2_WhatOperation}
                            onChange={handleChange}
                            options={themeOwnPreparation_Q2_WhatOperationValues}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4} className={classes.gridItem}>
                        <SurveyTextField
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="themeOwnPreparation_Q2_WhatOperation_Description"
                            label="Aðrar ráðstafanir"
                            title="Hvaða starfsemi hefur sveitarfélagið sinnt í tengslum við eigin viðbúnað árið 2021?"
                            helperText="Setjið hér gildi sem ekki eru í flettilistanum hér að framan"
                            value={survey.themeOwnPreparation_Q2_WhatOperation_Description}
                            onChange={handleChange}
                            maxLength={50}
                            multiline
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 8}>
                        <SurveyTextField
                            disabled={isReadOnly}
                            multiline
                            variant={inputVariant}
                            surveyProperty="themeOwnPreparation_Q3_Proposals"
                            label="Tillögur"
                            title="Almannavarnadeild ríkislögreglustjóra ætlar að standa fyrir þemaviku um eigin viðbúnað árið
                            2022. Hér að neðan er hægt að koma með tillögur um hvernig Almannavarnadeild ríkislögreglustjóra getur auðveldað 
                            sveitarfélagi að taka virkan þátt í og bjóða upp á efni eða starfsemi í tengslum við þemaviku um
                            eigin viðbúnað árið 2022?"
                            value={survey.themeOwnPreparation_Q3_Proposals}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <SurveySectionHeader
                            disabled={isReadOnly}
                            title="Að lokum eru tvær spurningar til þeirra sem ábyrgjast neyðarstjórnun eða eru umsjónarmenn sveitarfélags."
                            variant={"h5"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={!isPrinting && 4}>
                        <SurveyTextField<ISurvey>
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="submitterJobRole"
                            label="Starfsheiti"
                            title="Hvert er starfsheiti þitt?"
                            value={survey.submitterJobRole}
                            onChange={handleChange}
                            maxLength={50}
                            multiline
                        />
                    </Grid>
                    <Hidden smDown>
                        <Grid item xs={8}></Grid>
                    </Hidden>
                    <Grid item xs={12} sm={!isPrinting && 4}>
                        <SurveySlider
                            disabled={isReadOnly}
                            formControlClassName={classes.formControl}
                            surveyProperty="howMuchTimeSpentOnEmergencyControl_Perc"
                            label=""
                            helperText="Hve stórt hlutfall stöðunnar er varið í að sinna því hlutverki að ábyrgjast 
                            neyðarstjórnun eða gegna hlutverki umsjónarmanns sveitarfélagsins. Notaðu tölur til að gefa til kynna bil í prósentum (1-100)."
                            value={survey.howMuchTimeSpentOnEmergencyControl_Perc}
                            onChange={handleChange}
                            min={1}
                            max={100}
                        />
                    </Grid>
                    <Hidden smDown>
                        <Grid item xs={8}></Grid>
                    </Hidden>
                    <Grid item xs={12} sm={!isPrinting && 8}>
                        <SurveyTextField
                            multiline
                            disabled={isReadOnly}
                            variant={inputVariant}
                            surveyProperty="surveyFeedback"
                            label="Athugasemdir"
                            title="Aðrar athugasemdir við þessa könnun á almannavarnastarfi sveitarfélagsins í ár"
                            value={survey.surveyFeedback}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SurveySectionHeader
                            disabled={isReadOnly}
                            title=""
                            subTitle={`Kærar þakkir fyrir álitið. Hægt er að vista svörin og halda áfram síðar ${
                                user &&
                                (matchesRoles(
                                    user.organizations,
                                    [Roles.almAdmin, Roles.surveyAdmin],
                                    survey.organization?.id
                                )
                                    ? "eða ef svari er að fullu lokið er hægt að staðfesta og skila"
                                    : "")
                            }. Hægt er að prenta svörin út með því að smella á prentara táknið ${
                                // TODO: skrifum við "Hér að neðan?" finna útur orðalagi
                                ""
                                // TODO: Helst að koma Alm... í nýja línu!
                            }. Með bestu kveðjum, Almannavarnadeild ríkislögreglustjóra`}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} spacing={2} className={classes.saveButtonsContainer}>
                    <Hidden mdDown>
                        <Grid item xs={2}></Grid>
                    </Hidden>
                    {isReadOnly ? (
                        <Grid item xs={12} sm={!isPrinting && 6} md={4}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ width: "100%", color: "white" }}
                                onClick={() => {
                                    history.push(`/${routes.surveys.root}`);
                                }}
                            >
                                Loka
                            </Button>
                        </Grid>
                    ) : (
                        <>
                            {user &&
                                matchesRoles(
                                    user.organizations,
                                    [Roles.almAdmin, Roles.surveyAdmin],
                                    survey.organization?.id
                                ) && (
                                    <Grid item xs={12} sm={!isPrinting && 6} md={4}>
                                        <Button
                                            disabled={isSubmitting}
                                            variant="contained"
                                            color="primary"
                                            style={{ width: "100%", color: "white" }}
                                            onClick={() => {
                                                handleSubmit({ ...survey, isPublished: true });
                                            }}
                                        >
                                            Staðfesta og skila
                                        </Button>
                                    </Grid>
                                )}
                            {!survey.isPublished && (
                                <Grid item xs={12} sm={!isPrinting && 6} md={4}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        disabled={isSubmitting}
                                        style={{ width: "100%" }}
                                        onClick={() => {
                                            handleSubmit(survey);
                                        }}
                                    >
                                        Vista og halda áfram síðar
                                    </Button>
                                </Grid>
                            )}
                        </>
                    )}
                    <Hidden mdDown>
                        <Grid item xs={2}></Grid>
                    </Hidden>
                </Grid>
            </Grid>
        </div>
    );
}
