import { Paper, Table, TableCell, TableContainer, TableHead, TableRow, Typography, TableBody } from "@mui/material";
import { CommunityProjects } from "../../models/FormValues";
import { IRisk } from "../../models/RiskTypes";

import CheckIcon from "@mui/icons-material/Check";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles/createTheme";
import CopyButton from "../CopyButton";

export interface CommunityProjectsTableProps {
    risks?: Pick<IRisk, "id" | "affectedCommunityProjects" | "name">[];
    isPrinting: boolean;
}

const useTableStyles = makeStyles((theme: Theme) => ({
    tableHeader: { height: "85px" },
    labelCell: {
        height: "85px",
        position: "relative",
        // fontWeight: 600,
        fontSize: "1.2rem",
    },
    rowLabel: {
        position: "absolute",
        bottom: "0px",
    },
    colLabel: {
        position: "absolute",
        top: "0px",
        right: "0px",
    },
    headerCell: {
        whiteSpace: "nowrap",
        "&>div": {
            transform: "translate(-3px, 23px) rotate(330deg)",
            width: "30px",
            "&>span": {
                // borderBottom: "1px solid #ccc",
                padding: "5px 10px",
            },
        },
    },
    tableCell: {
        borderLeft: "1px solid rgb(0 0 0 / 12%)",
        textAlign: "center",
        minWidth: "70px",
        padding: 0,
        "&:last-of-type": {
            minWidth: "80px",
        },
    },
    icon: {
        color: theme.palette.success.main,
    },
    colored: {
        backgroundColor: "#E09E3D4D",
        borderBottom: "1px solid rgb(139 139 139)",
    },
}));

const CommunityProjectsTable = ({
    risks = [
        { id: 1, name: "Við skulum gera textann bara töluvert lengir", affectedCommunityProjects: [1, 3] },
        {
            id: 2,
            name: "Ég nenni samt ekki að gera það alls staðar þannig við látum þessa tvo bara duga.",
            affectedCommunityProjects: [3, 5],
        },
        { id: 3, name: "Test 3", affectedCommunityProjects: [1, 7, 8, 9] },
        { id: 4, name: "Test 4", affectedCommunityProjects: [2, 5, 13, 10] },
        { id: 5, name: "Test 5", affectedCommunityProjects: [3, 5, 11, 4] },
        { id: 6, name: "Test 6", affectedCommunityProjects: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13] },
        { id: 7, name: "Test 7", affectedCommunityProjects: [6, 5] },
    ],
    isPrinting,
}: CommunityProjectsTableProps) => {
    const classes = useTableStyles();

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Typography
                    style={{
                        fontWeight: 550,
                        fontSize: "1.05rem",
                        color: "black",
                        marginTop: "10px",
                        marginBottom: "10px",
                    }}
                >
                    Áhrif atvika á Samfélagslega mikilvæg verkefni
                </Typography>
                <CopyButton elementID={"CopyToClipboardCommunityProjectsTable"} />
            </div>
            <TableContainer component={Paper}>
                <Table
                    style={{
                        border: isPrinting ? "2px solid black" : "",
                        transform: isPrinting ? "scale(0.59)" : "",
                        transformOrigin: isPrinting ? "top left" : "",
                    }}
                    // className={classes.tableBorders}
                >
                    <TableHead className={classes.tableHeader}>
                        <TableRow>
                            <TableCell className={classes.colored}>
                                <div className={classes.labelCell}>
                                    <div className={classes.rowLabel}>Atvik</div>
                                    <svg
                                        width="100"
                                        height="90"
                                        viewBox="0 0 68 68"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{ position: "absolute", left: "25%" }}
                                    >
                                        <path
                                            d="M1 1L67 67"
                                            stroke="#8b8b8b"
                                            strokeWidth="1"
                                            strokeLinecap="round"
                                        ></path>
                                    </svg>

                                    <div className={classes.colLabel}>Verkefni</div>
                                </div>
                            </TableCell>
                            {CommunityProjects.map((proj) => (
                                <TableCell key={proj.id} className={[classes.headerCell, classes.colored].join(" ")}>
                                    <div>
                                        <span>{proj.shortText}</span>
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {risks.map(({ id, name, affectedCommunityProjects: projects }) => {
                            return (
                                <TableRow key={id} style={{ border: isPrinting ? "2px solid black" : "" }}>
                                    <TableCell
                                        style={{ border: isPrinting ? "2px solid black" : "" }}
                                        className={classes.colored}
                                    >
                                        {name}
                                    </TableCell>
                                    {CommunityProjects.map(({ id: projId }) => (
                                        <TableCell
                                            style={{ border: isPrinting ? "2px solid black" : "" }}
                                            key={projId}
                                            className={classes.tableCell}
                                        >
                                            {projects.indexOf(projId) != -1 ? (
                                                <>
                                                    <span style={{ display: "none" }}>X</span>
                                                    <CheckIcon className={classes.icon} />
                                                </>
                                            ) : (
                                                //   "x"
                                                ""
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div id="CopyToClipboardCommunityProjectsTable" style={{ display: "none" }}>
                <table>
                    <thead style={{ fontSize: "10px" }}>
                        <tr>
                            <th>
                                <div>Verkefni</div>
                                <div>Atvik</div>
                            </th>
                            {CommunityProjects.map((proj) => (
                                <th key={proj.id}>{proj.shortText}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {risks.map(({ id, name, affectedCommunityProjects: projects }) => {
                            return (
                                <tr key={id}>
                                    <td style={{ fontSize: "10px" }}>{name}</td>
                                    {CommunityProjects.map(({ id: projId }) => (
                                        <td key={projId}>{projects.indexOf(projId) != -1 ? "X" : ""}</td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CommunityProjectsTable;
