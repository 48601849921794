import { CircularProgress, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { spinnerContainer } from "../../styles/Styles";

const useStyles = makeStyles((theme) => ({
    ...spinnerContainer(theme),
}));

function Spinner() {
    const classes = useStyles();
    return (
        <Container classes={{ root: classes.spinnerContainer }}>
            <CircularProgress size={70} />
        </Container>
    );
}

export default Spinner;
