import { Typography } from "@mui/material";
import FormIntro, { FormIntroProps } from "../FormIntro";

export type PlaceIntroProps = Pick<FormIntroProps, "isExpandedByDefault">;

export default function PlacesIntro({ isExpandedByDefault }: PlaceIntroProps) {
    return (
        <FormIntro isExpandedByDefault={isExpandedByDefault} title="Stofna skráningu">
            <Typography paragraph>
                Til að skrá mikilvægan stað þar sem talið er að hættuástand geti skapast þarf að skrá eftirfarandi:
            </Typography>
            <Typography paragraph>
                <span style={{ fontWeight: "bold" }}>Titil</span>: Hér skal skrá heimilisfang þar sem talið er að hætta
                geti skapast. Ef ekki er til heimilisfang skal skrá stutta lýsingu á staðnum þannig að sem auðveldast er
                að vita hvaða staður þetta er.
            </Typography>
            <Typography paragraph>
                <span style={{ fontWeight: "bold" }}>Stofnun</span>: Hér skal velja þá stofnun úr uppflettilistanum sem
                ber ábyrgð á skráningunni. Ef hún kemur ekki fram í henni skal senda tölvupóst á netfangið{" "}
                <a href="mailto: mikilvaegirstadir@almannavarnir.is" rel="noreferrer">
                    mikilvaegirstadir@almannavarnir.is
                </a>{" "}
                og óska eftir að viðkomandi stofnun sé skráð í uppflettilistann.
            </Typography>
            <Typography paragraph>
                <span style={{ fontWeight: "bold" }}>Heiti/númer staðfangs</span>: Hér skal skrá staðfang fasteignar en
                það er hægt að finna með því að fara á slóðina{" "}
                <a
                    href="https://www.skra.is/gogn/grunngogn-til-nidurhals/stadfangaskra/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                >
                    hér
                </a>
                .
            </Typography>
            <Typography paragraph>
                Staðföng eru skráð í staðfangaskrá Húsnæðis- og mannvirkjastofnunar. Ef það eru athugasemdir varðandi
                staðföng skal senda töluvpóst á netfangið{" "}
                <a href="mailto: land@hms.is" rel="noreferrer">
                    land@hms.is
                </a>{" "}
                . Ef breyta þarf skráningu eða hnitsetningu sendið með allar upplýsingar um skráninguna sem þið hafið,
                að lágmarki 7 stafa auðkenni staðfangs og svo nánari útskýringar/athugasemd varðandi breytingarnar sem
                þarf að gera.
            </Typography>
            <Typography paragraph>
                Vanti staðfang sendið upplýsingar um hvað vantar og hvernig þið teljið að skráningin ætti að vera.
            </Typography>
            <Typography paragraph>Best er ef athugasemdum fylgja myndir til skýringar. </Typography>
        </FormIntro>
    );
}
