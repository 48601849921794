import { createStyles, WithStyles, withStyles } from "@mui/styles/";
import { Modal, Paper, Stack, Theme, Typography } from "@mui/material";
import { getContentWrapperClass } from "../../styles/Styles";
import { history, Spinner } from "../../components/Shared/Common";
import { routes } from "../../routes/routes";
import { CustomIconButton } from "../../components/DownloadButton";
import { ImportantPlaceAllSubmission } from "../../models/PlaceTypes";
import { useAsync } from "../../utils/UseAsync";
import FullPageError from "../../components/FullPageError";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ModeIcon from "@mui/icons-material/Mode";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { useState } from "react";
import { AlmEnhancedTable } from "almannavarnir-shared";
const styles = (theme: Theme) =>
    createStyles({
        wrapperClass: {
            ...getContentWrapperClass(theme),
        },
    });

export interface PlaceOverviewProps extends WithStyles<typeof styles> {
    getPlaces: () => Promise<ImportantPlaceAllSubmission[]>;
}

function PlaceOverview({ classes, getPlaces }: PlaceOverviewProps) {
    const { status, value: places, error } = useAsync(getPlaces, true);
    const [open, setOpen] = useState(false);
    const [content, setDialogContent] = useState<ImportantPlaceAllSubmission | undefined>();

    const handleClose = () => {
        setOpen(false);
        setDialogContent(undefined);
    };
    return (
        <div className={classes.wrapperClass}>
            <div>
                <Typography variant="h2">Mikilvægir staðir</Typography>
                <Typography paragraph sx={{ pt: 2 }} variant="body2">
                    Takk fyrir að aðstoða við að gera Ísland að öruggari stað til að búa á.
                </Typography>
                <Typography paragraph variant="body2">
                    Áhættuskoðun almannavarna, þ.m.t. skráning á mikilvægum stöðum, er liður í starfi
                    Almannavarnadeildar ríkislögreglustjóra til að uppfylla kröfur í 7. gr. laga um almannavarnir þar
                    sem segir að ríkislögreglustjóri hafi umsjón með að ráðstafanir séu gerðar í samræmi við stefnu
                    stjórnvalda í almannavarna- og öryggismálum sem og eftirlit með skipulagi almannavarna á landinu
                    öllu og eftirlit með almannavörnum sveitarfélaga.
                </Typography>
                <Typography paragraph variant="body2">
                    Ætlunin er að Mikilvægir staðir sé lifandi gagnagrunnur sem inniheldur raunsætt mat á áhættu og
                    hvernig hægt er að bregðast við henni. Ráðuneyti, stofnanir og sveitarfélög geta bætt við nýjum
                    stöðum þar sem taldar eru líkur á að hætta geti skapast. Eftirfylgni Ríkislögreglustjóra fer yfir
                    skráningu og metur hvort og hvernig sé best að bregðast við.
                </Typography>
                <Typography paragraph variant="body2">
                    Hægt er að uppfæra upplýsingar um mikilvægan stað sem þegar er skráður með því að setja inn nýja
                    skráningu. Kerfið sér um að uppfæra gögnin þannig að alltaf séu nýjustu upplýsingar aðgengilegar
                    þegar á þarf að halda.
                </Typography>
            </div>
            {status == "error" && <FullPageError message={error || "Something went wrong"} />}
            {status == "pending" && <Spinner />}
            {status == "success" && places && (
                <AlmEnhancedTable
                    // TODO: Default order by saved date
                    items={places}
                    searchFields={["title", "organizationName", "accessAddress", "status", "lastModified"]}
                    columns={[
                        { id: "title", label: "Titill" },
                        { id: "organizationName", label: "Stofnun" },
                        { id: "accessAddress", label: "Staðfang" },
                        {
                            id: "lastModified",
                            label: "Vistað",
                            onRender: (itm) => (
                                <span style={{ whiteSpace: "nowrap" }}>{itm.lastModified?.substring(0, 10)}</span>
                            ),
                        },
                        {
                            id: "status",
                            label: "Staða",
                            onRender: (itm) => statusIcon(itm.status),
                        },
                    ]}
                    idCol="id"
                    onClick={(_ev, it) => {
                        if (it.activeSubmissionID) {
                            history.push(`/${routes.places.edit.root}/${it.activeSubmissionID}`);
                        } else {
                            setDialogContent(it);
                            setOpen(true);
                        }
                    }}
                    customBtn={
                        <Stack direction={"row"}>
                            <CustomIconButton
                                variant="contained"
                                color="secondary"
                                icon={<AddCircleOutlineRoundedIcon />}
                                title={"Skrá nýjan mikilvægan stað"}
                                onClick={() => {
                                    history.push(`/${routes.places.new}`);
                                }}
                                resize={true}
                            />
                        </Stack>
                    }
                />
            )}

            {status == "success" && places && places.length == 0 && (
                <Typography>Engir mikilvægir staðir fundust frá þinni stofnun.</Typography>
            )}
            <Modal onClose={handleClose} open={open}>
                {dialogContent(content)}
            </Modal>
        </div>
    );
}

const statusIcon = (status: string) => {
    const icon =
        status === "InReview" ? (
            <ScheduleIcon color="primary" />
        ) : status === "InProgress" ? (
            <ModeIcon color="secondary" />
        ) : status === "IsSubmitted" ? (
            <DoneIcon color="success" />
        ) : (
            <ClearIcon color="error" />
        );
    const display =
        status === "InReview"
            ? "Í yfirferð"
            : status === "InProgress"
            ? "Í vinnslu"
            : status === "IsSubmitted"
            ? "Lokið"
            : "Hafnað";
    return (
        <Stack direction={"row"} sx={{ alignItems: "center" }}>
            {icon}
            <Typography variant="body2" sx={{ pl: 0.5 }}>
                {display}
            </Typography>
        </Stack>
    );
};

const dialogContent = (content: ImportantPlaceAllSubmission | undefined) => {
    return (
        <Paper
            sx={{
                width: { xs: "100vw", sm: "600px", md: "700px" },
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: 0,
                boxShadow: "0px 0px 23px rgba(0, 0, 0, 0.1)",
            }}
        >
            <Stack sx={{ p: 5, width: "100%" }}>
                <Typography variant="h6" sx={{ fontWeight: 800 }}>
                    {content?.title}
                </Typography>
                <Typography sx={{ fontSize: "18px", fontWeight: 800, pt: 1 }}>Skilað af</Typography>
                <Stack direction={"row"} sx={{ whiteSpace: "break-spaces" }}>
                    <Typography>{`${content?.metadataSummary?.author.name} - `}</Typography>
                    <Typography sx={{ fontStyle: "italic" }}> {content?.metadataSummary?.author.email}</Typography>
                </Stack>
                <Typography variant="body2"> Skilað: {content?.lastModified}</Typography>
                <Typography sx={{ fontSize: "18px", fontWeight: 800, pt: 1 }}>Lýsing</Typography>
                <Typography>{content?.submittersDescription}</Typography>
                <Typography sx={{ fontSize: "18px", fontWeight: 800, pt: 1 }}>Viðhengi</Typography>
                {content?.metadataSummary?.attachments?.map((itm) => (
                    <Stack direction="row" key={itm.name} sx={{ whiteSpace: "break-spaces" }}>
                        <Typography>{itm.name} </Typography>
                        <Typography> - {itm.tagNames.join(", ")} - </Typography>
                        <Typography variant="body2" sx={{ lineHeight: 1.5, alignSelf: "flex-end" }}>
                            {itm.lastModified}
                        </Typography>
                    </Stack>
                ))}
                {content?.metadataSummary?.links?.map((itm) => (
                    <Stack key={itm.title}>
                        <Typography>
                            {itm.title} - {itm.tagNames.join(", ")}
                        </Typography>
                        <Typography sx={{ fontStyle: "italic", whiteSpace: "break-spaces" }}>
                            {"    "}
                            {itm.fullUrl}
                        </Typography>
                    </Stack>
                ))}
                <Typography sx={{ fontSize: "18px", fontWeight: 800, pt: 1 }}>Tengiliðir</Typography>
                {content?.metadataSummary?.contacts?.map((itm) => (
                    <Stack key={itm.name} direction="row" sx={{ whiteSpace: "break-spaces" }}>
                        <Typography>{itm.name}</Typography>
                        <Typography>
                            {" - "}
                            {itm.mobilePhone}
                        </Typography>
                        <Typography sx={{ fontStyle: "italic", whiteSpace: "break-spaces" }}>
                            {" - "}
                            {itm.email}
                        </Typography>
                        <Typography>
                            {" - "}
                            {itm.jobRoleName}
                        </Typography>
                    </Stack>
                ))}
            </Stack>
        </Paper>
    );
};
export default withStyles(styles)(PlaceOverview);
