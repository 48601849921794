import { FormControl, TextField, TextFieldProps, FormHelperText, styled, FormControlProps } from "@mui/material";

import useIsTyping from "../../utils/CustomHooks/useIsTyping";
import { useEffect, useState } from "react";
import { IOrganization, IRisk, ISurvey, OrganizationUser } from "../../models/RiskTypes";
import { InputUrl } from "../Attachments/UrlInput";
import { IMinimumPlace, IPlace } from "../../models/PlaceTypes";

export type SurveyTextFieldProps<
    T extends ISurvey | IRisk | IOrganization | OrganizationUser | IPlace | InputUrl | IMinimumPlace
> = Omit<TextFieldProps, "onChange"> & {
    variant: "filled" | "standard" | "outlined";
    surveyProperty: keyof T;
    label: string;
    value: string;
    helperText?: string;
    errorText?: string;
    title?: string;
    onChange: (property: keyof T, newVal: string) => void;
    number?: boolean;
    onValidateNumber?: (currVal: number) => number;
    maxLength?: number;
    precision?: number;
    large?: boolean;
};

const StyledFormControl = styled(
    FormControl,
    {}
)<FormControlProps & { value: string }>(({ error, value, theme }) => ({
    width: "100%",
    //ekki focused
    "& label": {
        color: error ? theme.palette.error.main : (value != "" && theme.palette.success.main) || undefined,
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: error ? theme.palette.error.light : (value != "" && theme.palette.success.main) || undefined,
    },
    "& .MuiOutlinedInput-root": {
        color: error ? theme.palette.error.main : (value != "" && theme.palette.success.main) || undefined,
    },
    //fyrir focused
    "& .Mui-focused": {
        // label
        color: error ? theme.palette.error.main : (value != "" && theme.palette.success.main) || undefined,

        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: error
                ? theme.palette.error.light
                : (value != "" && theme.palette.success.main + "!important") || undefined,
        },
        "& .MuiOutlinedInput-input": {
            //texti focused
            color: "black",
        },
    },
}));

export function SurveyTextField<
    T extends ISurvey | IRisk | IOrganization | OrganizationUser | IPlace | InputUrl | IMinimumPlace
>({
    variant,
    surveyProperty,
    label,
    helperText,
    errorText,
    title,
    value,
    onChange,
    number: isNumber = undefined,
    onValidateNumber,
    maxLength,
    precision,
    error,
    large,
    ...rest
}: SurveyTextFieldProps<T>) {
    const [isTyping, register] = useIsTyping({ timeout: 500 });

    const [textValue, setTextValue] = useState<string>(value);
    useEffect(() => {
        if (!isTyping && textValue != value) {
            onChange(surveyProperty, textValue);
        }
    }, [isTyping, textValue, value, onChange, surveyProperty, isNumber]);

    return (
        <>
            {title && (
                <FormHelperText style={{ marginBottom: "7px", fontSize: "0.85rem", color: "#000000" }}>
                    {title}
                </FormHelperText>
            )}
            {helperText && (
                <FormHelperText style={{ marginBottom: "7px", flex: "auto", fontStyle: "italic" }}>
                    {helperText}
                </FormHelperText>
            )}
            <StyledFormControl error={error} value={textValue}>
                <TextField
                    inputRef={register}
                    multiline={large}
                    minRows={large ? 4 : 0}
                    label={label}
                    variant={variant}
                    error={error}
                    value={textValue}
                    helperText={error && errorText}
                    onChange={(ev) => {
                        let val = ev.target.value;

                        if (isNumber) {
                            if (precision && val.indexOf(",") !== -1)
                                val = `${val.split(",")[0]},${(val.split(",")[1] || "").slice(0, precision)}`;
                            val = parseNumVal(val, onValidateNumber);
                        }

                        if (maxLength && val.length > maxLength) {
                            val = val.slice(0, maxLength);
                        }

                        setTextValue(val);
                    }}
                    {...rest}
                />
            </StyledFormControl>
            {""}
        </>
    );
}
function parseNumVal(val: string, onValidateNumber: ((currVal: number) => number) | undefined) {
    val = val
        .replace(/[^\d,-]/g, "") // first, remove all characters that aren't applicable
        .replace(/(?!^)-/g, "") // remove negative characters that aren't in beginning
        .replace(/\./g, ",") // now replace all periods with comma
        .replace(",", "%FD%") // replace first occurrence of comma with placeholder
        .replace(/,/g, "") // now replace all other occurrences of comma
        .replace("%FD%", ","); // reintroduce comma

    const reintroductionZeros = (val.match(/,.*(0)+$/) ?? [""])[0];
    const reintroductionComma = val[val.length - 1] == "," ? "," : "";
    const reintroductionNeg = "-0,".indexOf(val) == 0 ? "-" : "";

    if (onValidateNumber && val != "") {
        const numVal = parseFloat(val.replace(",", "."));

        if (!(val.indexOf("0,0") != -1 && numVal == 0)) {
            val =
                reintroductionNeg +
                (!isNaN(numVal) ? onValidateNumber(numVal).toString().replace(".", ",") : "") +
                reintroductionComma +
                reintroductionZeros;
        }
    }
    return val;
}
