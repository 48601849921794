import { Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { hideInPrint } from "../../styles/Styles";
import cloneDeep from "lodash.clonedeep";
import {
    Risk_LifeAndHealth_NumDeceased_Values,
    Risk_Effects_Duration_Values,
    Risk_Stability_NumPeople_Values,
    Risk_natureAndEnvironment_Area_Values,
    Risk_natureAndEnvironment_Scope_Values,
    Risk_natureAndEnvironment_Protection_Values,
    Risk_natureAndEnvironment_Duration_Values,
    Risk_physicalValuables_Values,
    Risk_LifeAndHealth_NumInjured_Values,
} from "../../models/FormValues";
import { IEffectAssessment, IEffects } from "../../models/RiskTypes";
import { EffectSection } from "../TableUtilities/TableCheckboxQuestion";

const useStyles = makeStyles(() => ({
    radioSpan: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
    },
    radio: {},
    hiddenInPrint: {
        ...hideInPrint,
    },
}));

export interface IEffectsTableControlProps {
    effects: IEffects;
    onChange: (newEffects: IEffects) => void;
    disabled: boolean;
}

export function EffectsTableControl({ effects, onChange, disabled }: IEffectsTableControlProps) {
    const classes = useStyles();

    const handleChange = function a<T extends keyof IEffects>(
        effectGroupKey: T,
        assessmentKey: keyof Omit<IEffects[typeof effectGroupKey], "preventions">,
        newAssessment: IEffectAssessment
    ) {
        const newEffects = cloneDeep(effects);
        (newEffects[effectGroupKey][assessmentKey] as unknown as IEffectAssessment) = newAssessment; //TODO: improve typing
        onChange(newEffects);
    };

    return (
        <Table style={{ pageBreakInside: "avoid" }}>
            <TableBody>
                <TableRow>
                    <TableCell className={classes.hiddenInPrint}>
                        <Typography {...(disabled && { color: "textSecondary" })}>Verðmæti</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography {...(disabled && { color: "textSecondary" })}>Tegund afleiðingar</Typography>
                    </TableCell>
                    <TableCell align="center">
                        <Typography {...(disabled && { color: "textSecondary" })}>Vægi afleiðingar</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography {...(disabled && { color: "textSecondary" })}>Skýring</Typography>
                    </TableCell>
                </TableRow>
                <TableRow style={{ alignItems: "flex-end" }}>
                    <TableCell rowSpan={2} className={classes.hiddenInPrint}>
                        <Typography {...(disabled && { color: "textSecondary" })}>1. Líf og heilsa</Typography>
                    </TableCell>
                    <EffectSection
                        disabled={disabled}
                        header="Fjöldi látinna"
                        options={Risk_LifeAndHealth_NumDeceased_Values}
                        value={effects.lifeAndHealth.numberOfDeceased}
                        onChange={(newVal) => handleChange("lifeAndHealth", "numberOfDeceased", newVal)}
                    />
                </TableRow>
                <TableRow>
                    <EffectSection
                        disabled={disabled}
                        header="Fjöldi slasaðra eða veikra"
                        options={Risk_LifeAndHealth_NumInjured_Values}
                        value={effects.lifeAndHealth.numberInjuredOrSick}
                        onChange={(newVal) => handleChange("lifeAndHealth", "numberInjuredOrSick", newVal)}
                    />
                </TableRow>
                <TableRow>
                    <TableCell rowSpan={4} className={classes.hiddenInPrint}>
                        <Typography {...(disabled && { color: "textSecondary" })}>2. Stöðugleiki</Typography>
                    </TableCell>
                    <EffectSection
                        disabled={disabled}
                        header="Óuppfylltar grunnþarfir - Tímalengd"
                        options={Risk_Effects_Duration_Values}
                        value={effects.stability.basicNeedsUnfulfilled_Duration}
                        onChange={(newVal) => handleChange("stability", "basicNeedsUnfulfilled_Duration", newVal)}
                    />
                </TableRow>
                <TableRow>
                    <EffectSection
                        disabled={disabled}
                        header="Óuppfylltar grunnþarfir - Fjöldi þolenda"
                        options={Risk_Stability_NumPeople_Values}
                        value={effects.stability.basicNeedsUnfulfilled_NumPeople}
                        onChange={(newVal) => handleChange("stability", "basicNeedsUnfulfilled_NumPeople", newVal)}
                    />
                </TableRow>
                <TableRow>
                    <EffectSection
                        disabled={disabled}
                        header="Truflanir á daglegu lífi - Tímalengd"
                        options={Risk_Effects_Duration_Values}
                        value={effects.stability.disturbancesToDailyLife_Duration}
                        onChange={(newVal) => handleChange("stability", "disturbancesToDailyLife_Duration", newVal)}
                    />
                </TableRow>
                <TableRow>
                    <EffectSection
                        disabled={disabled}
                        header="Truflanir á daglegu lífi - Fjöldi þolenda"
                        options={Risk_Stability_NumPeople_Values}
                        value={effects.stability.disturbancesToDailyLife_NumPeople}
                        onChange={(newVal) => handleChange("stability", "disturbancesToDailyLife_NumPeople", newVal)}
                    />
                </TableRow>
                <TableRow>
                    <TableCell rowSpan={4} className={classes.hiddenInPrint}>
                        <Typography {...(disabled && { color: "textSecondary" })}>3. Náttúra og umhverfi</Typography>
                    </TableCell>
                    <EffectSection
                        disabled={disabled}
                        header="Varanlegur skaði á náttúru/umhverfi - Landfræðileg dreifing"
                        options={Risk_natureAndEnvironment_Area_Values}
                        value={effects.natureAndEnvironment.permanentDamageToNature_Area}
                        onChange={(newVal) =>
                            handleChange("natureAndEnvironment", "permanentDamageToNature_Area", newVal)
                        }
                    />
                </TableRow>
                <TableRow>
                    <EffectSection
                        disabled={disabled}
                        header="Varanlegur skaði á náttúru/umhverfi - Tímalengd"
                        options={Risk_natureAndEnvironment_Duration_Values}
                        value={effects.natureAndEnvironment.permanentDamageToNature_Duration}
                        onChange={(newVal) =>
                            handleChange("natureAndEnvironment", "permanentDamageToNature_Duration", newVal)
                        }
                    />
                </TableRow>
                <TableRow>
                    <EffectSection
                        disabled={disabled}
                        header="Varanlegur skaði á menningarverðmætum, -starfsemi eða -minjum - Hve mikil eyðilegging"
                        options={Risk_natureAndEnvironment_Scope_Values}
                        value={effects.natureAndEnvironment.permanentDamageToCulture_Scope}
                        onChange={(newVal) =>
                            handleChange("natureAndEnvironment", "permanentDamageToCulture_Scope", newVal)
                        }
                    />
                </TableRow>
                <TableRow>
                    <EffectSection
                        disabled={disabled}
                        header="Varanlegur skaði á menningarverðmætum, -starfsemi eða -minjum - Verndarstaða/verndargildi"
                        options={Risk_natureAndEnvironment_Protection_Values}
                        value={effects.natureAndEnvironment.permanentDamageToCulture_Protection}
                        onChange={(newVal) =>
                            handleChange("natureAndEnvironment", "permanentDamageToCulture_Protection", newVal)
                        }
                    />
                </TableRow>
                <TableRow>
                    <TableCell rowSpan={2} className={classes.hiddenInPrint}>
                        <Typography {...(disabled && { color: "textSecondary" })}>4. Efnisleg verðmæti</Typography>
                    </TableCell>
                    <EffectSection
                        disabled={disabled}
                        header="Beint efnahagslegt tjón"
                        options={Risk_physicalValuables_Values}
                        value={effects.physicalValuables.directEconomicalDamage}
                        onChange={(newVal) => handleChange("physicalValuables", "directEconomicalDamage", newVal)}
                    />
                </TableRow>
                <TableRow>
                    <EffectSection
                        disabled={disabled}
                        header="Óbeint efnahagslegt tjón"
                        options={Risk_physicalValuables_Values}
                        value={effects.physicalValuables.indirectEconomicalDamage}
                        onChange={(newVal) => handleChange("physicalValuables", "indirectEconomicalDamage", newVal)}
                    />
                </TableRow>
            </TableBody>
        </Table>
    );
}
