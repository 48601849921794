import { Theme } from "@mui/material/styles/createTheme";
import { makeStyles } from "@mui//styles";
import { getContentWrapperClass } from "../styles/Styles";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
    wrapperClass: {
        ...getContentWrapperClass(theme),
    },
}));

export interface FullPageErrorProps {
    message: string;
}

export default function FullPageError({ message }: FullPageErrorProps) {
    const classes = useStyles();

    return (
        <div className={classes.wrapperClass}>
            <Typography variant="h3" color="error">
                {message}
            </Typography>
        </div>
    );
}
