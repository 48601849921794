import { FormControl, FormHelperText, InputLabel, Slider, SliderProps } from "@mui/material";
import { Theme } from "@mui/material/styles/createTheme";
import { IRisk, ISurvey } from "../../models/RiskTypes";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
    slider: {
        paddingTop: theme.spacing(6),
    },
}));

export type SurveySliderProps<U extends ISurvey | IRisk> = Omit<SliderProps, "onChange" | "value"> & {
    formControlClassName: string;
    surveyProperty: Extract<keyof U, string>;
    label: string;
    helperText?: string;
    value?: number;
    onChange: (property: keyof U, newVal: number) => void;
};

function SurveySlider<U extends ISurvey | IRisk>({
    formControlClassName,
    surveyProperty,
    label,
    helperText,
    onChange,
    ...rest
}: SurveySliderProps<U>) {
    const labelId = `survey-form-${surveyProperty}-select`;
    const classes = useStyles();

    return (
        <>
            {helperText && (
                <FormHelperText style={{ fontSize: "0.85rem", color: "#000000" }}>{helperText}</FormHelperText>
            )}
            <FormControl className={formControlClassName}>
                <InputLabel id={labelId} htmlFor={surveyProperty}>
                    {label}
                </InputLabel>

                <Slider
                    aria-label={label}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="on"
                    onChange={(_ev, newval) => {
                        onChange(surveyProperty, newval as number);
                    }}
                    className={classes.slider}
                    {...rest}
                    // defaultValue={50}
                    // step={1}
                    // min={0}
                    // max={100}
                />
            </FormControl>
        </>
    );
}

export default SurveySlider;
