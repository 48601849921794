import { useContext } from "react";
import { Navigate, useOutlet } from "react-router-dom";
import { matchesRoles, Roles } from "../../models/RiskUser";
import { AppContext } from "../../utils/AppContext";

interface AdminLayoutProps {
    isAdmin: boolean | undefined;
}
export const AdminLayout = ({ isAdmin }: AdminLayoutProps) => {
    const outlet = useOutlet();

    if (!isAdmin) {
        return <Navigate to="/" />;
    }
    return <>{outlet}</>;
};
export const SecuredLayout = ({ roles }: { roles: Roles[] }) => {
    const { user } = useContext(AppContext);
    const outlet = useOutlet();

    if (!matchesRoles(user?.organizations, [...roles, Roles.almAdmin, Roles.almEdit])) {
        return <Navigate to="/" />;
    }
    return <>{outlet}</>;
};
